import tinycolor from 'tinycolor2';
import {colorMixKM, getLookUpTable} from './kubelkaMonk';
import {Point2D, SeededRandom} from './utils';
import {ChangeTextureSet, FingerTip, FlowCell, FlowCellMove, TipAction, TipApplication, TipInstrument, TipMotion} from './fingertip';
import { CrcOff, Page } from './page';
import { initialize } from 'workbox-google-analytics';
import { GPURunner, ProgramInfo } from './GPURunner';
import { TouchPageChange, TouchProgramBuffers, TouchProgramInfo, TouchUniformLocations } from './TouchPageChange';
import { PageChange, PageChangeEntry } from './PageChange';

export interface StampUniformLocations extends TouchUniformLocations {
    externalStampColorTexNum: WebGLUniformLocation,
    externalStampColorTextureOffset: WebGLUniformLocation,
    stampDepth: WebGLUniformLocation,
};

export interface StampProgramInfo extends TouchProgramInfo {
    uniformLocations: StampUniformLocations
};

export interface StampProgramBuffers extends TouchProgramBuffers {
    stampColorTexture: WebGLTexture | null,

};


export class StampPageChange extends TouchPageChange {

    static stampProgram: StampProgramInfo = {tag: 'stampProgram', uniformLocations:{}} as StampProgramInfo;
    static stampBuffers: StampProgramBuffers | undefined;

static fsSourceStamp = 
TouchPageChange.fsSourceProcessGlobals  
+ `  
#line 7036
uniform highp usampler2D u_kmLookupTexNum;
uniform highp sampler2D u_externalStampColorTexNum;
uniform highp int u_stampDepth;
uniform highp ivec2 u_externalStampColorTextureOffset;
` + TouchPageChange.fsColorMixRGBToLatentSource + `
#line 7042
void main() {
    outputDepth = texture(u_workingDepthTexNum, v_texCoordRendered).r;
    outputLatentC = texture(u_workingLatentCTexNum, v_texCoordRendered);
    outputLatentM = texture(u_workingLatentMTexNum, v_texCoordRendered);
    Latent outputLatent = latentFromLatentCM(outputLatentC, outputLatentM);

    highp uvec2 sourceSize = uvec2(textureSize(u_workingLatentCTexNum, 0));
    highp ivec2 sourcePosition = ivec2(v_texCoordExternal * vec2(sourceSize));

    highp uvec2 stampSize = uvec2(textureSize(u_externalStampColorTexNum, 0));
    highp ivec2 stampPosition = ivec2(sourcePosition - u_externalStampColorTextureOffset);
    if (!(stampPosition.x < 0 || uint(stampPosition.x) >= stampSize.x || stampPosition.y < 0 || uint(stampPosition.y) >= stampSize.y)) {
        highp vec4 stampColor = texelFetch(u_externalStampColorTexNum, stampPosition, 0);
        if (stampColor.a > 0.0) {
            highp float blendWeight = float(u_stampDepth) / (float(u_stampDepth) + float(outputDepth));
            Latent stampLatent = rgbaToLatent(stampColor);
            outputLatent = lerpLatent(outputLatent, stampLatent, blendWeight);
            outputDepth += uint(u_stampDepth);
        }
    }
    outputLatentC = latentToLatentC(outputLatent);
    outputLatentM = latentToLatentM(outputLatent);
}`;

    static getPrograms(): Array<[string, ProgramInfo]> {
        return [[StampPageChange.fsSourceStamp, StampPageChange.stampProgram]];
    }

    static initProgramLocations(gl: WebGL2RenderingContext) {
        StampPageChange.stampProgram.uniformLocations = {
            kmLookupTexNum: gl.getUniformLocation(StampPageChange.stampProgram.program, 'u_kmLookupTexNum')!,
            externalStampColorTexNum: gl.getUniformLocation(StampPageChange.stampProgram.program, 'u_externalStampColorTexNum')!,
            externalStampColorTextureOffset: gl.getUniformLocation(StampPageChange.stampProgram.program, 'u_externalStampColorTextureOffset')!,
            stampDepth: gl.getUniformLocation(StampPageChange.stampProgram.program, 'u_stampDepth')!,
        } as StampUniformLocations;
        TouchPageChange.addProcessProgramLocations(gl, StampPageChange.stampProgram);
    }

    static initPermanentStorage(gl: WebGL2RenderingContext, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {
        StampPageChange.stampBuffers = {} as StampProgramBuffers;
        StampPageChange.addPermanentStorage(gl, StampPageChange.stampBuffers, textureCallback);
    }
    static addPermanentStorage(gl: WebGL2RenderingContext, buffers: StampProgramBuffers, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {

        if (StampPageChange.stampBuffers!.stampColorTexture === undefined)
        {
            TouchPageChange.addPermanentStorage(gl, buffers, textureCallback);
            StampPageChange.stampBuffers!.stampColorTexture = GPURunner.initTexture(gl, 'stampColorTexture');

        }
        buffers.stampColorTexture = StampPageChange.stampBuffers!.stampColorTexture;
    }

    static externalStampColorTexNumVal = 15;

    stampColors: Uint8Array | undefined;
    stampDepth: number | undefined;

    constructor(page: Page, pageX: number, pageY: number, width: number, height: number,
        tipsAndLocations: Array<PageChangeEntry>, dryTransmits: boolean) {
        super(page, pageX, pageY, width, height, tipsAndLocations, undefined as unknown as TipApplication, dryTransmits, undefined);
    }

    override initializeStartupProgram(pi: StampProgramInfo): void {
        let gl = GPURunner.gl!;
        let buffers = StampPageChange.stampBuffers!;
        super._initializeStartupProgram(pi, buffers);
    }

    override specializeStartupRoutine(pi: StampProgramInfo): void {
        let gl = GPURunner.gl!;
        let buffers = StampPageChange.stampBuffers!;
        super._specializeStartupRoutine(pi, buffers);
    }

    override runStartupRoutine(pi: StampProgramInfo): void {
        let buffers = StampPageChange.stampBuffers!;
        super._runStartupRoutine(pi, buffers);
    }
    override finalizeStartup(pi: StampProgramInfo): void {
        let buffers = StampPageChange.stampBuffers!;
        super._finalizeStartup(pi, buffers);
    }
    override initializeProcessProgram(pi: StampProgramInfo): void {
        let buffers = StampPageChange.stampBuffers!;
        let gl = GPURunner.gl!;
        super._initializeProcessProgram(pi, buffers);

        if (GPURunner.doInitializeProgram(StampPageChange.stampProgram, 'stamp')) {
            let piSave = pi;
            pi = StampPageChange.stampProgram;
            gl.useProgram(pi.program);
            super._initializeProcessProgram(pi, buffers);

            gl.uniform1i(pi.uniformLocations.kmLookupTexNum, StampPageChange.kmLookupTexNumVal);
            gl.uniform1i(pi.uniformLocations.externalStampColorTexNum, StampPageChange.externalStampColorTexNumVal);
            gl.uniform1i(pi.uniformLocations.stampDepth, 0);
            pi = piSave;
            gl.useProgram(pi.program);
        }

    }
    override specializeProcessRoutine(pi: StampProgramInfo): void {
        let buffers = StampPageChange.stampBuffers!;
        super._specializeProcessRoutine(pi, buffers);
        let gl = GPURunner.gl!;
    }

    override runProcessRoutine(pi: StampProgramInfo): void {
        let buffers = StampPageChange.stampBuffers!;
        let gl = GPURunner.gl!;
        //console.log('run process gets outputDepthTexture');
        //this.debugSpewDepthTexture(this.outputDepthTexture!);

        pi = StampPageChange.stampProgram;
        gl.useProgram(pi.program);

        gl.uniform1i(pi.uniformLocations.stampDepth, this.stampDepth!);
        let stampOffX = this.entries[0].location.x;
        let stampOffY = this.entries[0].location.y;
        let stampDims = this.entries[0].shape as {dx: number, dy: number};
        gl.uniform2i(pi.uniformLocations.externalStampColorTextureOffset, stampOffX, stampOffY);

        // load the initial stamp texture 
        gl.activeTexture(gl.TEXTURE0 + StampPageChange.externalStampColorTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D, StampPageChange.stampBuffers!.stampColorTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, stampDims.dx, stampDims.dy, 0, gl.RGBA, gl.UNSIGNED_BYTE, this.stampColors!);

        this.stashOutput();
        this.bindStashAsWorking();
        this.useAvailableOutputFrame();
        gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);

    }
    override finalizeProcess(pi: StampProgramInfo): void {
        let buffers = StampPageChange.stampBuffers!;
        super._finalizeProcess(pi, buffers);

    }
    override initializeFinishProgram(pi: StampProgramInfo): void {
        let buffers = StampPageChange.stampBuffers!;
        super._initializeFinishProgram(pi, buffers);

    }
    override specializeFinishRoutine(pi: StampProgramInfo): void {
        let buffers = StampPageChange.stampBuffers!;
        super._specializeFinishRoutine(pi, buffers);
    }

    override runFinishRoutine(pi: StampProgramInfo): void {
        let buffers = StampPageChange.stampBuffers!;
        super._runFinishRoutine(pi, buffers);

    }
    override finalizeFinish(pi: StampProgramInfo): void {
        let buffers = StampPageChange.stampBuffers!;
        super._finalizeFinish(pi, buffers);

    }

}

        