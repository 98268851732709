import { CircleTouchProgramBuffers } from './circlePageChange';
import { ChangeTextureIndexes, ChangeTextureSet, FingerTip, TipApplication } from './fingertip';
import { GPURunner, ProgramBuffers, ProgramInfo, UniformLocations } from './GPURunner';
import { getLookUpTable } from './kubelkaMonk';
import { Page } from './page';
import { PageChange, PageChangeEntry } from './PageChange';
import { Point2D } from './utils';


export interface TouchUniformLocations extends UniformLocations {

    kmLookupTexNum: WebGLUniformLocation,
   
    debug: WebGLUniformLocation,
    writeAccum: WebGLUniformLocation,
    resolution: WebGLUniformLocation,
    maxDepthTextureOffset: WebGLUniformLocation,
    actionTextureOffset: WebGLUniformLocation,
    maxDepthTextureIndex: WebGLUniformLocation,
    actionTextureIndex: WebGLUniformLocation,
    referenceLatentCTexNum: WebGLUniformLocation,
    referenceLatentMTexNum: WebGLUniformLocation,
    referenceDepthTexNum: WebGLUniformLocation,
    workingLatentCTexNum: WebGLUniformLocation,
    workingLatentMTexNum: WebGLUniformLocation,
    workingDepthTexNum: WebGLUniformLocation,
    accumLatentCTexNum: WebGLUniformLocation,
    accumLatentMTexNum: WebGLUniformLocation,
    accumDepthTexNum: WebGLUniformLocation,
    actionFromTexNum: WebGLUniformLocation,
    actionFracTexNum: WebGLUniformLocation,
    actionCommitTexNum: WebGLUniformLocation,
    maxDepthTexNum: WebGLUniformLocation,
    dryTransmits: WebGLUniformLocation,
    workingWetAgeTexNum: WebGLUniformLocation,
};

export interface TouchProgramInfo extends ProgramInfo {
    uniformLocations: TouchUniformLocations
};

export interface TouchProgramBuffers extends ProgramBuffers {
    kmLookupTexture: WebGLTexture,
    nullDepthTexture: WebGLTexture | null,
    nullLatentCTexture: WebGLTexture | null,
    nullLatentMTexture: WebGLTexture | null,

    initialWetLatentCTexture: WebGLTexture | null,
    initialWetLatentMTexture: WebGLTexture | null,
    initialWetDepthTexture: WebGLTexture | null,
    initialWetAgeTexture: WebGLTexture | null,

    working1LatentCTexture: WebGLTexture | null,
    working1LatentMTexture: WebGLTexture | null,
    working1DepthTexture: WebGLTexture | null,
    working1Framebuffer: WebGLFramebuffer | null,
    working2LatentCTexture: WebGLTexture | null,
    working2LatentMTexture: WebGLTexture | null,
    working2DepthTexture: WebGLTexture | null,
    working2Framebuffer: WebGLFramebuffer | null,
    working3LatentCTexture: WebGLTexture | null,
    working3LatentMTexture: WebGLTexture | null,
    working3DepthTexture: WebGLTexture | null,
    working3Framebuffer: WebGLFramebuffer | null,
    accLatentCTexture: WebGLTexture | null,
    accLatentMTexture: WebGLTexture | null,
    accDepthTexture: WebGLTexture | null,
    accFramebuffer: WebGLFramebuffer | null,
    finalWetAgeTexture: WebGLTexture | null,

};

export abstract class TouchPageChange extends PageChange {
    
    static debugRenderDepth: TouchProgramInfo = {tag:'debugRenderDepth', uniformLocations:{}} as TouchProgramInfo;

    static externalToLatentProgram: TouchProgramInfo = {tag:'externalToLatentProgram', uniformLocations:{}} as TouchProgramInfo;
    static latentToExternalProgram: TouchProgramInfo = {tag:'latentToExternalProgram', uniformLocations:{}} as TouchProgramInfo;
    static processAccumulateTakesProgram: TouchProgramInfo = {tag:'processAccumulateTakesProgram', uniformLocations:{}} as TouchProgramInfo;
    static processCommitAccumulatorProgram: TouchProgramInfo = {tag:'processCommitAccumulatorProgram', uniformLocations:{}} as TouchProgramInfo;
    static wetChangesToVisibleChangesProgram: TouchProgramInfo = {tag:'wetChangesToVisibleChanges', uniformLocations:{}} as TouchProgramInfo;
    static visibleChangesToFinalProgram: TouchProgramInfo = {tag:'visibleChangesToFinal', uniformLocations:{}} as TouchProgramInfo;
    static touchBuffers: TouchProgramBuffers | undefined;

  

    static fsColorMixLatentSource = `
#line 89
highp uvec4 emptyLatentPart = uvec4(0, 0, 0, 0);
highp uvec4 smudgeLatentM = uvec4(0, 0, 0, 1);
highp uvec4 wetScrapeLatentM = uvec4(0, 0, 0, 2);
highp uvec4 dryScrapeLatentM = uvec4(0, 0, 0, 3);
highp uvec4 neutralLatentM = uvec4(43000, 33000, 27000, 0);
highp uvec4 whiteLatentC = uvec4(0, 0, 0, 65535);
highp uvec4 greyLatentC = uvec4(0, 0, 0, 42000);
highp uvec4 cyanLatentC = uvec4(65000, 0, 0, 32000);
highp uvec4 mustardLatentC = uvec4(0, 65000, 0, 0);
highp uvec4 greenLatentC = uvec4(65000, 65000, 0, 65535);
highp uvec4 orangeLatentC = uvec4(0, 65000, 65000, 0);
highp uvec4 redLatentC = uvec4(0, 0, 65000, 0);
highp uvec4 violetLatentC = uvec4(65000, 0, 65000, 0);

struct Latent {
    highp float concentration0;
    highp float concentration1;
    highp float concentration2;
    highp float concentration3;
    highp float missingRed;
    highp float missingGreen;
    highp float missingBlue;
    highp float isSmudge;
};

Latent emptyLatent = Latent(0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0);
Latent smudgeLatent = Latent(0.0,0.0,0.0,0.0,0.0,0.0,0.0,1.0);
`;

    static fsColorMixLerpLatentSource = TouchPageChange.fsColorMixLatentSource + `
#line 115
highp float u_latentScale = 65535.0;
highp float u_maxC0 = 0.993;
highp float u_minC0 = 0.0;
highp float u_maxC1 = 0.991;
highp float u_minC1 = 0.0;
highp float u_maxC2 = 0.998;
highp float u_minC2 = 0.0;
highp float u_maxC3 = 1.0;
highp float u_minC3 = -0.0087;
highp float u_maxMRed = 0.19;
highp float u_minMRed = -0.31;
highp float u_maxMGreen = 0.38;
highp float u_minMGreen = -0.38;
highp float u_maxMBlue = 0.39;
highp float u_minMBlue = -0.24;

// many latents give the same rgb.
bool latentPartMatch(uvec4 cpart1, uvec4 cpart2, uvec4 mpart1, uvec4 mpart2) {
    if (mpart1.a != mpart2.a) {
        return false;
    }
    if (abs(float(cpart1.r) - float(cpart2.r)) > 60.0 || abs(float(cpart1.g) - float(cpart2.g)) > 60.0 || abs(float(cpart1.b) - float(cpart2.b)) > 60.0 || abs(float(cpart1.a) - float(cpart2.a)) > 60.0 ||
        abs(float(mpart1.r) - float(mpart2.r)) > 60.0 || abs(float(mpart1.g) - float(mpart2.g)) > 60.0 || abs(float(mpart1.b) - float(mpart2.b)) > 60.0 || abs(float(mpart1.a) - float(mpart2.a)) > 60.0) {
        return false;
    }
    return true;
}

highp uvec4 latentToLatentC(Latent latent) {
    if (latent.isSmudge > 0.0 || latent == emptyLatent) {
        return emptyLatentPart;
    }
    highp float c0 = clamp(latent.concentration0, u_minC0, u_maxC0);
    highp float c1 = clamp(latent.concentration1, u_minC1, u_maxC1);
    highp float c2 = clamp(latent.concentration2, u_minC2, u_maxC2);
    highp float c3 = clamp(latent.concentration3, u_minC3, u_maxC3);
    c0 = (c0 - u_minC0) / (u_maxC0 - u_minC0);
    c1 = (c1 - u_minC1) / (u_maxC1 - u_minC1);
    c2 = (c2 - u_minC2) / (u_maxC2 - u_minC2);
    c3 = (c3 - u_minC3) / (u_maxC3 - u_minC3);
    return uvec4(uint(round(c0 * u_latentScale)), uint(round(c1 * u_latentScale)), uint(round(c2 * u_latentScale)), uint(round(c3 * u_latentScale)));
}
highp uvec4 latentToLatentM(Latent latent) {
    if (latent == emptyLatent) {
        return emptyLatentPart;
    }
    if (latent.isSmudge > 0.0) {
        return smudgeLatentM;
    }

    highp float missingRed = clamp(latent.missingRed, u_minMRed, u_maxMRed);
    highp float missingGreen = clamp(latent.missingGreen, u_minMGreen, u_maxMGreen);
    highp float missingBlue = clamp(latent.missingBlue, u_minMBlue, u_maxMBlue);
    missingRed = (missingRed - u_minMRed) / (u_maxMRed - u_minMRed);
    missingGreen = (missingGreen - u_minMGreen) / (u_maxMGreen - u_minMGreen);
    missingBlue = (missingBlue - u_minMBlue) / (u_maxMBlue - u_minMBlue);
    return uvec4(uint(round(missingRed * u_latentScale)), uint(round(missingGreen * u_latentScale)), uint(round(missingBlue * u_latentScale)), latent.isSmudge > 0.0 ? 1u : 0u);
}
Latent latentFromLatentCM(highp uvec4 latentC, highp uvec4 latentM) {
    if (latentC == emptyLatentPart && latentM == emptyLatentPart) {
        return emptyLatent;
    }
    if (latentC == emptyLatentPart && latentM == smudgeLatentM) {
        return smudgeLatent;
    }
    highp float c0 = float(latentC.r) / u_latentScale * (u_maxC0 - u_minC0) + u_minC0;
    highp float c1 = float(latentC.g) / u_latentScale * (u_maxC1 - u_minC1) + u_minC1;
    highp float c2 = float(latentC.b) / u_latentScale * (u_maxC2 - u_minC2) + u_minC2;
    highp float c3 = float(latentC.a) / u_latentScale * (u_maxC3 - u_minC3) + u_minC3;
    highp float missingRed = float(latentM.r) / u_latentScale * (u_maxMRed - u_minMRed) + u_minMRed;
    highp float missingGreen = float(latentM.g) / u_latentScale * (u_maxMGreen - u_minMGreen) + u_minMGreen;
    highp float missingBlue = float(latentM.b) / u_latentScale * (u_maxMBlue - u_minMBlue) + u_minMBlue;
    return Latent(c0, c1, c2, c3, missingRed, missingGreen, missingBlue, float(latentM.a));
}

Latent lerpLatent(Latent latent1, Latent latent2, highp float weight2) {
    if (latent1.isSmudge > 0.0) {
        return latent2;
    }
    if (latent2.isSmudge > 0.0) {
        return latent1;
    }
    highp float weight1 = 1.0 - weight2;
    return Latent(
        latent1.concentration0 * weight1 + latent2.concentration0 * weight2,
        latent1.concentration1 * weight1 + latent2.concentration1 * weight2,
        latent1.concentration2 * weight1 + latent2.concentration2 * weight2,
        latent1.concentration3 * weight1 + latent2.concentration3 * weight2,
        latent1.missingRed * weight1 + latent2.missingRed * weight2,
        latent1.missingGreen * weight1 + latent2.missingGreen * weight2,
        latent1.missingBlue * weight1 + latent2.missingBlue * weight2,
        0.0);
}`;

static fsColorMixRGBToLatentSource = TouchPageChange.fsColorMixLerpLatentSource + `
#line 199
Latent rgbaToLatent(highp vec4 rgba) {
    if (rgba.r == 1.0/255.0 && rgba.g == 1.0/255.0 && rgba.b == 1.0/255.0 && rgba.a == 1.0) {
        return smudgeLatent;
    }
    if (rgba.r == 0.0 && rgba.g == 0.0 && rgba.b == 0.0 && rgba.a == 0.0) {
        return emptyLatent;
    }
    highp float x = (rgba.r * 63.0);
    highp float y = (rgba.g * 63.0);
    highp float z = (rgba.b * 63.0);

    highp int ix = int(x);
    highp int iy = int(y);
    highp int iz = int(z);

    highp float tx = x - float(ix);
    highp float ty = y - float(iy);
    highp float tz = z - float(iz);

    highp int xyz = ix + iy*64 + iz*64*64;

    highp float c0 = 0.0;
    highp float c1 = 0.0;
    highp float c2 = 0.0;
    highp float w = 0.0;

    w = (1.0-tx)*(1.0-ty)*(1.0-tz); 
    c0 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 192) % 1024, (xyz+ 192) / 1024), 0).r); 
    c1 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 262336) % 1024, (xyz+ 262336) / 1024), 0).r);
    c2 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 524480) % 1024, (xyz+ 524480) / 1024), 0).r);
    w = (    tx)*(1.0-ty)*(1.0-tz); 
    c0 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 193) % 1024, (xyz+ 193) / 1024), 0).r);
    c1 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 262337) % 1024, (xyz+ 262337) / 1024), 0).r);
    c2 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 524481) % 1024, (xyz+ 524481) / 1024), 0).r);
    w = (1.0-tx)*(    ty)*(1.0-tz); 
    c0 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 256) % 1024, (xyz+ 256) / 1024), 0).r);
    c1 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 262400) % 1024, (xyz+ 262400) / 1024), 0).r);
    c2 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 524544) % 1024, (xyz+ 524544) / 1024), 0).r);
    w = (    tx)*(    ty)*(1.0-tz); 
    c0 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 257) % 1024, (xyz+ 257) / 1024), 0).r);
    c1 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 262401) % 1024, (xyz+ 262401) / 1024), 0).r);
    c2 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 524545) % 1024, (xyz+ 524545) / 1024), 0).r);
    w = (1.0-tx)*(1.0-ty)*(    tz); 
    c0 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 4288) % 1024, (xyz+ 4288) / 1024), 0).r);
    c1 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 266432) % 1024, (xyz+ 266432) / 1024), 0).r);
    c2 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 528576) % 1024, (xyz+ 528576) / 1024), 0).r);
    w = (    tx)*(1.0-ty)*(    tz); 
    c0 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 4289) % 1024, (xyz+ 4289) / 1024), 0).r);
    c1 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 266433) % 1024, (xyz+ 266433) / 1024), 0).r);
    c2 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 528577) % 1024, (xyz+ 528577) / 1024), 0).r);
    w = (1.0-tx)*(    ty)*(    tz); 
    c0 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 4352) % 1024, (xyz+ 4352) / 1024), 0).r);
    c1 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 266496) % 1024, (xyz+ 266496) / 1024), 0).r);
    c2 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 528640) % 1024, (xyz+ 528640) / 1024), 0).r);
    w = (    tx)*(    ty)*(    tz); 
    c0 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 4353) % 1024, (xyz+ 4353) / 1024), 0).r);
    c1 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 266497) % 1024, (xyz+ 266497) / 1024), 0).r);
    c2 += w*float(texelFetch(u_kmLookupTexNum, ivec2((xyz+ 528641) % 1024, (xyz+ 528641) / 1024), 0).r);

    c0 /= 255.0;
    c1 /= 255.0;
    c2 /= 255.0;

    highp float c3 = 1.0 - (c0 + c1 + c2);

    highp float c00 = c0 * c0;
    highp float c11 = c1 * c1;
    highp float c22 = c2 * c2;
    highp float c33 = c3 * c3;
    highp float c01 = c0 * c1;
    highp float c02 = c0 * c2;
    highp float c12 = c1 * c2;

    highp float rmix = 0.0;
    highp float gmix = 0.0;
    highp float bmix = 0.0;
    
    w = 0.0;

    w = c0*c00; rmix += +0.07717053*w; gmix += +0.02826978*w; bmix += +0.24832992*w;
    w = c1*c11; rmix += +0.95912302*w; gmix += +0.80256528*w; bmix += +0.03561839*w;
    w = c2*c22; rmix += +0.74683774*w; gmix += +0.04868586*w; bmix += +0.00000000*w;
    w = c3*c33; rmix += +0.99518138*w; gmix += +0.99978149*w; bmix += +0.99704802*w;
    w = c00*c1; rmix += +0.04819146*w; gmix += +0.83363781*w; bmix += +0.32515377*w;
    w = c01*c1; rmix += -0.68146950*w; gmix += +1.46107803*w; bmix += +1.06980936*w;
    w = c00*c2; rmix += +0.27058419*w; gmix += -0.15324870*w; bmix += +1.98735057*w;
    w = c02*c2; rmix += +0.80478189*w; gmix += +0.67093710*w; bmix += +0.18424500*w;
    w = c00*c3; rmix += -0.35031003*w; gmix += +1.37855826*w; bmix += +3.68865000*w;
    w = c0*c33; rmix += +1.05128046*w; gmix += +1.97815239*w; bmix += +2.82989073*w;
    w = c11*c2; rmix += +3.21607125*w; gmix += +0.81270228*w; bmix += +1.03384539*w;
    w = c1*c22; rmix += +2.78893374*w; gmix += +0.41565549*w; bmix += -0.04487295*w;
    w = c11*c3; rmix += +3.02162577*w; gmix += +2.55374103*w; bmix += +0.32766114*w;
    w = c1*c33; rmix += +2.95124691*w; gmix += +2.81201112*w; bmix += +1.17578442*w;
    w = c22*c3; rmix += +2.82677043*w; gmix += +0.79933038*w; bmix += +1.81715262*w;
    w = c2*c33; rmix += +2.99691099*w; gmix += +1.22593053*w; bmix += +1.80653661*w;
    w = c01*c2; rmix += +1.87394106*w; gmix += +2.05027182*w; bmix += -0.29835996*w;
    w = c01*c3; rmix += +2.56609566*w; gmix += +7.03428198*w; bmix += +0.62575374*w;
    w = c02*c3; rmix += +4.08329484*w; gmix += -1.40408358*w; bmix += +2.14995522*w;
    w = c12*c3; rmix += +6.00078678*w; gmix += +2.55552042*w; bmix += +1.90739502*w;

    return Latent(c0, c1, c2, c3, rgba.r-rmix, rgba.g-gmix, rgba.b-bmix, 0.0);
}
`;

static fsColorMixLatentToRGBSource = TouchPageChange.fsColorMixLerpLatentSource + `
#line 305
highp vec3 evalPolynomial(highp float c0, highp float c1, highp float c2, highp float c3) {
    highp float r = 0.0;
    highp float g = 0.0;
    highp float b = 0.0;

    highp float c00 = c0 * c0;
    highp float c11 = c1 * c1;
    highp float c22 = c2 * c2;
    highp float c33 = c3 * c3;
    highp float c01 = c0 * c1;
    highp float c02 = c0 * c2;
    highp float c12 = c1 * c2;

    highp float w = 0.0;
    w = c0*c00; r += +0.07717053*w; g += +0.02826978*w; b += +0.24832992*w;
    w = c1*c11; r += +0.95912302*w; g += +0.80256528*w; b += +0.03561839*w;
    w = c2*c22; r += +0.74683774*w; g += +0.04868586*w; b += +0.00000000*w;
    w = c3*c33; r += +0.99518138*w; g += +0.99978149*w; b += +0.99704802*w;
    w = c00*c1; r += +0.04819146*w; g += +0.83363781*w; b += +0.32515377*w;
    w = c01*c1; r += -0.68146950*w; g += +1.46107803*w; b += +1.06980936*w;
    w = c00*c2; r += +0.27058419*w; g += -0.15324870*w; b += +1.98735057*w;
    w = c02*c2; r += +0.80478189*w; g += +0.67093710*w; b += +0.18424500*w;
    w = c00*c3; r += -0.35031003*w; g += +1.37855826*w; b += +3.68865000*w;
    w = c0*c33; r += +1.05128046*w; g += +1.97815239*w; b += +2.82989073*w;
    w = c11*c2; r += +3.21607125*w; g += +0.81270228*w; b += +1.03384539*w;
    w = c1*c22; r += +2.78893374*w; g += +0.41565549*w; b += -0.04487295*w;
    w = c11*c3; r += +3.02162577*w; g += +2.55374103*w; b += +0.32766114*w;
    w = c1*c33; r += +2.95124691*w; g += +2.81201112*w; b += +1.17578442*w;
    w = c22*c3; r += +2.82677043*w; g += +0.79933038*w; b += +1.81715262*w;
    w = c2*c33; r += +2.99691099*w; g += +1.22593053*w; b += +1.80653661*w;
    w = c01*c2; r += +1.87394106*w; g += +2.05027182*w; b += -0.29835996*w;
    w = c01*c3; r += +2.56609566*w; g += +7.03428198*w; b += +0.62575374*w;
    w = c02*c3; r += +4.08329484*w; g += -1.40408358*w; b += +2.14995522*w;
    w = c12*c3; r += +6.00078678*w; g += +2.55552042*w; b += +1.90739502*w;
    
    return vec3(r, g, b);
}

highp vec4 latentToRgba(Latent latent) {
    if (latent.isSmudge > 0.0) {
        return vec4(1.0/255.0, 1.0/255.0, 1.0/255.0, 1.0);
    }
    if (latent == emptyLatent) {
        return vec4(0.0, 0.0, 0.0, 0.0);
    }

    highp vec3 rgb = evalPolynomial(latent.concentration0, latent.concentration1, latent.concentration2, latent.concentration3);

    highp float  r = max(0.0, min(1.0, rgb.r + latent.missingRed));
    highp float  g = max(0.0, min(1.0, rgb.g + latent.missingGreen));
    highp float  b = max(0.0, min(1.0, rgb.b + latent.missingBlue));

    if (r < 1.6/255.0 && g < 1.6/255.0 && b < 1.6/255.0) {
        r=g=b=0.0;
    }
    return vec4(r, g, b, 1.0);
}
`;

static fsActionPositionsSource = `
#line 366

highp ivec3 workingPositionToActionPosition(highp ivec2 workingPosition, highp uvec2 workingSize, highp ivec2 actionOffset, highp uvec2 actionSize, highp int textureIndex) {
    // external texture so flip y
    workingPosition.y = int(workingSize.y) - workingPosition.y - 1;

    highp ivec3 actionPosition = ivec3(workingPosition - actionOffset, textureIndex);
    if (actionPosition.x < 0 || uint(actionPosition.x) >= actionSize.x || actionPosition.y < 0 || uint(actionPosition.y) >= actionSize.y) {
        actionPosition.x = -1;
        actionPosition.y = -1;
    } 
    return actionPosition;
}

highp ivec2 actionIndexToWorkingPosition(highp uint actionIndex, highp ivec2 actionOffset, highp uvec2 actionSize, highp uvec2 workingSize) {
    highp ivec2 actionPosition = ivec2(actionIndex % actionSize.x, actionIndex / actionSize.x);
    highp ivec2 workingPosition = actionPosition + actionOffset;
    // external texture so flip y
    workingPosition.y = int(workingSize.y) - workingPosition.y - 1;
    return workingPosition;
}
`;


static fsSourceExternalToLatent = `#version 300 es
#line 391
uniform highp usampler2D u_kmLookupTexNum;
uniform highp sampler2D u_externalColorTexNum;
uniform highp usampler2D u_externalDepthTexNum;
uniform highp vec2 u_resolution;
in highp vec2 v_texCoordRendered;
in highp vec2 v_texCoordExternal;
layout(location = 0) out highp uvec4 outputLatentC;
layout(location = 1) out highp uvec4 outputLatentM;
layout(location = 2) out highp uint outputDepth;
` + TouchPageChange.fsColorMixRGBToLatentSource + `
#line 402
void main() {
    outputDepth = texture(u_externalDepthTexNum, v_texCoordExternal).r;
    highp vec4 outputColor = texture(u_externalColorTexNum, v_texCoordExternal);
    Latent outputLatent = rgbaToLatent(outputColor);
    outputLatentC = latentToLatentC(outputLatent);
    outputLatentM = latentToLatentM(outputLatent);
}`;

static fsSourceLatentToExternal = `#version 300 es
#line 412
uniform highp usampler2D u_workingLatentCTexNum;
uniform highp usampler2D u_workingLatentMTexNum;
uniform highp usampler2D u_workingDepthTexNum;
uniform highp usampler2D u_kmLookupTexNum;
in highp vec2 v_texCoordRendered;
in highp vec2 v_texCoordExternal;
layout(location = 0) out highp vec4 outputColor;
layout(location = 1) out highp uint outputDepth;
` + TouchPageChange.fsColorMixLatentToRGBSource + `
#line 422
void main() {
    outputDepth = texture(u_workingDepthTexNum, v_texCoordRendered).r;
    highp uvec4 latentC = texture(u_workingLatentCTexNum, v_texCoordRendered);
    highp uvec4 latentM = texture(u_workingLatentMTexNum, v_texCoordRendered);
    Latent outputLatent = latentFromLatentCM(latentC, latentM);
    outputColor = latentToRgba(outputLatent);
}`;

static fsSourceProcessGlobals = `#version 300 es
#line 432
uniform highp usampler2D u_workingLatentCTexNum;
uniform highp usampler2D u_workingLatentMTexNum;
uniform highp usampler2D u_workingDepthTexNum;
uniform highp usampler2D u_accumLatentCTexNum;
uniform highp usampler2D u_accumLatentMTexNum;
uniform highp usampler2D u_accumDepthTexNum;
uniform highp usampler2D u_referenceLatentCTexNum;
uniform highp usampler2D u_referenceLatentMTexNum;
uniform highp usampler2D u_referenceDepthTexNum;
uniform highp usampler2DArray u_maxDepthTexNum;
uniform highp sampler2DArray u_actionCommitTexNum;
uniform highp usampler2DArray u_actionFromTexNum;
uniform highp sampler2DArray u_actionFracTexNum;
uniform highp int u_maxDepthTextureIndex;
uniform highp int u_actionTextureIndex;
uniform highp int u_writeAccum;
uniform highp int u_debug;
in highp vec2 v_texCoordRendered;
in highp vec2 v_texCoordExternal;
layout(location = 0) out highp uvec4 outputLatentC;
layout(location = 1) out highp uvec4 outputLatentM;
layout(location = 2) out highp uint outputDepth;
uniform highp ivec2 u_actionTextureOffset;
uniform highp ivec2 u_maxDepthTextureOffset;
`;

static fsSourceProcessAccumulateTakes =
    TouchPageChange.fsSourceProcessGlobals  
    + TouchPageChange.fsColorMixLerpLatentSource
    + TouchPageChange.fsActionPositionsSource + 
`
#line 460

highp float accumulateTakes(highp float error, highp uint srcOffset, highp float srcFrac, highp ivec2 actionTextureOffset, highp uvec2 actionTextureSize, 
    highp ivec2 maxTextureOffset, highp uvec2 maxTextureSize, highp int maxDepthTextureIndex, highp uvec2 workingSize, 
    inout Latent outputLatent, inout highp uint outputDepth) {
    
    highp ivec2 workingSrcPos = actionIndexToWorkingPosition(srcOffset, actionTextureOffset, actionTextureSize, workingSize);
    highp ivec3 workingMaxPos = workingPositionToActionPosition(workingSrcPos, workingSize, maxTextureOffset, maxTextureSize, maxDepthTextureIndex);
    
    highp uint srcDepth = texelFetch(u_workingDepthTexNum, workingSrcPos, 0).r;
    highp uint srcMax = texelFetch(u_maxDepthTexNum, workingMaxPos, 0).r;

    highp float newError = error;
    highp float takef = ((float(srcDepth) - float(srcMax)) * srcFrac) + error;
    if (takef > 0.0) {
        highp uint takeDepth = uint(takef);
        newError = takef - float(takeDepth);
        highp float blendWeight = takef / (takef + float(outputDepth));
        Latent srcLatent = latentFromLatentCM(texelFetch(u_workingLatentCTexNum, workingSrcPos, 0), texelFetch(u_workingLatentMTexNum, workingSrcPos, 0));
        outputLatent = lerpLatent(outputLatent, srcLatent, blendWeight);
        outputDepth += takeDepth;
        // if (outputDepth > 65535u) {
        //     outputLatent = latentFromLatentCM(cyanLatentC, neutralLatentM);
        // }
    }
    return newError;
}

void main() {
    
    highp uvec2 workingSize = uvec2(textureSize(u_workingLatentCTexNum, 0));
    highp ivec2 workingPos = ivec2(v_texCoordRendered * vec2(workingSize));
    highp uvec2 actionSize = uvec2(textureSize(u_actionFromTexNum, 0));
    highp ivec3 actionPos = workingPositionToActionPosition(workingPos, workingSize, u_actionTextureOffset, actionSize, u_actionTextureIndex);
    highp uvec2 maxDepthSize = uvec2(textureSize(u_maxDepthTexNum, 0));
    
    if (actionPos.x < 0) {
        return;
    }
    // apply the movements
    // movement instructions are in the movements texture.
    highp uvec4 vMovements = texelFetch(u_actionFromTexNum, actionPos, 0);
    highp vec4 vFractions = texelFetch(u_actionFracTexNum, actionPos, 0);
    Latent outputLatent = smudgeLatent;
    outputDepth = uint(0);

    highp float error = 0.0;
    if (vMovements.r > uint(0)) {
        error = accumulateTakes(error, vMovements.r, vFractions.r, u_actionTextureOffset, actionSize, u_maxDepthTextureOffset, maxDepthSize, u_maxDepthTextureIndex, workingSize, outputLatent, outputDepth);
    }
    if (vMovements.g > uint(0)) {
        error = accumulateTakes(error, vMovements.g, vFractions.g, u_actionTextureOffset, actionSize, u_maxDepthTextureOffset, maxDepthSize, u_maxDepthTextureIndex, workingSize, outputLatent, outputDepth);
    }
    if (vMovements.b > uint(0)) {
        error = accumulateTakes(error, vMovements.b, vFractions.b, u_actionTextureOffset, actionSize, u_maxDepthTextureOffset, maxDepthSize, u_maxDepthTextureIndex, workingSize, outputLatent, outputDepth);
    }
    if (vMovements.a > uint(0)) {
        error = accumulateTakes(error, vMovements.a, vFractions.a, u_actionTextureOffset, actionSize, u_maxDepthTextureOffset, maxDepthSize, u_maxDepthTextureIndex, workingSize, outputLatent, outputDepth);
    }
    outputLatentC = latentToLatentC(outputLatent);
    outputLatentM = latentToLatentM(outputLatent);        
    
    return;
}
`;
static fsSourceProcessCommitAccumulator = 
    TouchPageChange.fsSourceProcessGlobals  
    + TouchPageChange.fsColorMixLerpLatentSource  
    + TouchPageChange.fsActionPositionsSource + 
`
#line 546

void main() {

    // apply the commit texture. the accumulator adds to the source and removes down to the max depth
    highp uvec2 workingSize = uvec2(textureSize(u_workingLatentCTexNum, 0));
    highp ivec2 workingPos = ivec2(v_texCoordRendered * vec2(workingSize));
    highp uvec2 actionSize = uvec2(textureSize(u_actionFromTexNum, 0));
    highp ivec3 actionPos = workingPositionToActionPosition(workingPos, workingSize, u_actionTextureOffset, actionSize, u_actionTextureIndex);
    highp uvec2 maxDepthSize = uvec2(textureSize(u_maxDepthTexNum, 0));
    highp ivec3 maxDepthPos = workingPositionToActionPosition(workingPos, workingSize, u_maxDepthTextureOffset, maxDepthSize, u_maxDepthTextureIndex);
    
    outputDepth = texture(u_workingDepthTexNum, v_texCoordRendered).r;
    if (outputDepth == 0u) {
        outputLatentC = emptyLatentPart;
        outputLatentM = smudgeLatentM;
    } else {
        outputLatentC = texture(u_workingLatentCTexNum, v_texCoordRendered);
        outputLatentM = texture(u_workingLatentMTexNum, v_texCoordRendered);
    }

        if (u_debug == 1) {
        outputDepth = 13u;
        return;
    }

    if (actionPos.x < 0) {
        return;
    }
    

    highp float destCommitFrac = texelFetch(u_actionCommitTexNum, actionPos, 0).r;
    if (destCommitFrac > 0.0) {
        highp uint destMaxDepth = texelFetch(u_maxDepthTexNum, maxDepthPos, 0).r;

        if (outputDepth > destMaxDepth) {
            highp float removef = (destCommitFrac * float(outputDepth - destMaxDepth));
            highp uint remove = uint(removef);
            if (remove >= outputDepth) {
                outputDepth = uint(0);
                outputLatentC = emptyLatentPart;
                outputLatentM = smudgeLatentM;
            } else {
                outputDepth -= remove;
            }
        }
    }

    highp uint accumDepth = texture(u_accumDepthTexNum, v_texCoordRendered).r;
    if (u_writeAccum == 1 && accumDepth > uint(0)) {
        // blend
        highp float blendWeight = float(accumDepth) / (float(accumDepth) + float(outputDepth));
        Latent outputLatent = latentFromLatentCM(outputLatentC, outputLatentM);
        outputLatent = lerpLatent(outputLatent, latentFromLatentCM(texture(u_accumLatentCTexNum, v_texCoordRendered), texture(u_accumLatentMTexNum, v_texCoordRendered)), blendWeight);
        outputLatentC = latentToLatentC(outputLatent);
        outputLatentM = latentToLatentM(outputLatent);
        outputDepth += accumDepth;
        // if (outputDepth > 65535u) {
        //     outputLatentC = cyanLatentC;
        //     outputLatentM = neutralLatentM;
        // }
    }
}
`;

static fsSourceCentralPull = 
TouchPageChange.fsSourceProcessGlobals   
+ TouchPageChange.fsColorMixLerpLatentSource  
+`
#line 620

void main() {
    // working is the wet paint. reference is the starting wet. accumulator is the pull gradients
    highp uvec4 newWetLatentC = texture(u_workingLatentCTexNum, v_texCoordRendered);
    highp uvec4 newWetLatentM = texture(u_workingLatentMTexNum, v_texCoordRendered);
    highp uvec4 newWetDepth = texture(u_workingDepthTexNum, v_texCoordRendered);
    highp uvec4 startWetLatentC = texture(u_referenceLatentCTexNum, v_texCoordRendered);
    highp uvec4 startWetLatentM = texture(u_referenceLatentMTexNum, v_texCoordRendered);
    highp uvec4 startWetDepth = texture(u_referenceDepthTexNum, v_texCoordRendered);
    highp uvec4 pullParams0123 = texture(u_accumLatentCTexNum, v_texCoordRendered);
    highp uvec4 pullParams5678 = texture(u_accumLatentMTexNum, v_texCoordRendered);
    highp uvec4 pullParamsSelf = texture(u_accumDepthTexNum, v_texCoordRendered);

    outputDepth = 0u;
    outputLatentC = emptyLatentPart;
    outputLatentM = emptyLatentPart;

    if (newWetDepth.r == startWetDepth.r && latentPartMatch(newWetLatentC, startWetLatentC, newWetLatentM, startWetLatentM)) {
        outputDepth = startWetDepth;
        outputLatentC = startWetLatentC;
        outputLatentM = startWetLatentM;
        return;
    }
    // 


    // blend
    // scale between depth and 4* depth so thicker paint blocks out effect of blending much more
    highp float scaledDepth = float(newWetDepth.r);
    if (scaledDepth > preferredSmashScrapeDepth) {
        scaledDepth = 4.0 * preferredMeniscusScrapeDepth * ((scaledDepth-preferredSmashScrapeDepth)/(preferredMeniscusScrapeDepth - preferredSmashScrapeDepth));
    }
    highp float depthRat = backgroundImplicitDepth / (scaledDepth + backgroundImplicitDepth);
    Latent outputLatent = latentFromLatentCM(dryLatentC, dryLatentM);
    outputLatent = lerpLatent(latentFromLatentCM(newWetLatentC, newWetLatentM), outputLatent, depthRat);
    outputLatentC = latentToLatentC(outputLatent);
    outputLatentM = latentToLatentM(outputLatent);
    
    return;
}
`;


static fsSourceWetChangesToVisibleChanges = `#version 300 es
#line 606
uniform highp usampler2D u_workingLatentCTexNum;
uniform highp usampler2D u_workingLatentMTexNum;
uniform highp usampler2D u_workingDepthTexNum;
uniform highp usampler2D u_workingWetAgeTexNum;
uniform highp usampler2D u_accumLatentCTexNum;
uniform highp usampler2D u_accumLatentMTexNum;
uniform highp usampler2D u_accumDepthTexNum;
uniform highp usampler2D u_referenceLatentCTexNum;
uniform highp usampler2D u_referenceLatentMTexNum;
uniform highp usampler2D u_referenceDepthTexNum;
uniform highp int u_dryTransmits;
in highp vec2 v_texCoordRendered;
in highp vec2 v_texCoordExternal;
layout(location = 0) out highp uvec4 outputLatentC;
layout(location = 1) out highp uvec4 outputLatentM;
layout(location = 2) out highp uint outputDepth;
layout(location = 3) out highp uint outputAge;

highp float preferredMeniscusScrapeDepth = 8.0 * 4.0; 
highp float preferredSmashScrapeDepth = 3.0 * 4.0; 
highp float backgroundImplicitDepth = 1.5;
`  
+ TouchPageChange.fsColorMixLerpLatentSource  
+`
#line 631

void main() {
    // make the new wet paint mask. source is the new wet paint and accumulator is the starting
    highp uvec4 newWetLatentC = texture(u_workingLatentCTexNum, v_texCoordRendered);
    highp uvec4 newWetLatentM = texture(u_workingLatentMTexNum, v_texCoordRendered);
    highp uvec4 newWetDepth = texture(u_workingDepthTexNum, v_texCoordRendered);
    highp uvec4 startWetLatentC = texture(u_accumLatentCTexNum, v_texCoordRendered);
    highp uvec4 startWetLatentM = texture(u_accumLatentMTexNum, v_texCoordRendered);
    highp uvec4 startWetDepth = texture(u_accumDepthTexNum, v_texCoordRendered);
    highp uint startWetAge = texture(u_workingWetAgeTexNum, v_texCoordRendered).r;

    outputAge = startWetAge;
    outputDepth = 0u;
    outputLatentC = emptyLatentPart;
    outputLatentM = emptyLatentPart;

    if (newWetDepth.r == startWetDepth.r && latentPartMatch(newWetLatentC, startWetLatentC, newWetLatentM, startWetLatentM)) {
        return;
    }
    
    highp uvec4 dryLatentC = texture(u_referenceLatentCTexNum, v_texCoordRendered);
    highp uvec4 dryLatentM = texture(u_referenceLatentMTexNum, v_texCoordRendered);

    if (newWetDepth.r == 0u) {
        outputLatentC = dryLatentC;
        outputLatentM = dryLatentM;
        outputAge = 0u;
        return;
    }
    outputAge = 8u;
        
    if (u_dryTransmits == 0) {
        outputLatentC = newWetLatentC;
        outputLatentM = newWetLatentM;
        return;
    }
    // blend
    // scale between depth and 4* depth so thicker paint blocks out effect of blending much more
    highp float scaledDepth = float(newWetDepth.r);
    if (scaledDepth > preferredSmashScrapeDepth) {
        scaledDepth = 4.0 * preferredMeniscusScrapeDepth * ((scaledDepth-preferredSmashScrapeDepth)/(preferredMeniscusScrapeDepth - preferredSmashScrapeDepth));
    }
    highp float depthRat = backgroundImplicitDepth / (scaledDepth + backgroundImplicitDepth);
    Latent outputLatent = latentFromLatentCM(dryLatentC, dryLatentM);
    outputLatent = lerpLatent(latentFromLatentCM(newWetLatentC, newWetLatentM), outputLatent, depthRat);
    outputLatentC = latentToLatentC(outputLatent);
    outputLatentM = latentToLatentM(outputLatent);
    
    return;
}
`;

static fsSourceVisibleChangesToFinal = `#version 300 es
#line 682
uniform highp usampler2D u_workingLatentCTexNum;
uniform highp usampler2D u_workingLatentMTexNum;
uniform highp usampler2D u_workingDepthTexNum;
uniform highp usampler2D u_accumLatentCTexNum;
uniform highp usampler2D u_accumLatentMTexNum;
uniform highp usampler2D u_accumDepthTexNum;
in highp vec2 v_texCoordRendered;
in highp vec2 v_texCoordExternal;
layout(location = 0) out highp uvec4 outputLatentC;
layout(location = 1) out highp uvec4 outputLatentM;

`  
+ TouchPageChange.fsColorMixLerpLatentSource  
+`
#line 697

void accumulateInfluence(highp uvec4 inflLatentC, highp uvec4 inflLatentM, highp float inf, inout Latent influenceLatent, inout highp float votes) {
    if (!(inflLatentC == emptyLatentPart && inflLatentM == emptyLatentPart)) {
        Latent l = latentFromLatentCM(inflLatentC, inflLatentM);
        if (votes == 0.0) {
            votes=inf;
            influenceLatent = l;
        } else {
            // blend
            votes+=inf;
            if (influenceLatent != l) {
                highp float blendWeight = inf / votes;
                influenceLatent = lerpLatent(influenceLatent, l, blendWeight);
            }
        }
    }
}

void main() {
    // produce the final visible.
    // source is external visible
    // accumulator is the new visible mask from step 6
    highp uvec4 newVisibleLatentC = texture(u_accumLatentCTexNum, v_texCoordRendered);
    highp uvec4 newVisibleLatentM = texture(u_accumLatentMTexNum, v_texCoordRendered);
    highp uvec4 oldVisibleLatentC = texture(u_workingLatentCTexNum, v_texCoordRendered);
    highp uvec4 oldVisibleLatentM = texture(u_workingLatentMTexNum, v_texCoordRendered);

    highp uvec2 workingSize = uvec2(textureSize(u_accumLatentCTexNum, 0));
    highp ivec2 workingPos = ivec2(v_texCoordRendered * vec2(workingSize));

    if (!(newVisibleLatentC == emptyLatentPart && newVisibleLatentM == emptyLatentPart)) {
        outputLatentC = newVisibleLatentC;
        outputLatentM = newVisibleLatentM;
    } else {
        outputLatentC = oldVisibleLatentC;
        outputLatentM = oldVisibleLatentM;

        // if next to any new visibles, collect the influence for anti aliasing
        Latent influenceLatent = smudgeLatent;
        highp float votes = 0.0;

        highp uvec4 inflLatentC = texelFetch(u_accumLatentCTexNum, workingPos + ivec2(-1, -1), 0);
        highp uvec4 inflLatentM = texelFetch(u_accumLatentMTexNum, workingPos + ivec2(-1, -1), 0);
        accumulateInfluence(inflLatentC, inflLatentM, 0.707, influenceLatent, votes);
        inflLatentC = texelFetch(u_accumLatentCTexNum, workingPos + ivec2(0, -1), 0);
        inflLatentM = texelFetch(u_accumLatentMTexNum, workingPos + ivec2(0, -1), 0);
        accumulateInfluence(inflLatentC, inflLatentM, 1.0, influenceLatent, votes);
        inflLatentC = texelFetch(u_accumLatentCTexNum, workingPos + ivec2(1, -1), 0);
        inflLatentM = texelFetch(u_accumLatentMTexNum, workingPos + ivec2(1, -1), 0);
        accumulateInfluence(inflLatentC, inflLatentM, 0.707, influenceLatent, votes);
        inflLatentC = texelFetch(u_accumLatentCTexNum, workingPos + ivec2(-1, 0), 0);
        inflLatentM = texelFetch(u_accumLatentMTexNum, workingPos + ivec2(-1, 0), 0);
        accumulateInfluence(inflLatentC, inflLatentM, 1.0, influenceLatent, votes);
        inflLatentC = texelFetch(u_accumLatentCTexNum, workingPos + ivec2(1, 0), 0);
        inflLatentM = texelFetch(u_accumLatentMTexNum, workingPos + ivec2(1, 0), 0);
        accumulateInfluence(inflLatentC, inflLatentM, 0.707, influenceLatent, votes);
        inflLatentC = texelFetch(u_accumLatentCTexNum, workingPos + ivec2(-1, 1), 0);
        inflLatentM = texelFetch(u_accumLatentMTexNum, workingPos + ivec2(-1, 1), 0);
        accumulateInfluence(inflLatentC, inflLatentM, 0.707, influenceLatent, votes);
        inflLatentC = texelFetch(u_accumLatentCTexNum, workingPos + ivec2(0, 1), 0);
        inflLatentM = texelFetch(u_accumLatentMTexNum, workingPos + ivec2(0, 1), 0);
        accumulateInfluence(inflLatentC, inflLatentM, 1.0, influenceLatent, votes);
        inflLatentC = texelFetch(u_accumLatentCTexNum, workingPos + ivec2(1, 1), 0);
        inflLatentM = texelFetch(u_accumLatentMTexNum, workingPos + ivec2(1, 1), 0);
        accumulateInfluence(inflLatentC, inflLatentM, 0.707, influenceLatent, votes);

        if (votes > 100.0) {
            // blend
            highp float depthRat = votes / 10.0;
            Latent outputLatent = latentFromLatentCM(outputLatentC, outputLatentM);
            outputLatent = lerpLatent(outputLatent, influenceLatent, depthRat);
            outputLatentC = latentToLatentC(outputLatent);
            outputLatentM = latentToLatentM(outputLatent);
            //outputLatentM = neutralLatentM;
            //outputLatentC = cyanLatentC;
        }
    }
    return;
}
`;


static fsSourceDebugRenderDepth = `#version 300 es
#line 748
uniform highp usampler2D u_workingLatentCTexNum;
uniform highp usampler2D u_workingLatentMTexNum;
uniform highp usampler2D u_workingDepthTexNum;
uniform highp usampler2D u_kmLookupTexNum;
in highp vec2 v_texCoordRendered;
in highp vec2 v_texCoordExternal;
layout(location = 0) out highp vec4 outputColor;
layout(location = 1) out highp uint outputDepth;
` + TouchPageChange.fsColorMixLatentToRGBSource + `
#line 348
void main() {
    outputDepth = texture(u_workingDepthTexNum, v_texCoordRendered).r;
    if (outputDepth == 0u) {
        outputColor = vec4(0.0, 0.0, 0.0, 1.0);
    } else if (outputDepth < 5u) {
        outputColor = vec4(1.0, 1.0, 1.0, 1.0);
    } else if (outputDepth < 11u) {
        outputColor = vec4(1.0, 0.0, 0.0, 1.0);
    } else if (outputDepth < 21u) {
        outputColor = vec4(1.0, 0.5, 0.0, 1.0);
    } else if (outputDepth < 36u) {
        outputColor = vec4(1.0, 1.0, 0.0, 1.0);
    } else if (outputDepth < 66u) {
        outputColor = vec4(0.0, 1.0, 0.0, 1.0);
    } else if (outputDepth < 150u) {
        outputColor = vec4(0.0, 0.5, 0.9, 1.0);
    } else if (outputDepth < 500u) {
        outputColor = vec4(0.0, 0.0, 1.0, 1.0);
    } else if (outputDepth < 2001u) {
        outputColor = vec4(0.0, 0.5, 0.9, 1.0);
    } else {
        outputColor = vec4(0.5, 0.5, 0.5, 1.0);
    }
}`;


    static getPrograms(): Array<[string, ProgramInfo]> {
        return [[TouchPageChange.fsSourceExternalToLatent, TouchPageChange.externalToLatentProgram],
            [TouchPageChange.fsSourceLatentToExternal, TouchPageChange.latentToExternalProgram], 
            [TouchPageChange.fsSourceProcessAccumulateTakes, TouchPageChange.processAccumulateTakesProgram], 
            [TouchPageChange.fsSourceProcessCommitAccumulator, TouchPageChange.processCommitAccumulatorProgram], 
            [TouchPageChange.fsSourceWetChangesToVisibleChanges, TouchPageChange.wetChangesToVisibleChangesProgram],
            [TouchPageChange.fsSourceVisibleChangesToFinal, TouchPageChange.visibleChangesToFinalProgram],
            [TouchPageChange.fsSourceDebugRenderDepth, TouchPageChange.debugRenderDepth],
            
        ];
    }

    static initProgramLocations(gl: WebGL2RenderingContext) {
        TouchPageChange.externalToLatentProgram.uniformLocations = {} as TouchUniformLocations;
        TouchPageChange.addStartupProgramLocations(gl, TouchPageChange.externalToLatentProgram);

        TouchPageChange.latentToExternalProgram.uniformLocations = {} as TouchUniformLocations;
        TouchPageChange.addStartupProgramLocations(gl, TouchPageChange.latentToExternalProgram);

        TouchPageChange.processAccumulateTakesProgram.uniformLocations = {} as TouchUniformLocations;
        TouchPageChange.addProcessProgramLocations(gl, TouchPageChange.processAccumulateTakesProgram);

        TouchPageChange.processCommitAccumulatorProgram.uniformLocations = {} as TouchUniformLocations;
        TouchPageChange.addProcessProgramLocations(gl, TouchPageChange.processCommitAccumulatorProgram);

        TouchPageChange.wetChangesToVisibleChangesProgram.uniformLocations = {} as TouchUniformLocations;
        TouchPageChange.addFinishProgramLocations(gl, TouchPageChange.wetChangesToVisibleChangesProgram);

        TouchPageChange.visibleChangesToFinalProgram.uniformLocations = {} as TouchUniformLocations;
        TouchPageChange.addFinishProgramLocations(gl, TouchPageChange.visibleChangesToFinalProgram);

        TouchPageChange.debugRenderDepth.uniformLocations = {} as TouchUniformLocations;
        TouchPageChange.addStartupProgramLocations(gl, TouchPageChange.debugRenderDepth);

    }

    static addSharedProgramLocations(gl: WebGL2RenderingContext, programInfo: TouchProgramInfo) {
        programInfo.uniformLocations.kmLookupTexNum = gl.getUniformLocation(programInfo.program, 'u_kmLookupTexNum')!;
        programInfo.uniformLocations.workingLatentCTexNum = gl.getUniformLocation(programInfo.program, 'u_workingLatentCTexNum')!;
        programInfo.uniformLocations.workingLatentMTexNum = gl.getUniformLocation(programInfo.program, 'u_workingLatentMTexNum')!;
        programInfo.uniformLocations.workingDepthTexNum = gl.getUniformLocation(programInfo.program, 'u_workingDepthTexNum')!;
        programInfo.uniformLocations.accumLatentCTexNum = gl.getUniformLocation(programInfo.program, 'u_accumLatentCTexNum')!;
        programInfo.uniformLocations.accumLatentMTexNum = gl.getUniformLocation(programInfo.program, 'u_accumLatentMTexNum')!;
        programInfo.uniformLocations.accumDepthTexNum = gl.getUniformLocation(programInfo.program, 'u_accumDepthTexNum')!;
        programInfo.uniformLocations.referenceLatentCTexNum = gl.getUniformLocation(programInfo.program, 'u_referenceLatentCTexNum')!;
        programInfo.uniformLocations.referenceLatentMTexNum = gl.getUniformLocation(programInfo.program, 'u_referenceLatentMTexNum')!;
        programInfo.uniformLocations.referenceDepthTexNum = gl.getUniformLocation(programInfo.program, 'u_referenceDepthTexNum')!;

    }

    static addStartupProgramLocations(gl: WebGL2RenderingContext, programInfo: TouchProgramInfo) {
        TouchPageChange.addSharedProgramLocations(gl, programInfo);
        PageChange.addProgramLocations(gl, programInfo);
    }

    static addProcessProgramLocations(gl: WebGL2RenderingContext, programInfo: TouchProgramInfo) {
        TouchPageChange.addSharedProgramLocations(gl, programInfo);
        programInfo.uniformLocations.maxDepthTexNum = gl.getUniformLocation(programInfo.program, 'u_maxDepthTexNum')!;
        programInfo.uniformLocations.actionCommitTexNum = gl.getUniformLocation(programInfo.program, 'u_actionCommitTexNum')!;
        programInfo.uniformLocations.actionFromTexNum = gl.getUniformLocation(programInfo.program, 'u_actionFromTexNum')!;
        programInfo.uniformLocations.actionFracTexNum = gl.getUniformLocation(programInfo.program, 'u_actionFracTexNum')!;
        programInfo.uniformLocations.maxDepthTextureIndex = gl.getUniformLocation(programInfo.program, 'u_maxDepthTextureIndex')!;
        programInfo.uniformLocations.actionTextureIndex = gl.getUniformLocation(programInfo.program, 'u_actionTextureIndex')!;
        programInfo.uniformLocations.writeAccum = gl.getUniformLocation(programInfo.program, 'u_writeAccum')!;
        programInfo.uniformLocations.debug = gl.getUniformLocation(programInfo.program, 'u_debug')!;
        programInfo.uniformLocations.actionTextureOffset = gl.getUniformLocation(programInfo.program, 'u_actionTextureOffset')!;
        programInfo.uniformLocations.maxDepthTextureOffset = gl.getUniformLocation(programInfo.program, 'u_maxDepthTextureOffset')!;

        PageChange.addProgramLocations(gl, programInfo);
    }

    static addFinishProgramLocations(gl: WebGL2RenderingContext, programInfo: TouchProgramInfo) {
        TouchPageChange.addSharedProgramLocations(gl, programInfo);
        programInfo.uniformLocations.dryTransmits = gl.getUniformLocation(programInfo.program, 'u_dryTransmits')!;
        programInfo.uniformLocations.workingWetAgeTexNum = gl.getUniformLocation(programInfo.program, 'u_workingWetAgeTexNum')!;

        PageChange.addProgramLocations(gl, programInfo);
    }

    static initPermanentStorage(gl: WebGL2RenderingContext, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {
        TouchPageChange.touchBuffers = {} as TouchProgramBuffers;
        TouchPageChange.addPermanentStorage(gl, TouchPageChange.touchBuffers, textureCallback);
    }
    static addPermanentStorage(gl: WebGL2RenderingContext, buffers: TouchProgramBuffers, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {
        PageChange.addPermanentStorage(gl, buffers, textureCallback);
        if (TouchPageChange.touchBuffers!.kmLookupTexture == undefined) {
            const initialWetLatentCTex = GPURunner.initTexture(gl, 'initialWetLatentCTex');
            const initialWetLatentMTex = GPURunner.initTexture(gl, 'initialWetLatentMTex');
            const initialWetDepthTex = GPURunner.initTexture(gl, 'initialWetDepthTex');
            const initialWetAgeTex = GPURunner.initTexture(gl, 'initialWetAgeTex');
            const working1LatentCTex = GPURunner.initTexture(gl, 'working1LatentCTex');
            const working1LatentMTex = GPURunner.initTexture(gl, 'working1LatentMTex');
            const working1DepthTex = GPURunner.initTexture(gl, 'working1DepthTex');
            const working2LatentCTex = GPURunner.initTexture(gl, 'working2LatentCTex');
            const working2LatentMTex = GPURunner.initTexture(gl, 'working2LatentMTex');
            const working2DepthTex = GPURunner.initTexture(gl, 'working2DepthTex');
            const working3LatentCTex = GPURunner.initTexture(gl, 'working3LatentCTex');
            const working3LatentMTex = GPURunner.initTexture(gl, 'working3LatentMTex');
            const working3DepthTex = GPURunner.initTexture(gl, 'working3DepthTex');
            const accLatentCTex = GPURunner.initTexture(gl, 'accLatentCTex');
            const accLatentMTex = GPURunner.initTexture(gl, 'accLatentMTex');
            const accDepthTex = GPURunner.initTexture(gl, 'accDepthTex');

            TouchPageChange.touchBuffers!.kmLookupTexture = GPURunner.initTexture(gl, 'kmLookupTexture');
            TouchPageChange.touchBuffers!.nullDepthTexture = GPURunner.initTexture(gl, 'nullDepthTexture');
            TouchPageChange.touchBuffers!.nullLatentCTexture = GPURunner.initTexture(gl, 'nullLatentCTexture');
            TouchPageChange.touchBuffers!.nullLatentMTexture = GPURunner.initTexture(gl, 'nullLatentMTexture');
            TouchPageChange.touchBuffers!.initialWetLatentCTexture = initialWetLatentCTex;
            TouchPageChange.touchBuffers!.initialWetLatentMTexture = initialWetLatentMTex;
            TouchPageChange.touchBuffers!.initialWetDepthTexture = initialWetDepthTex;
            TouchPageChange.touchBuffers!.initialWetAgeTexture = initialWetAgeTex;
            TouchPageChange.touchBuffers!.working1LatentCTexture = working1LatentCTex;
            TouchPageChange.touchBuffers!.working1LatentMTexture = working1LatentMTex;
            TouchPageChange.touchBuffers!.working1DepthTexture = working1DepthTex;
            TouchPageChange.touchBuffers!.working1Framebuffer = GPURunner.initTextureFramebuffer(gl, 'working1Framebuffer', working1LatentCTex, working1LatentMTex, working1DepthTex);
            TouchPageChange.touchBuffers!.working2LatentCTexture = working2LatentCTex;
            TouchPageChange.touchBuffers!.working2LatentMTexture = working2LatentMTex;
            TouchPageChange.touchBuffers!.working2DepthTexture = working2DepthTex;
            TouchPageChange.touchBuffers!.working2Framebuffer = GPURunner.initTextureFramebuffer(gl, 'working2Framebuffer', working2LatentCTex, working2LatentMTex, working2DepthTex);
            TouchPageChange.touchBuffers!.working3LatentCTexture = working3LatentCTex;
            TouchPageChange.touchBuffers!.working3LatentMTexture = working3LatentMTex;
            TouchPageChange.touchBuffers!.working3DepthTexture = working3DepthTex;
            TouchPageChange.touchBuffers!.working3Framebuffer = GPURunner.initTextureFramebuffer(gl, 'working3Framebuffer', working3LatentCTex, working3LatentMTex, working3DepthTex);
            TouchPageChange.touchBuffers!.accLatentCTexture = accLatentCTex;
            TouchPageChange.touchBuffers!.accLatentMTexture = accLatentMTex;
            TouchPageChange.touchBuffers!.accDepthTexture = accDepthTex;
            TouchPageChange.touchBuffers!.accFramebuffer = GPURunner.initTextureFramebuffer(gl, 'accFramebuffer', accLatentCTex, accLatentMTex, accDepthTex);

            TouchPageChange.touchBuffers!.finalWetAgeTexture = GPURunner.initTexture(gl, 'finalWetAgeTexture');

            let lut = getLookUpTable();
            gl.activeTexture(gl.TEXTURE0 + TouchPageChange.kmLookupTexNumVal);
            gl.bindTexture(gl.TEXTURE_2D, TouchPageChange.touchBuffers!.kmLookupTexture);
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.R8UI, 1024, 773, 0, gl.RED_INTEGER, gl.UNSIGNED_BYTE, lut);

            gl.activeTexture(gl.TEXTURE0 + TouchPageChange.accumDepthTexNumVal);
            gl.bindTexture(gl.TEXTURE_2D, TouchPageChange.touchBuffers!.nullDepthTexture);
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.R16UI,
                2, 2, 0,
                gl.RED_INTEGER, gl.UNSIGNED_SHORT, null);

            gl.activeTexture(gl.TEXTURE0 + TouchPageChange.accumLatentCTexNumVal);
            gl.bindTexture(gl.TEXTURE_2D, TouchPageChange.touchBuffers!.nullLatentCTexture);
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA16UI,
                2, 2, 0,
                gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, null);
            gl.activeTexture(gl.TEXTURE0 + TouchPageChange.accumLatentMTexNumVal);
            gl.bindTexture(gl.TEXTURE_2D, TouchPageChange.touchBuffers!.nullLatentMTexture);
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA16UI,
                2, 2, 0,
                gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, null);
    
        }

        buffers.kmLookupTexture = TouchPageChange.touchBuffers!.kmLookupTexture;
        buffers.nullDepthTexture = TouchPageChange.touchBuffers!.nullDepthTexture;
        buffers.nullLatentCTexture = TouchPageChange.touchBuffers!.nullLatentCTexture;
        buffers.nullLatentMTexture = TouchPageChange.touchBuffers!.nullLatentMTexture;
        buffers.initialWetLatentCTexture = TouchPageChange.touchBuffers!.initialWetLatentCTexture;
        buffers.initialWetLatentMTexture = TouchPageChange.touchBuffers!.initialWetLatentMTexture;
        buffers.initialWetDepthTexture = TouchPageChange.touchBuffers!.initialWetDepthTexture;
        buffers.initialWetAgeTexture = TouchPageChange.touchBuffers!.initialWetAgeTexture;
        buffers.working1LatentCTexture = TouchPageChange.touchBuffers!.working1LatentCTexture;
        buffers.working1LatentMTexture = TouchPageChange.touchBuffers!.working1LatentMTexture;
        buffers.working1DepthTexture = TouchPageChange.touchBuffers!.working1DepthTexture;
        buffers.working1Framebuffer = TouchPageChange.touchBuffers!.working1Framebuffer;
        buffers.working2LatentCTexture = TouchPageChange.touchBuffers!.working2LatentCTexture;
        buffers.working2LatentMTexture = TouchPageChange.touchBuffers!.working2LatentMTexture;
        buffers.working2DepthTexture = TouchPageChange.touchBuffers!.working2DepthTexture;
        buffers.working2Framebuffer = TouchPageChange.touchBuffers!.working2Framebuffer;
        buffers.working3LatentCTexture = TouchPageChange.touchBuffers!.working3LatentCTexture;
        buffers.working3LatentMTexture = TouchPageChange.touchBuffers!.working3LatentMTexture;
        buffers.working3DepthTexture = TouchPageChange.touchBuffers!.working3DepthTexture;
        buffers.working3Framebuffer = TouchPageChange.touchBuffers!.working3Framebuffer;
        buffers.accLatentCTexture = TouchPageChange.touchBuffers!.accLatentCTexture;
        buffers.accLatentMTexture = TouchPageChange.touchBuffers!.accLatentMTexture;
        buffers.accDepthTexture = TouchPageChange.touchBuffers!.accDepthTexture;
        buffers.accFramebuffer = TouchPageChange.touchBuffers!.accFramebuffer;
        buffers.finalWetAgeTexture = TouchPageChange.touchBuffers!.finalWetAgeTexture;
    }

    // shared
    static workingLatentCTexNumVal = 0;
    static workingLatentMTexNumVal = 1;
    static workingDepthTexNumVal = 2;
    static workingWetAgeTexNumVal = 3;
    static accumLatentCTexNumVal = 4;
    static accumLatentMTexNumVal = 5;
    static accumDepthTexNumVal = 6;
    static referenceLatentCTexNumVal = 7;
    static referenceLatentMTexNumVal = 8;
    static referenceDepthTexNumVal = 9;
    static kmLookupTexNumVal = 10;
    // process only
    static maxDepthTexNumVal = 11;
    static actionFromTexNumVal = 12;
    static actionFracTexNumVal = 13;
    static actionCommitTexNumVal = 14;

    // finish only



    entries: Array<PageChangeEntry>;
    tipApplication: TipApplication;
    dryTransmits: boolean;
    centralPull: ((d:number)=>number) | undefined

    workingLatentCTexture: WebGLTexture | null = null;
    workingLatentMTexture: WebGLTexture | null = null;
    workingDepthTexture: WebGLTexture | null = null;
    workingFramebuffer: WebGLFramebuffer | null = null;
    accLatentCTexture: WebGLTexture | null = null;
    accLatentMTexture: WebGLTexture | null = null;
    accDepthTexture: WebGLTexture | null = null;
    accFramebuffer: WebGLFramebuffer | null = null;
    outputLatentCTexture: WebGLTexture | null = null;
    outputLatentMTexture: WebGLTexture | null = null;
    outputDepthTexture: WebGLTexture | null = null;
    outputFramebuffer: WebGLFramebuffer | null = null;
    stashedLatentCTexture: WebGLTexture | null = null;
    stashedLatentMTexture: WebGLTexture | null = null;
    stashedDepthTexture: WebGLTexture | null = null;
    stashedFramebuffer: WebGLFramebuffer | null = null;
    preservedLatentCTexture: WebGLTexture | null = null;
    preservedLatentMTexture: WebGLTexture | null = null;
    preservedDepthTexture: WebGLTexture | null = null;
    preservedFramebuffer: WebGLFramebuffer | null = null;

    constructor(page: Page, pageX: number, pageY: number, width: number, height: number,
            tipsAndLocations: Array<PageChangeEntry>, tipApplication: TipApplication,
            dryTransmits: boolean, centralPull: ((d:number)=>number) | undefined) {
        super(page, pageX, pageY, width, height);
        this.entries = tipsAndLocations;
        this.tipApplication = tipApplication;
        this.dryTransmits = dryTransmits;
        this.centralPull = centralPull;
    }

    debugShowAt = 0;
    debugShowAt2 = 0;

    debugRenderLatentOutput(piRestart: ProgramInfo, depthRender: boolean = false) {
        const gl = GPURunner.gl!;
        let saveInputLatentCTex = this.workingLatentCTexture;
        let saveInputLatentMTex = this.workingLatentMTexture;
        let useOutputLatentCTex = this.outputLatentCTexture;
        let useOutputLatentMTex = this.outputLatentMTexture;
        let saveInputDepthTex = this.workingDepthTexture;
        let useOutputDepthTex = this.outputDepthTexture;
        if (!depthRender) {
            saveInputDepthTex = null;
            useOutputDepthTex = null;
        }
        if (depthRender === false) {
            gl.useProgram(TouchPageChange.latentToExternalProgram.program);
            this._initializeOutputProgram(TouchPageChange.latentToExternalProgram, null as any);
        } else {
            gl.useProgram(TouchPageChange.debugRenderDepth.program);
            this._initializeOutputProgram(TouchPageChange.debugRenderDepth, null as any);
        }
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        if (depthRender === false) {
            gl.activeTexture(gl.TEXTURE0 + TouchPageChange.workingLatentCTexNumVal);
            gl.bindTexture(gl.TEXTURE_2D, useOutputLatentCTex);
            gl.activeTexture(gl.TEXTURE0 + TouchPageChange.workingLatentMTexNumVal);
            gl.bindTexture(gl.TEXTURE_2D, useOutputLatentMTex);
        } else {
            gl.activeTexture(gl.TEXTURE0 + TouchPageChange.workingDepthTexNumVal);
            gl.bindTexture(gl.TEXTURE_2D, useOutputDepthTex);
        }
        gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);
        //gl.drawArrays(gl.TRIANGLES, 0, 24);
        this.crcDebug!.clearRect(this.debugShowAt, this.debugShowAt2, 50, 50);
        this.crcDebug!.drawImage(gl.canvas, this.debugShowAt, this.debugShowAt2);
        this.debugShowAt += 50;
        if (this.debugShowAt > 250) {
            this.debugShowAt = 0;
            this.debugShowAt2 += 50;
            if (this.debugShowAt2 > 250) {
                this.debugShowAt2 = 0;
            }

        }
        if (depthRender === false) {
            gl.activeTexture(gl.TEXTURE0 + TouchPageChange.workingLatentCTexNumVal);
            gl.bindTexture(gl.TEXTURE_2D, saveInputLatentCTex);
            gl.activeTexture(gl.TEXTURE0 + TouchPageChange.workingLatentMTexNumVal);
            gl.bindTexture(gl.TEXTURE_2D, saveInputLatentMTex);
        } else {
            gl.activeTexture(gl.TEXTURE0 + TouchPageChange.workingDepthTexNumVal);
            gl.bindTexture(gl.TEXTURE_2D, saveInputDepthTex);
        }
        gl.useProgram(piRestart.program);
    }
    debugMeasureDepthTexture(text: WebGLTexture, byte: boolean = false): [number, Uint8Array | Uint16Array] {
        const gl = GPURunner.gl!;
        let sampFb = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, sampFb);

        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, text, 0);
        let texDepth: Uint16Array | Uint8Array;
        if (byte) {
            texDepth = new Uint8Array(this.width * this.height);
            gl.readPixels(0, 0, this.width, this.height, gl.RED_INTEGER, gl.UNSIGNED_BYTE, texDepth);
        } else {
            texDepth = new Uint16Array(this.width * this.height);
            gl.readPixels(0, 0, this.width, this.height, gl.RED_INTEGER, gl.UNSIGNED_SHORT, texDepth);
        }
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.outputFramebuffer);
        let d = 0;
        (texDepth.values() as any).forEach((element: number) => { d+=element; });
        return [d, texDepth];
    }
    debugSpewArray(buffer: Uint8Array | Uint16Array, intPer: number, cellWidth: number, scaleDown: number, altWidth: number | undefined = undefined, altHeight: number | undefined = undefined) {

        let width = altWidth === undefined ? this.width : altWidth;
        let height = altHeight === undefined ? this.height : altHeight;

        console.log('values');
        for (let i = 0; i < height; i++) {
            let spew = '';
            if (i === 0) {
                spew = '      ';
                for (let j = 0; j < width; j++) {
                    let cellSpew = `${j<10?' ':''}${j}`;
                    spew += ' '.repeat(cellWidth-cellSpew.length) + cellSpew + ',';
                }
                console.log(spew);
            }
            spew = `${i<10?' ':''}${i} ${(height-1-i)<10?' ':''}${(height-1-i)} `;
            for (let j = 0; j < width; j++) {
                let idx = i*intPer*height + j*intPer;
                let d = buffer[idx];
                let cellSpew = '';
                for (let k = 0; k < intPer; k++) {
                    d = buffer[idx+k];
                    cellSpew += (Math.floor(d/scaleDown) + (d > 0 && d <= scaleDown ? 1 : 0)).toString();
                }
                if (cellWidth < cellSpew.length) {
                    spew += cellSpew + ',';
                } else {
                    spew += ' '.repeat(cellWidth-cellSpew.length) + cellSpew + ',';
                }
    
            }
            console.log(spew);

        }
    }

    debugSpewDepthTexture(text: WebGLTexture, byte: boolean = false) {
        let d = this.debugMeasureDepthTexture(text, byte);
        if (byte) {
            this.debugSpewArray(d[1] as Uint8Array, 1, 4, 1);
        } else {
            this.debugSpewArray(d[1] as Uint16Array, 1, 4, 1);
        }
    }
    debugSpewLatentTexture(text: WebGLTexture) {
        const gl = GPURunner.gl!;
        let sampFb = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, sampFb);

        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, text, 0);
        let texLat = new Uint16Array(this.width * this.height * 4);
        gl.readPixels(0, 0, this.width, this.height, gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, texLat);
        //this.debugSpewArray(texLat, 4, 4, 6554);
        this.debugSpewArray(texLat, 4, 20, 1);
    }

    workingFrameTextureNumbers = [TouchPageChange.workingLatentCTexNumVal, TouchPageChange.workingLatentMTexNumVal, TouchPageChange.workingDepthTexNumVal];


    bindWorkingFrame(frame: WebGLFramebuffer | null = null, latentCTex: WebGLTexture | null = null, latentMTex: WebGLTexture | null = null, depthTex: WebGLTexture | null = null) {
        let gl = GPURunner.gl!;
        this.workingFramebuffer = frame;
        this.workingLatentCTexture = latentCTex;
        this.workingLatentMTexture = latentMTex;
        this.workingDepthTexture = depthTex;

        if (frame === null) {
            //console.log('bindWorking null frame');
            // indicates the working frame is external
            if (depthTex !== null) {
                gl.activeTexture(gl.TEXTURE0 + this.workingFrameTextureNumbers[2]);
                gl.bindTexture(gl.TEXTURE_2D, depthTex);
            }
            if (latentMTex !== null) {
                gl.activeTexture(gl.TEXTURE0 + this.workingFrameTextureNumbers[1]);
                gl.bindTexture(gl.TEXTURE_2D, latentMTex);
            }
            if (latentCTex !== null) {
                gl.activeTexture(gl.TEXTURE0 + this.workingFrameTextureNumbers[0]);
                gl.bindTexture(gl.TEXTURE_2D, latentCTex);
            }

        } else {
            if (this.workingFramebuffer === TouchPageChange.touchBuffers!.working1Framebuffer) {
                this.workingDepthTexture = TouchPageChange.touchBuffers!.working1DepthTexture;
                this.workingLatentCTexture = TouchPageChange.touchBuffers!.working1LatentCTexture;
                this.workingLatentMTexture = TouchPageChange.touchBuffers!.working1LatentMTexture;
            } else if (this.workingFramebuffer === TouchPageChange.touchBuffers!.accFramebuffer) {
                this.workingDepthTexture = TouchPageChange.touchBuffers!.accDepthTexture;
                this.workingLatentCTexture = TouchPageChange.touchBuffers!.accLatentCTexture;
                this.workingLatentMTexture = TouchPageChange.touchBuffers!.accLatentMTexture;
            } else if (this.workingFramebuffer === TouchPageChange.touchBuffers!.working2Framebuffer) {
                this.workingDepthTexture = TouchPageChange.touchBuffers!.working2DepthTexture;
                this.workingLatentCTexture = TouchPageChange.touchBuffers!.working2LatentCTexture;
                this.workingLatentMTexture = TouchPageChange.touchBuffers!.working2LatentMTexture;
            } else if (this.workingFramebuffer === TouchPageChange.touchBuffers!.working3Framebuffer) {
                this.workingDepthTexture = TouchPageChange.touchBuffers!.working3DepthTexture;
                this.workingLatentCTexture = TouchPageChange.touchBuffers!.working3LatentCTexture;
                this.workingLatentMTexture = TouchPageChange.touchBuffers!.working3LatentMTexture;
            } else {
                //console.log('bindWorking unknown');
            }

            if (this.workingDepthTexture !== null) {
                gl.activeTexture(gl.TEXTURE0 + this.workingFrameTextureNumbers[2]);
                gl.bindTexture(gl.TEXTURE_2D, this.workingDepthTexture);
            }
            if (this.workingLatentMTexture !== null) {
                gl.activeTexture(gl.TEXTURE0 + this.workingFrameTextureNumbers[1]);
                gl.bindTexture(gl.TEXTURE_2D, this.workingLatentMTexture);
            }
            if (this.workingLatentCTexture !== null) {
                gl.activeTexture(gl.TEXTURE0 + this.workingFrameTextureNumbers[0]);
                gl.bindTexture(gl.TEXTURE_2D, this.workingLatentCTexture);
            }
        }
    }

    bindOutputFrame(frame: WebGLFramebuffer | null = null, latentCTex: WebGLTexture | null = null, latentMTex: WebGLTexture | null = null, depthTex: WebGLTexture | null = null) {
        let gl = GPURunner.gl!;
        if (frame === null) {
            gl.bindFramebuffer(gl.FRAMEBUFFER, null);
            this.outputLatentCTexture = null;
            this.outputLatentMTexture = null;
            this.outputDepthTexture = null;
            this.outputFramebuffer = null;
            return;
        }
        this.outputFramebuffer = frame;
        if (latentCTex === null) {
            if (this.outputFramebuffer === TouchPageChange.touchBuffers!.working1Framebuffer) {
                this.outputLatentCTexture = TouchPageChange.touchBuffers!.working1LatentCTexture;
            } else if (this.outputFramebuffer === TouchPageChange.touchBuffers!.accFramebuffer) {
                this.outputLatentCTexture = TouchPageChange.touchBuffers!.accLatentCTexture;
            } else if (this.outputFramebuffer === TouchPageChange.touchBuffers!.working2Framebuffer) {
                this.outputLatentCTexture = TouchPageChange.touchBuffers!.working2LatentCTexture;
            } else if (this.outputFramebuffer === TouchPageChange.touchBuffers!.working3Framebuffer) {
                this.outputLatentCTexture = TouchPageChange.touchBuffers!.working3LatentCTexture;
            } else {
                this.outputLatentCTexture = null;
            }
        } else {
            this.outputLatentCTexture = latentCTex;
        }
        if (latentMTex === null) {
            if (this.outputFramebuffer === TouchPageChange.touchBuffers!.working1Framebuffer) {
                this.outputLatentMTexture = TouchPageChange.touchBuffers!.working1LatentMTexture;
            } else if (this.outputFramebuffer === TouchPageChange.touchBuffers!.accFramebuffer) {
                this.outputLatentMTexture = TouchPageChange.touchBuffers!.accLatentMTexture;
            } else if (this.outputFramebuffer === TouchPageChange.touchBuffers!.working2Framebuffer) {
                this.outputLatentMTexture = TouchPageChange.touchBuffers!.working2LatentMTexture;
            } else if (this.outputFramebuffer === TouchPageChange.touchBuffers!.working3Framebuffer) {
                this.outputLatentMTexture = TouchPageChange.touchBuffers!.working3LatentMTexture;
            } else {
                this.outputLatentMTexture = null;
            }
        } else {
            this.outputLatentMTexture = latentMTex;
        }

        if (depthTex === null) {
            if (this.outputFramebuffer === TouchPageChange.touchBuffers!.working1Framebuffer) {
                this.outputDepthTexture = TouchPageChange.touchBuffers!.working1DepthTexture;
            } else if (this.outputFramebuffer === TouchPageChange.touchBuffers!.accFramebuffer) {
                this.outputDepthTexture = TouchPageChange.touchBuffers!.accDepthTexture;
            } else if (this.outputFramebuffer === TouchPageChange.touchBuffers!.working2Framebuffer) {
                this.outputDepthTexture = TouchPageChange.touchBuffers!.working2DepthTexture;
            } else if (this.outputFramebuffer === TouchPageChange.touchBuffers!.working3Framebuffer) {
                this.outputDepthTexture = TouchPageChange.touchBuffers!.working3DepthTexture;
            } else {
                this.outputDepthTexture = null;
            }
        } else {
            this.outputDepthTexture = depthTex;
        }

        gl.bindFramebuffer(gl.FRAMEBUFFER, this.outputFramebuffer);

    }

    bindExternalOutputFrame(fbTemp: WebGLFramebuffer, colorTex: WebGLTexture, depthTex: WebGLTexture | null) {
        let gl = GPURunner.gl!;
        gl.bindFramebuffer(gl.FRAMEBUFFER, fbTemp);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, colorTex, 0);
        if (depthTex !== null) {
            gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT1, gl.TEXTURE_2D, depthTex, 0);
            gl.drawBuffers([gl.COLOR_ATTACHMENT0, gl.COLOR_ATTACHMENT1]);
        } else {
            gl.drawBuffers([gl.COLOR_ATTACHMENT0]);
        }

        this.outputFramebuffer = fbTemp;
        this.outputLatentCTexture = colorTex;
        this.outputLatentMTexture = null;
        this.outputDepthTexture = depthTex;
    }

    // importExternalTextures(textColor: WebGLTexture, textDepth: WebGLTexture | null) {
    //     let gl = GPURunner.gl!;
    //     let pi = TouchPageChange.externalToLatentProgram;
    //     gl.useProgram(pi.program);
    //     this.initializeStartupProgram(pi);
    //     this.bindOutputFrame(TouchPageChange.touchBuffers!.importedFramebuffer, TouchPageChange.touchBuffers!.importedLatentCTexture, TouchPageChange.touchBuffers!.importedLatentMTexture, TouchPageChange.touchBuffers!.importedDepthTexture);

    //     // set up the source color texture
    //     gl.activeTexture(gl.TEXTURE0 + TouchPageChange.externalColorTexNumVal);
    //     gl.bindTexture(gl.TEXTURE_2D, textColor);
    //     if (textDepth !== null) {
    //         gl.activeTexture(gl.TEXTURE0 + TouchPageChange.externalDepthTexNumVal);
    //         gl.bindTexture(gl.TEXTURE_2D, textDepth);
    //     } else {
    //         gl.activeTexture(gl.TEXTURE0 + TouchPageChange.externalDepthTexNumVal);
    //         gl.bindTexture(gl.TEXTURE_2D, TouchPageChange.touchBuffers!.nullDepthTexture);
    //     }
    //     gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);

    // }

    stashOutput() {
        this.stashedLatentCTexture = this.outputLatentCTexture;
        this.stashedLatentMTexture = this.outputLatentMTexture;
        this.stashedDepthTexture = this.outputDepthTexture;
        this.stashedFramebuffer = this.outputFramebuffer;
    }

    bindStashAsWorking() {
        this.bindWorkingFrame(this.stashedFramebuffer, this.stashedLatentCTexture, this.stashedLatentMTexture, this.stashedDepthTexture);
        this.stashedFramebuffer = this.stashedLatentCTexture = this.stashedLatentMTexture = this.stashedDepthTexture = null;
    }

    preserveOutput() {
        if (this.preservedLatentCTexture !== null) {
            console.log('preserved output already set');
        }
        this.preservedLatentCTexture = this.outputLatentCTexture;
        this.preservedLatentMTexture = this.outputLatentMTexture;
        this.preservedDepthTexture = this.outputDepthTexture;
        this.preservedFramebuffer = this.outputFramebuffer;
    }

    bindPreservedAsWorking() {
        this.bindWorkingFrame(this.preservedFramebuffer, this.preservedLatentCTexture, this.preservedLatentMTexture, this.preservedDepthTexture);
        this.preservedFramebuffer = this.preservedLatentCTexture = this.preservedLatentMTexture = this.preservedDepthTexture = null;
    }

    directOutputTowardAccumulator() {
        let gl = GPURunner.gl!;
        let buffers = TouchPageChange.touchBuffers!;
        //console.log('bindOutputIntoAccumulator');

        // no accumulator because we write to the accumulator
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.accumDepthTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D, null);
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.accumLatentCTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D, null);
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.accumLatentMTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D, null);
        
        this.bindOutputFrame(buffers.accFramebuffer, buffers.accLatentCTexture, buffers.accLatentMTexture, buffers.accDepthTexture);
        this.accFramebuffer = null;
        this.accLatentCTexture = null;
        this.accLatentMTexture = null;
        this.accDepthTexture = null;
    }

    useTempAccumulator(frame: WebGLFramebuffer | null = null, latentCTex: WebGLTexture | null = null, latentMTex: WebGLTexture | null = null, depthTex: WebGLTexture | null = null) {
        let gl = GPURunner.gl!;

        this.accFramebuffer = frame;
        this.accLatentCTexture = latentCTex;
        this.accLatentMTexture = latentMTex;
        this.accDepthTexture = depthTex;

        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.accumDepthTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D, this.accDepthTexture);
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.accumLatentMTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D, this.accLatentMTexture);
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.accumLatentCTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D, this.accLatentCTexture);
    }
    useAccumulator() {
        let gl = GPURunner.gl!;
        let buffers = TouchPageChange.touchBuffers!;
        this.useTempAccumulator(buffers.accFramebuffer, buffers.accLatentCTexture, buffers.accLatentMTexture, buffers.accDepthTexture);
    }

    useAvailableOutputFrame() {
        let buffers = TouchPageChange.touchBuffers!;
        // use either frame1 or frame2 as the output.
        // this assumes that one of those is not the stash, working or accumulator
        if (buffers.working1LatentCTexture !== this.workingLatentCTexture && buffers.working1LatentCTexture !== this.accLatentCTexture && buffers.working1LatentCTexture !== this.stashedLatentCTexture && buffers.working1LatentCTexture !== this.preservedLatentCTexture) {
            this.bindOutputFrame(buffers.working1Framebuffer);
        } else if (buffers.working2LatentCTexture !== this.workingLatentCTexture && buffers.working2LatentCTexture !== this.accLatentCTexture && buffers.working2LatentCTexture !== this.stashedLatentCTexture && buffers.working2LatentCTexture !== this.preservedLatentCTexture) {
            this.bindOutputFrame(buffers.working2Framebuffer);
        } else if (buffers.working3LatentCTexture !== this.workingLatentCTexture && buffers.working3LatentCTexture !== this.accLatentCTexture && buffers.working3LatentCTexture !== this.stashedLatentCTexture && buffers.working3LatentCTexture !== this.preservedLatentCTexture) {
            this.bindOutputFrame(buffers.working3Framebuffer);
        } else {
            console.log('no available output frame');
        }
    }

    usePageReference(latentCTex: WebGLTexture | null = null, latentMTex: WebGLTexture | null = null, depthTex: WebGLTexture | null = null) {
        let gl = GPURunner.gl!;

        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.referenceDepthTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D, depthTex);
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.referenceLatentMTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D, latentMTex);
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.referenceLatentCTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D, latentCTex);
    }


    //static setviewportcount = 0;

    override setViewport(): void {
        // set the resolution
        let gl = GPURunner.gl!;
        let width = this.width;
        let height = this.height;
        if (gl.canvas.width !== width || gl.canvas.height !== height) {
            gl.canvas.width = width;
            gl.canvas.height = height;
            gl.viewport(0, 0, width, height);
            // TouchPageChange.setviewportcount ++;
            // if (TouchPageChange.setviewportcount % 10 === 0) {
            //     console.log('setviewportcount', TouchPageChange.setviewportcount);
            // }
        }
    }

    override preparePageForStartup(): void {
        GPURunner.initTouchWetPageChange(this.page, this);
        //console.log('preparePageForStartup');
        //this.debugSpewDepthTexture(this.pageWetDepthTexture!);

        //GPURunner.initTouchVisiblePageChange(this.page, this);

    }
    override applyStartupToPage(): void {
    }
    override preparePageForProcess(): void {
    }
    override applyProcessToPage(): void {
        GPURunner.applyTouchWetPageChange(this.page, this);
    }
    override preparePageForFinish(): void {
        GPURunner.initTouchVisiblePageChange(this.page, this);
    }
    override applyFinishToPage(): void {

        //GPURunner.applyTouchWetPageChange(this.page, this);
        GPURunner.applyTouchVisiblePageChange(this.page, this);

    }


    override selectStartupProgram(): TouchProgramInfo {
        let gl = GPURunner.gl!;
        let pi = TouchPageChange.externalToLatentProgram;

        gl.useProgram(pi.program);
        return pi;
    }

    override initializeStartupProgram(pi: TouchProgramInfo) {
        let buffers = TouchPageChange.touchBuffers!;
        this._initializeStartupProgram(pi, buffers);
    }

    _initializeSharedProgram(pi: TouchProgramInfo, buffers: TouchProgramBuffers): void {
        let gl = GPURunner.gl!;

        if (GPURunner.doInitializeProgram(pi, 'touch-shared')) {
            gl.uniform1i(pi.uniformLocations.kmLookupTexNum, TouchPageChange.kmLookupTexNumVal);
            gl.uniform1i(pi.uniformLocations.workingLatentCTexNum, TouchPageChange.workingLatentCTexNumVal);
            gl.uniform1i(pi.uniformLocations.workingLatentMTexNum, TouchPageChange.workingLatentMTexNumVal);
            gl.uniform1i(pi.uniformLocations.workingDepthTexNum, TouchPageChange.workingDepthTexNumVal);
            gl.uniform1i(pi.uniformLocations.accumLatentCTexNum, TouchPageChange.accumLatentCTexNumVal);
            gl.uniform1i(pi.uniformLocations.accumLatentMTexNum, TouchPageChange.accumLatentMTexNumVal);
            gl.uniform1i(pi.uniformLocations.accumDepthTexNum, TouchPageChange.accumDepthTexNumVal);
            gl.uniform1i(pi.uniformLocations.referenceLatentCTexNum, TouchPageChange.referenceLatentCTexNumVal);
            gl.uniform1i(pi.uniformLocations.referenceLatentMTexNum, TouchPageChange.referenceLatentMTexNumVal);
            gl.uniform1i(pi.uniformLocations.referenceDepthTexNum, TouchPageChange.referenceDepthTexNumVal);

        }
   
    }


    _initializeStartupProgram(pi: TouchProgramInfo, buffers: TouchProgramBuffers) {
        let gl = GPURunner.gl!;

        if (GPURunner.doInitializeProgram(pi, 'touch')) {
            gl.uniform1i(pi.uniformLocations.kmLookupTexNum, TouchPageChange.kmLookupTexNumVal);
            //gl.uniform1i(pi.uniformLocations.externalColorTexNum, TouchPageChange.externalColorTexNumVal);
            //gl.uniform1i(pi.uniformLocations.externalDepthTexNum, TouchPageChange.externalDepthTexNumVal);
        }

    }

    override specializeStartupRoutine(pi: TouchProgramInfo) {
        let buffers = TouchPageChange.touchBuffers!;
        this._specializeStartupRoutine(pi, buffers);
    }

    _specializeStartupRoutine(pi: TouchProgramInfo, buffers: TouchProgramBuffers) {
        let gl = GPURunner.gl!;

        // set the resolution
        gl.canvas.width = this.width;
        gl.canvas.height = this.height;

        // set up the working textures
        gl.bindTexture(gl.TEXTURE_2D, buffers.initialWetLatentCTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA16UI,
            this.width, this.height, 0,
            gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.initialWetLatentMTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA16UI,
            this.width, this.height, 0,
            gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.initialWetDepthTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.R16UI,
            this.width, this.height, 0,
            gl.RED_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.initialWetAgeTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.R8UI,
            this.width, this.height, 0,
            gl.RED_INTEGER, gl.UNSIGNED_BYTE, null);

   
        gl.bindTexture(gl.TEXTURE_2D, buffers.working1LatentCTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA16UI,
            this.width, this.height, 0,
            gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.working1LatentMTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA16UI,
            this.width, this.height, 0,
            gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.working1DepthTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.R16UI,
            this.width, this.height, 0,
            gl.RED_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.working2LatentCTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA16UI,
            this.width, this.height, 0,
            gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.working2LatentMTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA16UI,
            this.width, this.height, 0,
            gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.working2DepthTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.R16UI,
            this.width, this.height, 0,
            gl.RED_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.working3LatentCTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA16UI,
            this.width, this.height, 0,
            gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.working3LatentMTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA16UI,
            this.width, this.height, 0,
            gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.working3DepthTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.R16UI,
            this.width, this.height, 0,
            gl.RED_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.accLatentCTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA16UI,
            this.width, this.height, 0,
            gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.accLatentMTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA16UI,
            this.width, this.height, 0,
            gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.accDepthTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.R16UI,
            this.width, this.height, 0,
            gl.RED_INTEGER, gl.UNSIGNED_SHORT, null);
    
    
    }


    override runStartupRoutine(pi: TouchProgramInfo): void {
        let buffers = TouchPageChange.touchBuffers!;
        this._runStartupRoutine(pi, buffers);

    }
    _runStartupRoutine(pi: TouchProgramInfo, buffers: TouchProgramBuffers): void {
        let gl = GPURunner.gl!;
        // stash the starting wet paint
        const fbTemp1 = gl.createFramebuffer();
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp1);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.pageWetLatentCTexture, 0);
        const fbTemp2 = gl.createFramebuffer();
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, fbTemp2);
        gl.framebufferTexture2D(gl.DRAW_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, buffers.initialWetLatentCTexture, 0);
        gl.blitFramebuffer(0, 0, this.width, this.height, 0, 0, this.width, this.height, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp1);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.pageWetLatentMTexture, 0);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, fbTemp2);
        gl.framebufferTexture2D(gl.DRAW_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, buffers.initialWetLatentMTexture, 0);
        gl.blitFramebuffer(0, 0, this.width, this.height, 0, 0, this.width, this.height, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp1);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.pageWetDepthTexture, 0);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, fbTemp2);
        gl.framebufferTexture2D(gl.DRAW_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, buffers.initialWetDepthTexture, 0);
        gl.blitFramebuffer(0, 0, this.width, this.height, 0, 0, this.width, this.height, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp1);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.pageWetAgeTexture, 0);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, fbTemp2);
        gl.framebufferTexture2D(gl.DRAW_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, buffers.initialWetAgeTexture, 0);
        gl.blitFramebuffer(0, 0, this.width, this.height, 0, 0, this.width, this.height, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        this.outputDepthTexture = buffers.initialWetDepthTexture;
        this.outputLatentCTexture = buffers.initialWetLatentCTexture;
        this.outputLatentMTexture = buffers.initialWetLatentMTexture;
       
        gl.invalidateFramebuffer(gl.READ_FRAMEBUFFER, []);
        gl.deleteFramebuffer(fbTemp1);
    }

    override finalizeStartup(pi: TouchProgramInfo): void {
        let buffers = TouchPageChange.touchBuffers!;
        this._finalizeStartup(pi, buffers);

    }
    _finalizeStartup(pi: TouchProgramInfo, buffers: TouchProgramBuffers): void {

    }

    override selectProcessProgram(): TouchProgramInfo {
        let gl = GPURunner.gl!;
        let pi = TouchPageChange.processAccumulateTakesProgram;

        gl.useProgram(pi.program);
        return pi;
    }

    override initializeProcessProgram(pi: TouchProgramInfo): void {
        let buffers = TouchPageChange.touchBuffers!;
        this._initializeProcessProgram(pi, buffers);

    }

    _initializeProcessProgram(pi: TouchProgramInfo, buffers: TouchProgramBuffers): void {
        let gl = GPURunner.gl!;

        this._initializeSharedProgram(pi, buffers);
        if (GPURunner.doInitializeProgram(pi, 'touch')) {
            gl.uniform1i(pi.uniformLocations.actionFromTexNum, TouchPageChange.actionFromTexNumVal);
            gl.uniform1i(pi.uniformLocations.actionFracTexNum, TouchPageChange.actionFracTexNumVal);
            gl.uniform1i(pi.uniformLocations.actionCommitTexNum, TouchPageChange.actionCommitTexNumVal);
            gl.uniform1i(pi.uniformLocations.maxDepthTexNum, TouchPageChange.maxDepthTexNumVal);
        }
   
    }
    override specializeProcessRoutine(pi: TouchProgramInfo): void {
        let buffers = TouchPageChange.touchBuffers!;
        this._specializeProcessRoutine(pi, buffers);
    }

    _specializeProcessRoutine(pi: TouchProgramInfo, buffers: TouchProgramBuffers): void {
        let gl = GPURunner.gl!;

        gl.bindTexture(gl.TEXTURE_2D, buffers.finalWetAgeTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.R8UI,
            this.width, this.height, 0,
            gl.RED_INTEGER, gl.UNSIGNED_BYTE, null);
    
    }

    override runProcessRoutine(pi: TouchProgramInfo): void {
        let buffers = TouchPageChange.touchBuffers!;
        this._runProcessRoutine(pi, buffers);
    }
    _runProcessRoutine(pi: TouchProgramInfo, buffers: TouchProgramBuffers): void {
    }

    accumulateForEntries(perEntryDo: (tipLoc: PageChangeEntry)=>boolean): void {
        let gl = GPURunner.gl!;
        
        // start with accumulator set
        this.useAccumulator();
        for (const tipLoc of this.entries) {
            if (perEntryDo(tipLoc) === false) {
                break;
            }
        }
    }

    accumulateTextures(center: Point2D, textures: ChangeTextureIndexes, idxFirst:number, idxLast:number, getAccumulateProgram: (idx: number)=>TouchProgramInfo, getCommitProgram: (idx: number, stopEarly: boolean)=>TouchProgramInfo): void {
        let gl = GPURunner.gl!;
        
        let offsetX = center.x - (textures.width)/2;
        let offsetY = center.y - (textures.height)/2;

        for (let idx=idxFirst; idx < idxLast; idx++) {
            let stopNow = false;
            let pi = getAccumulateProgram(idx - idxFirst);
            if ((idx - idxFirst) === 0) {
                this.initializeProcessProgram(pi);
                gl.uniform2i(pi.uniformLocations.maxDepthTextureOffset, offsetX, offsetY);
                gl.uniform2i(pi.uniformLocations.actionTextureOffset, offsetX, offsetY);
                gl.uniform1i(pi.uniformLocations.maxDepthTextureIndex, textures.idxMax);
                gl.uniform1i(pi.uniformLocations.writeAccum, 1);
            }
            gl.uniform1i(pi.uniformLocations.actionTextureIndex, textures.idxsAction[idx]);

            this.stashOutput();
            this.directOutputTowardAccumulator();
            this.bindStashAsWorking();
            //console.log('depth into accumulate')   
            //this.debugSpewDepthTexture(this.workingDepthTexture!)


            gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);

            //console.log('accumulator')   
            //this.debugSpewDepthTexture(this.outputDepthTexture!)

            //this.debugSpewDepthTexture(this.outputDepthTexture!)
            //console.log('acum', this.debugMeasureDepthTexture(this.outputDepthTexture!));

            // peek ahead to see if we need to avoid writing to the accumulator and stop now
            stopNow = idx+1 < textures.idxsAction.length && textures.idxsAction[idx + 1] === -1;

            pi = getCommitProgram((idx - idxFirst), stopNow);
            if ((idx - idxFirst) === 0) {
                this.initializeProcessProgram(pi);
                gl.uniform2i(pi.uniformLocations.maxDepthTextureOffset, offsetX, offsetY);
                gl.uniform2i(pi.uniformLocations.actionTextureOffset, offsetX, offsetY);
                gl.uniform1i(pi.uniformLocations.maxDepthTextureIndex, textures.idxMax);
                gl.uniform1i(pi.uniformLocations.writeAccum, 1);
            }
            gl.uniform1i(pi.uniformLocations.actionTextureIndex, textures.idxsAction[idx]);

            // make the output the frame that isn't the accumulator or the input
            this.useAvailableOutputFrame();
            // use that accumulator
            this.useAccumulator();

            //gl.uniform1i(pi.uniformLocations.debug, 1);


            gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);
            //console.log('new depth')   
            //this.debugSpewDepthTexture(this.outputDepthTexture!)
            //console.log('new depth', this.debugMeasureDepthTexture(this.outputDepthTexture!)[0])

            if (stopNow) {
                break;
            }   
        }

    }

    override finalizeProcess(pi: TouchProgramInfo): void {
        let buffers = TouchPageChange.touchBuffers!;
        this._finalizeProcess(pi, buffers);
    }

    static anglePushes: Array<number>;
    _finalizeProcess(pi: TouchProgramInfo, buffers: TouchProgramBuffers): void {
        let gl = GPURunner.gl!;

        if (this.centralPull !== undefined) {
            // let buff0123 = new Uint16Array(this.width * this.height * 4);
            // let buff5678 = new Uint16Array(this.width * this.height * 4);
            // let buffSelf = new Uint16Array(this.width * this.height);

            // let ptCenter = new Point2D(this.page.cellsWidth/2, this.page.cellsHeight/2);
            // if (TouchPageChange.anglePushes === undefined) {

            //     const intersectsWith = (pt1p: Point2D, pt2p: Point2D, pt3p: Point2D, pt4p: Point2D, ptP: Point2D, dx: number, dy: number): number  => {
            //         // define a rectangle for the sides of the pixel in direction 0
            //         let pt1o = new Point2D(ptP.x - .5 + dx, ptP.y - .5 + dy);
            //         let pt2o = new Point2D(ptP.x - .5 + dx + 1, ptP.y - .5 + dy);
            //         let pt3o = new Point2D(ptP.x - .5 + dx + 1, ptP.y - .5 + dy + 1);
            //         let pt4o = new Point2D(ptP.x - .5 + dx, ptP.y - .5 + dy + 1);
            //         const rect1 = [pt1o, pt2o, pt3o, pt4o];
            //         const rect2 = [pt1p, pt2p, pt3p, pt4p];
            
            //         // calculate the area of the intersection of the two rectangles
            //         const intersectionPolygon = Point2D.sutherlandHodgman(rect1, rect2);
            //         return Point2D.polygonArea(intersectionPolygon);
            //     }

                
            //     let pushes = new Map<number, Array<number>>();
            //     let addPushCell = (pt: Point2D, dx: number, dy: number) => {
            //         let idx = Math.floor((pt.x + dx)) + Math.floor(pt.y + dy) * 1000;
            //         if (!pushes.has(idx)) {
            //             pushes.set(idx, new Array<number>(8));
            //         }
            //     }
            //     for (let ai = 0; ai < 512; ai++) {
            //         // get a point in a circle that has a radius of about 512
            //         let a = ai * Math.PI * 2 / 512;
            //         let d = 162;
            //         let ptCirc = ptCenter.newRelative(d, a);
            //         let ptPage = new Point2D(Math.round(ptCirc.x), Math.round(ptCirc.y));
            //         // all the neighbors
            //         addPushCell(ptPage, -1, -1);
            //         addPushCell(ptPage, 0, -1);
            //         addPushCell(ptPage, 1, -1);
            //         addPushCell(ptPage, -1, 0);
            //         addPushCell(ptPage, 0, 0);
            //         addPushCell(ptPage, 1, 0);
            //         addPushCell(ptPage, -1, 1);
            //         addPushCell(ptPage, 0, 1);
            //         addPushCell(ptPage, 1, 1);
            //     }

            //     for (const cell of pushes) {
            //         let ptPage = new Point2D(cell[0] % 1000 + .5, Math.floor(cell[0] / 1000) + .5);
            //         let d = ptCenter.distanceTo(ptPage);
            //         let a = ptCenter.angleToward(ptPage)!;
            //         // define a rectangle with sides parallel to line and perpendicular to line 
            //         // and centered on the point with width 1 and length = d
            //         // get the ends near the point with right angle to a
            //         let pt1p = new Point2D(ptPage.x + Math.cos(a + Math.PI/2) * .5, ptPage.y + Math.sin(a + Math.PI/2) * .5);
            //         let pt2p = new Point2D(ptCenter.x + Math.cos(a + Math.PI/2) * .5, ptCenter.y + Math.sin(a + Math.PI/2) * .5);
            //         let pt3p = new Point2D(ptCenter.x + Math.cos(a - Math.PI/2) * .5, ptCenter.y + Math.sin(a - Math.PI/2) * .5);
            //         let pt4p = new Point2D(ptPage.x + Math.cos(a - Math.PI/2) * .5, ptPage.y + Math.sin(a - Math.PI/2) * .5);
                    
            //         let area0 = intersectsWith(pt1p, pt2p, pt3p, pt4p, ptPage, -1, -1);
            //         let area1 = intersectsWith(pt1p, pt2p, pt3p, pt4p, ptPage, 0, -1);
            //         let area2 = intersectsWith(pt1p, pt2p, pt3p, pt4p, ptPage, 1, -1);
            //         let area3 = intersectsWith(pt1p, pt2p, pt3p, pt4p, ptPage, -1, 0);
            //         let area5 = intersectsWith(pt1p, pt2p, pt3p, pt4p, ptPage, 1, 0);
            //         let area6 = intersectsWith(pt1p, pt2p, pt3p, pt4p, ptPage, -1, 1);
            //         let area7 = intersectsWith(pt1p, pt2p, pt3p, pt4p, ptPage, 0, 1);
            //         let area8 = intersectsWith(pt1p, pt2p, pt3p, pt4p, ptPage, 1, 1);
            //         // normalize these to 0-1 and scale by the central pull
            //         let total = area0 + area1 + area2 + area3 + area5 + area6 + area7 + area8;
            //         cell[1][0] = area0 / total;
            //         cell[1][1] = area1 / total;
            //         cell[1][2] = area2 / total;
            //         cell[1][3] = area3 / total;
            //         cell[1][4] = area5 / total;
            //         cell[1][5] = area6 / total;
            //         cell[1][6] = area7 / total;
            //         cell[1][7] = area8 / total;
            //     }
            //     TouchPageChange.anglePushes = new Array<number>(512 * 8);
            //     for (let ai = 0; ai < 512; ai++) {
            //         // get a point in a circle that has a radius of about 512
            //         let a = ai * Math.PI * 2 / 512;
            //         let d = 162;
            //         let ptCirc = ptCenter.newRelative(d, a);
            //         let ptPage = new Point2D(Math.round(ptCirc.x), Math.round(ptCirc.y));

            //         let idx = (ptPage.x - 1) + (ptPage.y - 1) * 1000;
            //         let cellPushes = pushes.get(idx);
            //         if (cellPushes !== undefined) {
            //             TouchPageChange.anglePushes[ai * 8 + 0] = cellPushes[7];
            //         }
            //         idx = (ptPage.x) + (ptPage.y - 1) * 1000;
            //         cellPushes = pushes.get(idx);
            //         if (cellPushes !== undefined) {
            //             TouchPageChange.anglePushes[ai * 8 + 1] = cellPushes[6];
            //         }
            //         idx = (ptPage.x + 1) + (ptPage.y - 1) * 1000;
            //         cellPushes = pushes.get(idx);
            //         if (cellPushes !== undefined) {
            //             TouchPageChange.anglePushes[ai * 8 + 2] = cellPushes[5];
            //         }
            //         idx = (ptPage.x - 1) + (ptPage.y) * 1000;
            //         cellPushes = pushes.get(idx);
            //         if (cellPushes !== undefined) {
            //             TouchPageChange.anglePushes[ai * 8 + 3] = cellPushes[4];
            //         }
            //         idx = (ptPage.x + 1) + (ptPage.y) * 1000;
            //         cellPushes = pushes.get(idx);
            //         if (cellPushes !== undefined) {
            //             TouchPageChange.anglePushes[ai * 8 + 4] = cellPushes[3];
            //         }
            //         idx = (ptPage.x - 1) + (ptPage.y + 1) * 1000;
            //         cellPushes = pushes.get(idx);
            //         if (cellPushes !== undefined) {
            //             TouchPageChange.anglePushes[ai * 8 + 5] = cellPushes[2];
            //         }
            //         idx = (ptPage.x) + (ptPage.y + 1) * 1000;
            //         cellPushes = pushes.get(idx);
            //         if (cellPushes !== undefined) {
            //             TouchPageChange.anglePushes[ai * 8 + 6] = cellPushes[1];
            //         }
            //         idx = (ptPage.x + 1) + (ptPage.y + 1) * 1000;
            //         cellPushes = pushes.get(idx);
            //         if (cellPushes !== undefined) {
            //             TouchPageChange.anglePushes[ai * 8 + 7] = cellPushes[0];
            //         }
            //     }    
            // }

            // for (let y = 0; y < this.height; y++) {
            //     for (let x = 0; x < this.width; x++) {
            //         let offset = (y * this.width + x);
            //         let ptPage = new Point2D(this.pageX + x, this.pageY + y);
            //         let d = ptCenter.distanceTo(ptPage);
            //         let c = this.centralPull(d);
            //         buffSelf[offset] = c * 10000;

            //         let a = ptCenter.angleToward(ptPage)!;
            //         let ai = Math.floor(a * 512 / (Math.PI * 2));
            //         let pushes = TouchPageChange.anglePushes.slice(ai * 8, ai * 8 + 8);
                    
            //         if (x > 0 && y > 0) {
            //             offset = ((y-1) * this.width + (x-1));
            //             buff0123[offset*4 + 0] = pushes[0] * c * 10000;
            //         }
            //         if (y > 0) {
            //             offset = ((y-1) * this.width + (x));
            //             buff0123[offset*4 + 1] = pushes[1] * c * 10000;
            //         }
            //         if (x < this.width - 1 && y > 0) {
            //             offset = ((y-1) * this.width + (x+1));
            //             buff0123[offset*4 + 2] = pushes[offset*8 + 2] * c * 10000;
            //         }
            //         if (x > 0) {
            //             offset = ((y) * this.width + (x-1));
            //             buff0123[offset*4 + 3] = pushes[offset*8 + 3] * c * 10000;
            //         }
            //         if (x < this.width - 1) {
            //             offset = ((y) * this.width + (x+1));
            //             buff5678[offset*4 + 0] = pushes[offset*8 + 4] * c * 10000;
            //         }
            //         if (x > 0 && y < this.height - 1) {
            //             offset = ((y+1) * this.width + (x-1));
            //             buff5678[offset*4 + 1] = pushes[offset*8 + 5] * c * 10000;
            //         }   
            //         if (y < this.height - 1) {
            //             offset = ((y+1) * this.width + (x));
            //             buff5678[offset*4 + 2] = pushes[offset*8 + 6] * c * 10000;
            //         }
            //         if (x < this.width - 1 && y < this.height - 1) {
            //             offset = ((y+1) * this.width + (x+1));
            //             buff5678[offset*4 + 3] = pushes[offset*8 + 7] * c * 10000;
            //         }
                   
            //     }

            // }

            // //pi = TouchPageChange.centralPullWetProgram;
            // //gl.useProgram(pi.program);
            // //this.initializeFinishProgram(pi);

            // // upload those into the accumulator textures
            // gl.bindTexture(gl.TEXTURE_2D, buffers.accLatentCTexture);
            // gl.texSubImage2D(gl.TEXTURE_2D, 0, 0, 0, this.width, this.height, gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, buff0123);
            // gl.bindTexture(gl.TEXTURE_2D, buffers.accLatentMTexture);
            // gl.texSubImage2D(gl.TEXTURE_2D, 0, 0, 0, this.width, this.height, gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, buff5678);
            // gl.bindTexture(gl.TEXTURE_2D, buffers.accDepthTexture);
            // gl.texSubImage2D(gl.TEXTURE_2D, 0, 0, 0, this.width, this.height, gl.RED_INTEGER, gl.UNSIGNED_SHORT, buffSelf);
            // this.useAccumulator();

            // // last output is the working
            // this.stashOutput();
            // this.bindStashAsWorking();

            // // reference input is the wet paint
            // this.usePageReference(buffers.initialWetLatentCTexture, buffers.initialWetLatentMTexture, buffers.initialWetDepthTexture);

            // this.useAvailableOutputFrame();
            // gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);

        }

        //  pull out the final wet colors and depths into the page texture
        const fbTemp1 = gl.createFramebuffer();
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp1);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.outputLatentCTexture, 0);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, this.pageWetLatentCFramebufferW);
        gl.blitFramebuffer(0, 0, this.width, this.height, 0, 0, this.width, this.height, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp1);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.outputLatentMTexture, 0);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, this.pageWetLatentMFramebufferW);
        gl.blitFramebuffer(0, 0, this.width, this.height, 0, 0, this.width, this.height, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp1);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.outputDepthTexture, 0);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, this.pageWetDepthFramebufferW);
        gl.blitFramebuffer(0, 0, this.width, this.height, 0, 0, this.width, this.height, gl.COLOR_BUFFER_BIT, gl.NEAREST);

    }

    override selectFinishProgram(): TouchProgramInfo {
        let gl = GPURunner.gl!;
        let pi = TouchPageChange.wetChangesToVisibleChangesProgram;

        gl.useProgram(pi.program);
        return pi;
    
    }
    override initializeFinishProgram(pi: TouchProgramInfo): void {
        let buffers = TouchPageChange.touchBuffers!;
        this._initializeFinishProgram(pi, buffers);

    }
    _initializeFinishProgram(pi: TouchProgramInfo, buffers: TouchProgramBuffers): void {
        let gl = GPURunner.gl!;
        this._initializeSharedProgram(pi, buffers);
        if (GPURunner.doInitializeProgram(pi, 'touch')) {
            gl.uniform1i(pi.uniformLocations.workingWetAgeTexNum, TouchPageChange.workingWetAgeTexNumVal);
        }
    }

    override specializeFinishRoutine(pi: TouchProgramInfo): void {
        let buffers = TouchPageChange.touchBuffers!;
        this._specializeFinishRoutine(pi, buffers);
    }

    _specializeFinishRoutine(pi: TouchProgramInfo, buffers: TouchProgramBuffers): void {
    }

    _initializeOutputProgram(pi: TouchProgramInfo, buffers: TouchProgramBuffers) {
        let gl = GPURunner.gl!;
        if (GPURunner.doInitializeProgram(pi, 'touch')) {
            gl.uniform1i(pi.uniformLocations.kmLookupTexNum, TouchPageChange.kmLookupTexNumVal);
            gl.uniform1i(pi.uniformLocations.workingLatentCTexNum, TouchPageChange.workingLatentCTexNumVal);
            gl.uniform1i(pi.uniformLocations.workingLatentMTexNum, TouchPageChange.workingLatentMTexNumVal);
            gl.uniform1i(pi.uniformLocations.workingDepthTexNum, TouchPageChange.workingDepthTexNumVal);
        }

    }

    override runFinishRoutine(pi: TouchProgramInfo): void {
        let buffers = TouchPageChange.touchBuffers!;
        this._runFinishRoutine(pi, buffers);
    }

    _runFinishRoutine(pi: TouchProgramInfo, buffers: TouchProgramBuffers): void {
        let gl = GPURunner.gl!;

        // use the finish program to make a set of combinations and extractions
        // find a mask (5) of the wet paint that is different from the starting point in color or depth
        // the dry source colors are mixed (6) with the wet paint mask to make a new visible color mask
        // the old visible is compared with the new visible mask to find places that need antialiasing (7) and to produce
        // the final visible color canvas
    
        // move the last result to the wet paint texture
        //debugRepeatDraw();

        //this.debugRenderLatentOutput(pi);
        //this.debugRenderLatentOutput(pi, true);
        // make sure the last output frame is the source
        
        //console.log('fun finish outputDepthTexture');
        //this.debugSpewDepthTexture(this.outputDepthTexture!);

        this.stashOutput();
        this.bindStashAsWorking();

        //this.debugSpewDepthTexture(this.pageWetDepthTexture!);

        // make the new wet paint mask by looking for differences between the wet paint and the starting point
        // the new wet paint is already set as the source, so make the starting wet the accumulator
        // use that to make the new visible mask by blending the dry source with the wet paint mask
        // external source is the dry source
        // put dry source into the latent form

        pi = TouchPageChange.wetChangesToVisibleChangesProgram;
        gl.useProgram(pi.program);
        this.initializeFinishProgram(pi);
        // make a special output frame with the accumulator and the page age texture
        const fbWAge = GPURunner.initTextureFramebuffer(gl, 'fbWAge', buffers.accLatentCTexture!, buffers.accLatentMTexture!, buffers.accDepthTexture!, this.pageWetAgeTexture!);
        this.bindOutputFrame(fbWAge, buffers.accLatentCTexture, buffers.accLatentMTexture, buffers.accDepthTexture);
        // reference input is the wet paint
        this.usePageReference(this.pageDryLatentCTexture, this.pageDryLatentMTexture, null);
        // initial wet paint is the accumulator
        this.useTempAccumulator(null, buffers.initialWetLatentCTexture, buffers.initialWetLatentMTexture, buffers.initialWetDepthTexture);
        //this.debugSpewLatentTexture(buffers.initialWetLatentCTexture!);

        // special case for the age texture
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.workingWetAgeTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D, buffers.initialWetAgeTexture);
        gl.uniform1i(pi.uniformLocations.dryTransmits, this.dryTransmits === true ? 1 : 0);
        gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);
        //this.debugRenderLatentOutput(pi);

        // antialias and make final visible. source is the old visible canvas
        // 
        pi = TouchPageChange.visibleChangesToFinalProgram;
        gl.useProgram(pi.program);
        this.initializeFinishProgram(pi);

        this.stashOutput();
        this.useTempAccumulator(this.stashedFramebuffer, this.stashedLatentCTexture, this.stashedLatentMTexture, null);
        this.bindWorkingFrame(null, this.pageVisibleLatentCTexture, this.pageVisibleLatentMTexture, null);

        this.useAvailableOutputFrame();
        gl.drawBuffers([gl.COLOR_ATTACHMENT0, gl.COLOR_ATTACHMENT1]);
        gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);
        gl.drawBuffers([gl.COLOR_ATTACHMENT0, gl.COLOR_ATTACHMENT1, gl.COLOR_ATTACHMENT2]);

        //this.debugRenderLatentOutput(pi, true);


        //this.debugSpewTexture(this.outputLatentCTexture!, true);
        //this.debugRenderLatentOutput(pi);

        // put the new visible into the visible color texture
        const fbTemp1 = gl.createFramebuffer();
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp1);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.outputLatentCTexture, 0);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, this.pageVisibleLatentCFramebufferW);
        gl.blitFramebuffer(0, 0, this.width, this.height, 0, 0, this.width, this.height, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp1);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.outputLatentMTexture, 0);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, this.pageVisibleLatentMFramebufferW);
        gl.blitFramebuffer(0, 0, this.width, this.height, 0, 0, this.width, this.height, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.deleteFramebuffer(fbTemp1);
        gl.deleteFramebuffer(fbWAge);
        
    }
    override finalizeFinish(pi: TouchProgramInfo): void {
        let buffers = TouchPageChange.touchBuffers!;
        this._finalizeFinish(pi, buffers);

    }
    _finalizeFinish(pi: TouchProgramInfo, buffers: TouchProgramBuffers): void {
        let gl = GPURunner.gl!;
        //this.debugRepeatDraw();

        pi = TouchPageChange.latentToExternalProgram;
        gl.useProgram(pi.program);
        this._initializeOutputProgram(pi, buffers);
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        this.bindWorkingFrame(null, this.pageVisibleLatentCTexture, this.pageVisibleLatentMTexture, null);
        gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);
        //this.debugRenderLatentOutput(pi);
        this.page.crcVisible.drawImage(gl.canvas, this.pageX, this.pageY);
        gl.bindFramebuffer(gl.FRAMEBUFFER, buffers.accFramebuffer);
        gl.invalidateFramebuffer(gl.FRAMEBUFFER, []);
        gl.bindFramebuffer(gl.FRAMEBUFFER, buffers.working1Framebuffer);
        gl.invalidateFramebuffer(gl.FRAMEBUFFER, []);
        gl.bindFramebuffer(gl.FRAMEBUFFER, buffers.working2Framebuffer);
        gl.invalidateFramebuffer(gl.FRAMEBUFFER, []);
        gl.bindFramebuffer(gl.FRAMEBUFFER, buffers.working3Framebuffer);
        gl.invalidateFramebuffer(gl.FRAMEBUFFER, []);

    }

}


