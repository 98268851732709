import tinycolor from 'tinycolor2';
import {colorMixKM, getLookUpTable} from './kubelkaMonk';
import {Point2D, SeededRandom} from './utils';
import {ChangeTextureSet, FingerTip, FlowCell, FlowCellMove, TipAction, TipApplication, TipInstrument, TipMotion} from './fingertip';
import { CrcOff, Page } from './page';
import { initialize } from 'workbox-google-analytics';
import { GPURunner, ProgramInfo } from './GPURunner';
import { TouchPageChange, TouchProgramBuffers, TouchProgramInfo, TouchUniformLocations } from './TouchPageChange';
import { PageChange, PageChangeEntry } from './PageChange';


export interface BlowTouchUniformLocations extends TouchUniformLocations {
   
    dryAmount: WebGLUniformLocation,
    dryRadius: WebGLUniformLocation,
    dryPosition: WebGLUniformLocation,
};

export interface BlowTouchProgramInfo extends TouchProgramInfo {
    uniformLocations: BlowTouchUniformLocations
};

export interface BlowTouchProgramBuffers extends TouchProgramBuffers {

    workingDepthTexture: WebGLTexture | null,
    workingWetAgeTexture: WebGLTexture | null,
    workingFramebuffer: WebGLFramebuffer | null,
    resultDepthTexture: WebGLTexture | null,
    resultAgeTexture: WebGLTexture | null,
    resultFramebuffer: WebGLFramebuffer | null,
};


export class BlowPageChange extends TouchPageChange {

    static processProgram: BlowTouchProgramInfo = {tag:'BlowprocessProgram', uniformLocations:{}} as BlowTouchProgramInfo;
    static startupProgram: BlowTouchProgramInfo = {tag:'BlowstartupProgram', uniformLocations:{}} as BlowTouchProgramInfo;
    static finishProgram: BlowTouchProgramInfo = {tag:'BlowfinishProgram', uniformLocations:{}} as BlowTouchProgramInfo;
    static blowBuffers: BlowTouchProgramBuffers | undefined;

    static fsSourceStartup = `#version 300 es
    #line 3046
    uniform highp usampler2D u_workingDepthTexNum;
    uniform highp usampler2D u_workingAgeTexNum;
    uniform highp vec2 u_resolution;
    in highp vec2 v_texCoordRendered;
    in highp vec2 v_texCoordExternal;
    layout(location = 0) out highp uint outputDepth;
    layout(location = 1) out highp uint outputAge;
    
    void main() {
        outputDepth = texture(u_workingDepthTexNum, v_texCoordRendered).r;
        outputAge = texture(u_workingAgeTexNum, v_texCoordRendered).r;
    }
    `;
    
    static fsSourceProcess = `#version 300 es
    #line 3062
    uniform highp usampler2D u_workingDepthTexNum;
    uniform highp usampler2D u_workingAgeTexNum;
    uniform highp ivec2 u_dryPosition;
    uniform highp int u_dryRadius;
    uniform highp int u_dryAmount;

    in highp vec2 v_texCoordRendered;
    in highp vec2 v_texCoordExternal;
    layout(location = 0) out highp uint outputDepth;
    layout(location = 1) out highp uint outputAge;
    
    void main() {
    
        highp uvec2 workingSize = uvec2(textureSize(u_workingDepthTexNum, 0));
        highp ivec2 workingPos = ivec2(v_texCoordRendered * vec2(workingSize));

        outputAge = texture(u_workingDepthTexNum, v_texCoordRendered).r;
        outputDepth = texture(u_workingDepthTexNum, v_texCoordRendered).r;

        highp vec2 vectBetween = vec2(workingPos - u_dryPosition);
        if (length(vectBetween) <= float(u_dryRadius)) {
            if (uint(u_dryAmount) >= outputDepth + 1u) {
                outputDepth = 1u;
                outputAge = 1u;
            } else {
                outputDepth -= uint(u_dryAmount);
            }
        }
    }
    `;

    static fsSourceFinish = `#version 300 es
    #line 3095

    in highp vec2 v_texCoordRendered;
    in highp vec2 v_texCoordExternal;
    layout(location = 0) out highp vec4 outputColor;
    void main() {
    }
    `;

    static getPrograms(): Array<[string, ProgramInfo]> {
        return [[BlowPageChange.fsSourceStartup, BlowPageChange.startupProgram], [BlowPageChange.fsSourceProcess, BlowPageChange.processProgram], [BlowPageChange.fsSourceFinish, BlowPageChange.finishProgram]];
    }

    static initProgramLocations(gl: WebGL2RenderingContext) {
        BlowPageChange.startupProgram.uniformLocations = {} as BlowTouchUniformLocations;
        BlowPageChange.addProgramLocations(gl, BlowPageChange.startupProgram);
        BlowPageChange.processProgram.uniformLocations = {} as BlowTouchUniformLocations;
        BlowPageChange.addProgramLocations(gl, BlowPageChange.processProgram);
        BlowPageChange.finishProgram.uniformLocations = {} as BlowTouchUniformLocations;
        BlowPageChange.addProgramLocations(gl, BlowPageChange.finishProgram);

    }

    static addProgramLocations(gl: WebGL2RenderingContext, programInfo: BlowTouchProgramInfo) {
        programInfo.uniformLocations.resolution = gl.getUniformLocation(programInfo.program, 'u_resolution')!;
        programInfo.uniformLocations.workingDepthTexNum = gl.getUniformLocation(programInfo.program, 'u_workingDepthTexNum')!;
        programInfo.uniformLocations.workingWetAgeTexNum = gl.getUniformLocation(programInfo.program, 'u_workingWetAgeTexNum')!;
        programInfo.uniformLocations.dryAmount = gl.getUniformLocation(programInfo.program, 'u_dryAmount')!;
        programInfo.uniformLocations.dryPosition = gl.getUniformLocation(programInfo.program, 'u_dryPosition')!;
        programInfo.uniformLocations.dryRadius = gl.getUniformLocation(programInfo.program, 'u_dryRadius')!;
        PageChange.addProgramLocations(gl, programInfo);
    }

    static initPermanentStorage(gl: WebGL2RenderingContext, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {
        BlowPageChange.blowBuffers = {} as BlowTouchProgramBuffers;
        BlowPageChange.addPermanentStorage(gl, BlowPageChange.blowBuffers, textureCallback);
    }
    static addPermanentStorage(gl: WebGL2RenderingContext, buffers: BlowTouchProgramBuffers, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {
        PageChange.addPermanentStorage(gl, buffers, textureCallback);

        if (BlowPageChange.blowBuffers!.resultFramebuffer === undefined)
        {
            BlowPageChange.blowBuffers!.workingWetAgeTexture = GPURunner.initTexture(gl, 'workingWetAgeTexture');
            BlowPageChange.blowBuffers!.workingDepthTexture = GPURunner.initTexture(gl, 'workingDepthTexture');
            BlowPageChange.blowBuffers!.resultAgeTexture = GPURunner.initTexture(gl, 'resultAgeTexture');
            BlowPageChange.blowBuffers!.resultDepthTexture = GPURunner.initTexture(gl, 'resultDepthTexture');
            BlowPageChange.blowBuffers!.workingFramebuffer = GPURunner.initTextureFramebuffer(gl, 'workingFramebuffer', BlowPageChange.blowBuffers!.workingDepthTexture, BlowPageChange.blowBuffers!.workingWetAgeTexture);
            BlowPageChange.blowBuffers!.resultFramebuffer = GPURunner.initTextureFramebuffer(gl, 'resultFramebuffer', BlowPageChange.blowBuffers!.resultDepthTexture, BlowPageChange.blowBuffers!.resultAgeTexture);

        }
        buffers.workingWetAgeTexture = BlowPageChange.blowBuffers!.workingWetAgeTexture;
        buffers.workingDepthTexture = BlowPageChange.blowBuffers!.workingDepthTexture;
        buffers.resultAgeTexture = BlowPageChange.blowBuffers!.resultAgeTexture;
        buffers.resultDepthTexture = BlowPageChange.blowBuffers!.resultDepthTexture;
    }


    constructor(page: Page, pageX: number, pageY: number, width: number, height: number,
        tipsAndLocations: Array<PageChangeEntry>, tipApplication: TipApplication,
        dryTransmits: boolean, centralPull: ((d:number)=>number) | undefined) {
        super(page, pageX, pageY, width, height, tipsAndLocations, tipApplication, dryTransmits, centralPull);
    }

    override preparePageForStartup(): void {
        GPURunner.initTouchWetPageChange(this.page, this);
        GPURunner.initTouchVisiblePageChange(this.page, this);
    }
    override applyStartupToPage(): void {
    }
    override preparePageForProcess(): void {
    }
    override applyProcessToPage(): void {
    }
    override preparePageForFinish(): void {
    }
    override applyFinishToPage(): void {
        GPURunner.applyTouchWetPageChange(this.page, this);
        GPURunner.applyTouchVisiblePageChange(this.page, this);
    }

    override selectStartupProgram(): BlowTouchProgramInfo {
        let gl = GPURunner.gl!;
        let pi = BlowPageChange.startupProgram;

        gl.useProgram(pi.program);
        return pi;
    }

    override initializeStartupProgram(pi: BlowTouchProgramInfo): void {
        let gl = GPURunner.gl!;
        let buffers = BlowPageChange.blowBuffers!;

        if (GPURunner.doInitializeProgram(pi, 'blow')) {
            gl.uniform1i(pi.uniformLocations.workingWetAgeTexNum, TouchPageChange.workingWetAgeTexNumVal);
            gl.uniform1i(pi.uniformLocations.workingDepthTexNum, TouchPageChange.workingDepthTexNumVal);
        }
    }
    override specializeStartupRoutine(pi: BlowTouchProgramInfo): void {
        let gl = GPURunner.gl!;
        let buffers = BlowPageChange.blowBuffers!;

        gl.canvas.width = this.width;
        gl.canvas.height = this.height;

        // set up the working textures
        gl.bindTexture(gl.TEXTURE_2D, buffers.workingDepthTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.R16UI,
            this.width, this.height, 0,
            gl.RED_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.workingWetAgeTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.R8UI,
            this.width, this.height, 0,
            gl.RED_INTEGER, gl.UNSIGNED_BYTE, null);

        this.workingFrameTextureNumbers = [TouchPageChange.workingDepthTexNumVal, TouchPageChange.workingWetAgeTexNumVal];
    }

    override runStartupRoutine(pi: BlowTouchProgramInfo): void {
        let gl = GPURunner.gl!;
        let buffers = BlowPageChange.blowBuffers!;
        // set up the source color texture
        this.bindWorkingFrame(null, this.pageWetDepthTexture, this.pageWetAgeTexture);
        this.bindOutputFrame(buffers.workingFramebuffer, buffers.workingDepthTexture, buffers.workingWetAgeTexture);
        gl.drawBuffers([gl.COLOR_ATTACHMENT0, gl.COLOR_ATTACHMENT1]);
        gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);
        //this.debugRepeatDraw();

    }
    override finalizeStartup(pi: BlowTouchProgramInfo): void {

    }

    override selectProcessProgram(): BlowTouchProgramInfo {
        let gl = GPURunner.gl!;
        let pi = BlowPageChange.processProgram;

        gl.useProgram(pi.program);
        return pi;
    }

    

    override initializeProcessProgram(pi: BlowTouchProgramInfo): void {
        let buffers = BlowPageChange.blowBuffers!;
        let gl = GPURunner.gl!;

        if (GPURunner.doInitializeProgram(pi, 'blow')) {
            gl.uniform2f(pi.uniformLocations.resolution, this.width, this.height);

            gl.uniform1i(pi.uniformLocations.workingWetAgeTexNum, TouchPageChange.workingWetAgeTexNumVal);
            gl.uniform1i(pi.uniformLocations.workingDepthTexNum, TouchPageChange.workingDepthTexNumVal);
        }

    }
    override specializeProcessRoutine(pi: BlowTouchProgramInfo): void {
        let buffers = BlowPageChange.blowBuffers!;
        let gl = GPURunner.gl!;

        // set up the working textures
        gl.bindTexture(gl.TEXTURE_2D, buffers.resultDepthTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.R16UI,
            this.width, this.height, 0,
            gl.RED_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.resultAgeTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.R8UI,
            this.width, this.height, 0,
            gl.RED_INTEGER, gl.UNSIGNED_BYTE, null);

    }

    override runProcessRoutine(pi: BlowTouchProgramInfo): void {
        let buffers = BlowPageChange.blowBuffers!;
        let gl = GPURunner.gl!;

        this.bindOutputFrame(buffers.resultFramebuffer, buffers.resultDepthTexture, buffers.resultAgeTexture);
        this.bindWorkingFrame(buffers.workingFramebuffer, buffers.workingDepthTexture, buffers.workingWetAgeTexture);

        let count = this.entries.length;
        for (const tipLoc of this.entries) {
            let tip = tipLoc.shape as FingerTip
            let center = tipLoc.location;
           
            let rep = 0;

            gl.uniform2i(pi.uniformLocations.dryPosition, center.x, this.height - center.y + 1);
            gl.uniform1i(pi.uniformLocations.dryAmount, tipLoc.modifier);
            gl.uniform1i(pi.uniformLocations.dryRadius, tip.radius);

            gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);

            rep ++;

            if (rep < count || rep % 2 === 0) {
                if (this.workingFramebuffer === buffers.workingFramebuffer) {
                    this.bindOutputFrame(buffers.workingFramebuffer, buffers.workingDepthTexture, buffers.workingWetAgeTexture);
                    this.bindWorkingFrame(buffers.resultFramebuffer, buffers.resultDepthTexture, buffers.resultAgeTexture);
                } else {
                    this.bindOutputFrame(buffers.resultFramebuffer, buffers.resultDepthTexture, buffers.resultAgeTexture);
                    this.bindWorkingFrame(buffers.workingFramebuffer, buffers.workingDepthTexture, buffers.workingWetAgeTexture);
                }
            }
        }
    }


    override finalizeProcess(pi: BlowTouchProgramInfo): void {
        let buffers = BlowPageChange.blowBuffers!;
    }
    override selectFinishProgram(): BlowTouchProgramInfo {
        let gl = GPURunner.gl!;
        let pi = BlowPageChange.finishProgram;
        gl.useProgram(pi.program);
        return pi;
   
    }

    override initializeFinishProgram(pi: BlowTouchProgramInfo): void {
        let buffers = BlowPageChange.blowBuffers!;
        let gl = GPURunner.gl!;

        if (GPURunner.doInitializeProgram(pi, 'blow')) {

            gl.uniform1i(pi.uniformLocations.workingWetAgeTexNum, TouchPageChange.workingWetAgeTexNumVal);
            gl.uniform1i(pi.uniformLocations.workingDepthTexNum, TouchPageChange.workingDepthTexNumVal);
        }
    }

    override specializeFinishRoutine(pi: BlowTouchProgramInfo): void {

    }

    override runFinishRoutine(pi: BlowTouchProgramInfo): void {
        let buffers = BlowPageChange.blowBuffers!;
        let blitReadX0 = 0;
        let blitReadY0 = 0;
        let blitReadX1 = this.width;
        let blitReadY1 = this.height;
        let blitDrawX0 = 0;
        let blitDrawY0 = 0;
        let blitDrawX1 = this.width;
        let blitDrawY1 = this.height;
        let gl = GPURunner.gl!;

        const fbTemp1 = gl.createFramebuffer();
        const fbTemp2 = gl.createFramebuffer();

        // blit the final wet paint to the wet paint result texture
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, buffers.resultFramebuffer);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, this.pageWetDepthFramebufferW);
        gl.blitFramebuffer(blitReadX0, blitReadY0, blitReadX1, blitReadY1, blitDrawX0, blitDrawY0, blitDrawX1, blitDrawY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);
        // blit the final wet paint depths to the wet paint depth canvas
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp1);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, buffers.resultAgeTexture, 0);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, this.pageWetAgeFramebufferW);
        gl.blitFramebuffer(blitReadX0, blitReadY0, blitReadX1, blitReadY1, blitDrawX0, blitDrawY0, blitDrawX1, blitDrawY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);
    }
    override finalizeFinish(pi: BlowTouchProgramInfo): void {
    }

}

        