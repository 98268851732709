import tinycolor from 'tinycolor2';
import {colorMixKM, getLookUpTable} from './kubelkaMonk';
import {Point2D, SeededRandom} from './utils';
import {ChangeTextureSet, FingerTip, FlowCell, FlowCellMove, TipAction, TipApplication, TipInstrument, TipMotion} from './fingertip';
import { CrcOff, Page } from './page';
import { initialize } from 'workbox-google-analytics';
import { GPURunner, ProgramBuffers, ProgramInfo, UniformLocations } from './GPURunner';
import { PageChange, PageChangeEntry } from './PageChange';
import { TouchPageChange } from './TouchPageChange';

export interface RefreshUniformLocations extends UniformLocations {
    pageVisLatentCTexNum: WebGLUniformLocation,
    pageVisLatentMTexNum: WebGLUniformLocation,
    pageWetDepthTexNum: WebGLUniformLocation,
    pageWetAgeTexNum: WebGLUniformLocation,
    kmLookupTexNum: WebGLUniformLocation,
    showDry: WebGLUniformLocation,
};

export interface RefreshProgramInfo extends ProgramInfo {
    uniformLocations: RefreshUniformLocations
};

export interface RefreshProgramBuffers extends ProgramBuffers {

};

export class RefreshPageChange extends PageChange {
    static finishDryProgram: RefreshProgramInfo = {tag:'finishDryProgram', uniformLocations:{}} as RefreshProgramInfo;
    static refreshBuffers: RefreshProgramBuffers | undefined;
   
static fsSourceFinishDry = `#version 300 es
#line 4032
in highp vec2 v_texCoordRendered;
in highp vec2 v_texCoordExternal;
uniform highp usampler2D u_pageVisLatentCTexNum;
uniform highp usampler2D u_pageVisLatentMTexNum;
uniform highp usampler2D u_pageWetDepthTexNum;
uniform highp usampler2D u_pageWetAgeTexNum;
uniform highp usampler2D u_kmLookupTexNum;
uniform highp int u_showDry;
layout(location = 0) out highp vec4 outputColor;
` + TouchPageChange.fsColorMixLatentToRGBSource + `
#line 4042

void main() {
    uvec4 outputLatentC = texture(u_pageVisLatentCTexNum, v_texCoordRendered);
    uvec4 outputLatentM = texture(u_pageVisLatentMTexNum, v_texCoordRendered);
    highp uint finalDepth = texture(u_pageWetDepthTexNum, v_texCoordRendered).r;
    highp uint finalAge = texture(u_pageWetAgeTexNum, v_texCoordRendered).r;
    Latent outputLatent = latentFromLatentCM(outputLatentC, outputLatentM);
    outputColor = latentToRgba(outputLatent);

    if (u_showDry == 1 && (finalDepth == 0u || (finalDepth == 1u && finalAge == 1u))) {
        outputColor.r = outputColor.r + (1.0- outputColor.r) * 0.5;
        outputColor.g = outputColor.g + (1.0- outputColor.g) * 0.5;
        outputColor.b = outputColor.b + (1.0- outputColor.b) * 0.5;
        outputColor.a = 1.0;
    } else {
        outputColor.a = 1.0;
    }
}
`;


    static getPrograms(): Array<[string, ProgramInfo]> {
        return [[RefreshPageChange.fsSourceFinishDry, RefreshPageChange.finishDryProgram]];
    }

    static initProgramLocations(gl: WebGL2RenderingContext) {
        RefreshPageChange.finishDryProgram.uniformLocations = {} as RefreshUniformLocations;
        RefreshPageChange.addFinishProgramLocations(gl, RefreshPageChange.finishDryProgram);

    }

    static addFinishProgramLocations(gl: WebGL2RenderingContext, programInfo: RefreshProgramInfo) {
        programInfo.uniformLocations.pageVisLatentCTexNum = gl.getUniformLocation(programInfo.program, 'u_pageVisLatentCTexNum')!;
        programInfo.uniformLocations.pageVisLatentMTexNum = gl.getUniformLocation(programInfo.program, 'u_pageVisLatentMTexNum')!;
        programInfo.uniformLocations.pageWetDepthTexNum = gl.getUniformLocation(programInfo.program, 'u_pageWetDepthTexNum')!;
        programInfo.uniformLocations.pageWetAgeTexNum = gl.getUniformLocation(programInfo.program, 'u_pageWetAgeTexNum')!;
        programInfo.uniformLocations.kmLookupTexNum = gl.getUniformLocation(programInfo.program, 'u_kmLookupTexNum')!;
        programInfo.uniformLocations.showDry = gl.getUniformLocation(programInfo.program, 'u_showDry')!;
        PageChange.addProgramLocations(gl, programInfo);
    }

    static initPermanentStorage(gl: WebGL2RenderingContext, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {
        RefreshPageChange.refreshBuffers = {} as RefreshProgramBuffers;
        RefreshPageChange.addPermanentStorage(gl, RefreshPageChange.refreshBuffers, textureCallback);
    }
    static addPermanentStorage(gl: WebGL2RenderingContext, buffers: RefreshProgramBuffers, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {
        PageChange.addPermanentStorage(gl, buffers, textureCallback);
        if (RefreshPageChange.refreshBuffers! === undefined) {
        }
    }

    static visLatentCTexNum = 0;
    static visLatentMTexNum = 1;
    static depthTexNum = 2;
    static ageTexNum = 3;
    static kmLookupTexNum = TouchPageChange.kmLookupTexNumVal;

    previousBlows: Array<PageChangeEntry>;

    constructor(page: Page) {
        super(page, 0, 0, page.cellsWidth, page.cellsHeight);
        this.previousBlows = new Array<PageChangeEntry>();
    }

    override setViewport(): void {
        // set the resolution
        let gl = GPURunner.gl!;
        let width = this.page.cellsWidth;
        let height = this.page.cellsHeight;
        gl.canvas.width = width;
        gl.canvas.height = height;
        gl.viewport(0, 0, width, height);
    }
    override preparePageForStartup(): void {
        GPURunner.initRefreshPageChange(this.page, this);
    }
    override applyStartupToPage(): void {
    }
    override preparePageForProcess(): void {
    }
    override applyProcessToPage(): void {
    }
    override preparePageForFinish(): void {
    }
    override applyFinishToPage(): void {
    }

    override selectStartupProgram(): RefreshProgramInfo {
        return undefined as any as RefreshProgramInfo;
    }
    override initializeStartupProgram(pi: RefreshProgramInfo) {
    }
    override specializeStartupRoutine(pi: RefreshProgramInfo) {
    }
    override runStartupRoutine(pi: RefreshProgramInfo): void {
    }
    override finalizeStartup(pi: RefreshProgramInfo): void {
    }

    override selectProcessProgram(): RefreshProgramInfo {
        return undefined as any as RefreshProgramInfo;
    }
    override initializeProcessProgram(pi: RefreshProgramInfo): void {
    }
    override specializeProcessRoutine(pi: RefreshProgramInfo): void {
    }
    override runProcessRoutine(pi: RefreshProgramInfo): void {
    }
    override finalizeProcess(pi: RefreshProgramInfo): void {
    }

    override selectFinishProgram(): RefreshProgramInfo {
        let gl = GPURunner.gl!;
        let pi = RefreshPageChange.finishDryProgram;
        gl.useProgram(pi.program);

        return pi;
    }

    override initializeFinishProgram(pi: RefreshProgramInfo): void {

        let buffers = RefreshPageChange.refreshBuffers!;
        let gl = GPURunner.gl!;
        if (GPURunner.doInitializeProgram(pi, 'refresh')) {
            gl.uniform1i(pi.uniformLocations.pageVisLatentCTexNum, RefreshPageChange.visLatentCTexNum);
            gl.uniform1i(pi.uniformLocations.pageVisLatentMTexNum, RefreshPageChange.visLatentMTexNum);
            gl.uniform1i(pi.uniformLocations.pageWetDepthTexNum, RefreshPageChange.depthTexNum);
            gl.uniform1i(pi.uniformLocations.pageWetAgeTexNum, RefreshPageChange.ageTexNum);
            gl.uniform1i(pi.uniformLocations.kmLookupTexNum, RefreshPageChange.kmLookupTexNum);
        }
    }

    override specializeFinishRoutine(pi: RefreshProgramInfo): void {
    }

    override runFinishRoutine(pi: RefreshProgramInfo): void {
        let buffers = RefreshPageChange.refreshBuffers!;
        let gl = GPURunner.gl!;
        
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.activeTexture(gl.TEXTURE0 + RefreshPageChange.visLatentCTexNum);
        gl.bindTexture(gl.TEXTURE_2D, this.pageVisibleLatentCTexture);
        gl.activeTexture(gl.TEXTURE0 + RefreshPageChange.visLatentMTexNum);
        gl.bindTexture(gl.TEXTURE_2D, this.pageVisibleLatentMTexture);
        gl.activeTexture(gl.TEXTURE0 + RefreshPageChange.depthTexNum);
        gl.bindTexture(gl.TEXTURE_2D, this.pageWetDepthTexture);
        gl.activeTexture(gl.TEXTURE0 + RefreshPageChange.ageTexNum);
        gl.bindTexture(gl.TEXTURE_2D, this.pageWetAgeTexture);
        //gl.activeTexture(gl.TEXTURE0 + RefreshPageChange.kmLookupTexNum);
        //gl.bindTexture(gl.TEXTURE_2D, TouchPageChange.touchBuffers!.kmLookupTexture);

        gl.uniform1i(pi.uniformLocations.showDry, this.previousBlows.length > 0 ? 1 : 0);

        gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);
        this.page.crcVisible.drawImage(gl.canvas, 0,0);
    }

    override finalizeFinish(pi: RefreshProgramInfo): void {
    }

}

