import tinycolor from 'tinycolor2';
import {colorMixKM, getLookUpTable} from './kubelkaMonk';
import {Point2D, SeededRandom} from './utils';
import {ChangeTextureSet, FingerTip, FlowCell, FlowCellMove, TipAction, TipApplication, TipInstrument, TipMotion} from './fingertip';
import { CrcOff, Page } from './page';
import { initialize } from 'workbox-google-analytics';
import { GPURunner, ProgramInfo } from './GPURunner';
import { TouchPageChange, TouchProgramBuffers, TouchProgramInfo, TouchUniformLocations } from './TouchPageChange';
import { PageChange, PageChangeEntry } from './PageChange';


export interface KnifeTouchUniformLocations extends TouchUniformLocations {
    radius: WebGLUniformLocation,
    gapPattern: WebGLUniformLocation,
    orientCoef: WebGLUniformLocation,
    movePerpendicular: WebGLUniformLocation,
   
};

export interface KnifeTouchProgramInfo extends TouchProgramInfo {
    uniformLocations: KnifeTouchUniformLocations
};

export interface KnifeTouchProgramBuffers extends TouchProgramBuffers {
    maxDepthTexture: WebGLTexture | null,
    commitTexture: WebGLTexture | null,
    moveFromTexture: WebGLTexture | null,
    moveFracTexture: WebGLTexture | null,
};


export class KnifePageChange extends TouchPageChange {

    static processScrape: KnifeTouchProgramInfo = {tag: 'processScrape', uniformLocations:{}} as KnifeTouchProgramInfo;
    static processDryScrape: KnifeTouchProgramInfo = {tag: 'processDryScrape', uniformLocations:{}} as KnifeTouchProgramInfo;
    static knifeBuffers: KnifeTouchProgramBuffers | undefined;

    static fsSourceProcessScrape = 
    TouchPageChange.fsSourceProcessGlobals  
    + TouchPageChange.fsActionPositionsSource 
    + TouchPageChange.fsColorMixLerpLatentSource + 
`
#line 8044
uniform highp int u_radius;
uniform highp int[35] u_gapPattern;
uniform highp vec2 u_orientCoef;
uniform highp ivec2 u_movePerpendicular;

void main() {
    highp vec2 ptActionCenter = vec2(34.5, 34.5);
    // calculate the amount of paint to scrape off from a cell given the parameter provided. 
    highp uvec2 workingSize = uvec2(textureSize(u_workingLatentCTexNum, 0));
    highp ivec2 workingPos = ivec2(v_texCoordRendered * vec2(workingSize));
    highp uvec2 actionSize = uvec2(textureSize(u_maxDepthTexNum, 0));
    highp ivec3 actionPos = workingPositionToActionPosition(workingPos, workingSize, u_actionTextureOffset, actionSize, u_maxDepthTextureIndex);
   
    highp int dy = int(((float(actionPos.x) - ptActionCenter.x) * u_orientCoef.y + (float(actionPos.y) - ptActionCenter.y) * u_orientCoef.x));
    outputDepth = texture(u_workingDepthTexNum, v_texCoordRendered).r;
   
    if (outputDepth == 0u) {
        outputLatentC = emptyLatentPart;
        outputLatentM = smudgeLatentM;
    } else {
        outputLatentC = texture(u_workingLatentCTexNum, v_texCoordRendered);
        outputLatentM = texture(u_workingLatentMTexNum, v_texCoordRendered);
    }

    if (actionPos.x < 0) {
        return;
    }

    highp float d = distance(ptActionCenter, vec2(actionPos.x, actionPos.y));
    if (d > float(u_radius)) {
        return;
    }

    highp uint cellKind = texelFetch(u_maxDepthTexNum, actionPos, 0).r;
    highp int absDy = abs(dy);
    if ((cellKind == 1u || cellKind == 2u) && u_gapPattern[absDy] == 1) {
        outputLatentC = emptyLatentPart;
        if (outputDepth == 0u) {
            outputLatentM = dryScrapeLatentM;
        } else {
            if (outputLatentM != dryScrapeLatentM) {
                outputLatentM = wetScrapeLatentM;
            }
        }
        outputDepth = 1u;
    } else if (absDy > 0 && cellKind == 2u && outputDepth < 32u) {
        highp ivec2 takeFromIn = ivec2(0,0);
        highp ivec2 takeFromOut = ivec2(0,0);
        if (u_gapPattern[absDy-1] == 1) {
            takeFromIn = dy > 0 ? ivec2(-1,-1) : ivec2(1,1);
        }
        if (u_gapPattern[absDy+1] == 1) {
            takeFromOut = dy > 0 ? ivec2(1,1) : ivec2(-1,-1);
        }
        takeFromIn = takeFromIn * u_movePerpendicular;
        takeFromOut = takeFromOut * u_movePerpendicular;
        if (takeFromIn.x != 0 || takeFromIn.y != 0) {
            highp ivec2 takeFromPos = workingPos + takeFromIn;
            highp uint takeDepth = texelFetch(u_workingDepthTexNum, takeFromPos, 0).r;
            if (takeDepth > 1u) {
                takeDepth = max(1u, takeDepth / 3u);
                if (takeDepth > outputDepth) {
                    outputLatentC = texelFetch(u_workingLatentCTexNum, takeFromPos, 0);
                    outputLatentM = texelFetch(u_workingLatentMTexNum, takeFromPos, 0);
                }
                outputDepth += takeDepth;
            }
        }
        if (takeFromOut.x != 0 || takeFromOut.y != 0) {
            highp ivec2 takeFromPos = workingPos + takeFromOut;
            highp uint takeDepth = texelFetch(u_workingDepthTexNum, takeFromPos, 0).r;
            if (takeDepth > 1u) {
                takeDepth = max(1u, takeDepth / 3u);
                if (takeDepth > outputDepth) {
                    outputLatentC = texelFetch(u_workingLatentCTexNum, takeFromPos, 0);
                    outputLatentM = texelFetch(u_workingLatentMTexNum, takeFromPos, 0);
                }
                outputDepth += takeDepth;
            }
        }
        

    }
    return;
}`;
    

static fsSourceProcessDryScrape = 
TouchPageChange.fsSourceProcessGlobals  
+ TouchPageChange.fsColorMixLerpLatentSource + 
`
#line 8124
layout(location = 3) out highp uvec4 outputDryLatentC;
layout(location = 4) out highp uvec4 outputDryLatentM;

void main() {
    highp uvec2 workingSize = uvec2(textureSize(u_workingLatentCTexNum, 0));
    highp ivec2 workingPos = ivec2(v_texCoordRendered * vec2(workingSize));

    outputDepth = texture(u_workingDepthTexNum, v_texCoordRendered).r;
    outputLatentC = texture(u_workingLatentCTexNum, v_texCoordRendered);
    outputLatentM = texture(u_workingLatentMTexNum, v_texCoordRendered);

    outputDryLatentC = texture(u_referenceLatentCTexNum, v_texCoordRendered);
    outputDryLatentM = texture(u_referenceLatentMTexNum, v_texCoordRendered);

    if (outputDepth == 1u) {
        if (outputLatentM == wetScrapeLatentM) {
            outputLatentC = emptyLatentPart;
            outputLatentM = smudgeLatentM;
            outputDepth = 0u;
        } else if (outputLatentM == dryScrapeLatentM) {
            outputLatentC = emptyLatentPart;
            //outputLatentM = smudgeLatentM;
            outputDepth = 0u;

            highp float blendWeight =  5.0 / 100.0;
            Latent dryLatent = latentFromLatentCM(outputDryLatentC, outputDryLatentM);
            dryLatent = lerpLatent(dryLatent, latentFromLatentCM(whiteLatentC, neutralLatentM), blendWeight);

            outputDryLatentC = latentToLatentC(dryLatent);
            outputDryLatentM = latentToLatentM(dryLatent);
        }
    }

    return;
}`;

    static getPrograms(): Array<[string, ProgramInfo]> {
        return [[KnifePageChange.fsSourceProcessScrape, KnifePageChange.processScrape],[KnifePageChange.fsSourceProcessDryScrape, KnifePageChange.processDryScrape]];
    }

    static initProgramLocations(gl: WebGL2RenderingContext) {
        KnifePageChange.processScrape.uniformLocations = {
            radius: gl.getUniformLocation(KnifePageChange.processScrape.program, 'u_radius')!,
            gapPattern: gl.getUniformLocation(KnifePageChange.processScrape.program, 'u_gapPattern')!,
            orientCoef: gl.getUniformLocation(KnifePageChange.processScrape.program, 'u_orientCoef')!,
            movePerpendicular: gl.getUniformLocation(KnifePageChange.processScrape.program, 'u_movePerpendicular')!,
        } as KnifeTouchUniformLocations;
        TouchPageChange.addProcessProgramLocations(gl, KnifePageChange.processScrape);
        KnifePageChange.processDryScrape.uniformLocations = {
        } as KnifeTouchUniformLocations;
        TouchPageChange.addProcessProgramLocations(gl, KnifePageChange.processDryScrape);

    }

    static initPermanentStorage(gl: WebGL2RenderingContext, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {
        KnifePageChange.knifeBuffers = {} as KnifeTouchProgramBuffers;
        KnifePageChange.addPermanentStorage(gl, KnifePageChange.knifeBuffers, textureCallback);
    }
    static addPermanentStorage(gl: WebGL2RenderingContext, buffers: KnifeTouchProgramBuffers, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {
        //PageChange.addPermanentStorage(gl, buffers, textureCallback);

        if (KnifePageChange.knifeBuffers!.maxDepthTexture === undefined)
        {
            TouchPageChange.addPermanentStorage(gl, KnifePageChange.knifeBuffers!, textureCallback);
            // get the static change textures
            KnifePageChange.knifeBuffers!.maxDepthTexture = GPURunner.initTexture(gl, 'knifeActionTexture', true);
            KnifePageChange.knifeBuffers!.moveFromTexture = GPURunner.initTexture(gl, 'moveFromTexture', true);
            KnifePageChange.knifeBuffers!.moveFracTexture = GPURunner.initTexture(gl, 'moveFracTexture', true);
            KnifePageChange.knifeBuffers!.commitTexture = GPURunner.initTexture(gl, 'commitTexture', true);

            const knifeChangeTextureSet = textureCallback(0, {instrument: TipInstrument.knife, motion: TipMotion.press, action: TipAction.unknown, modifier: 0});

            gl.activeTexture(gl.TEXTURE0 + TouchPageChange.actionFromTexNumVal);
            gl.bindTexture(gl.TEXTURE_2D_ARRAY, KnifePageChange.knifeBuffers!.moveFromTexture);
            gl.texImage3D(gl.TEXTURE_2D_ARRAY, 0, gl.RGBA16UI, 
            knifeChangeTextureSet.width, knifeChangeTextureSet.height, knifeChangeTextureSet.moveEntries, 0, 
            gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, knifeChangeTextureSet.texFrom!);

            gl.activeTexture(gl.TEXTURE0 + TouchPageChange.actionFracTexNumVal);
            gl.bindTexture(gl.TEXTURE_2D_ARRAY, KnifePageChange.knifeBuffers!.moveFracTexture);
            gl.texImage3D(gl.TEXTURE_2D_ARRAY, 0, gl.RGBA32F, 
            knifeChangeTextureSet.width, knifeChangeTextureSet.height, knifeChangeTextureSet.moveEntries, 0, 
            gl.RGBA, gl.FLOAT, knifeChangeTextureSet.texFrac!);

            gl.activeTexture(gl.TEXTURE0 + TouchPageChange.actionCommitTexNumVal);
            gl.bindTexture(gl.TEXTURE_2D_ARRAY, KnifePageChange.knifeBuffers!.commitTexture);
            gl.texImage3D(gl.TEXTURE_2D_ARRAY, 0, gl.R32F, 
            knifeChangeTextureSet.width, knifeChangeTextureSet.height, knifeChangeTextureSet.moveEntries, 0, 
            gl.RED, gl.FLOAT, knifeChangeTextureSet.texCommit!);

            gl.activeTexture(gl.TEXTURE0 + TouchPageChange.maxDepthTexNumVal);
            gl.bindTexture(gl.TEXTURE_2D_ARRAY, KnifePageChange.knifeBuffers!.maxDepthTexture);
            gl.texImage3D(gl.TEXTURE_2D_ARRAY, 0, gl.R16UI, 
            knifeChangeTextureSet.width, knifeChangeTextureSet.height, knifeChangeTextureSet.maxEntries, 0, 
            gl.RED_INTEGER, gl.UNSIGNED_SHORT, knifeChangeTextureSet.texMax!);


        }
        buffers.maxDepthTexture = KnifePageChange.knifeBuffers!.maxDepthTexture;
        buffers.moveFromTexture = KnifePageChange.knifeBuffers!.moveFromTexture;
        buffers.moveFracTexture = KnifePageChange.knifeBuffers!.moveFracTexture;
        buffers.commitTexture = KnifePageChange.knifeBuffers!.commitTexture;

    }

    paintRadius: number;

    constructor(page: Page, pageX: number, pageY: number, width: number, height: number,
        tipsAndLocations: Array<PageChangeEntry>, tipApplication: TipApplication,
        dryTransmits: boolean, paintRadius: number, centralPull: ((d:number)=>number) | undefined) {
        super(page, pageX, pageY, width, height, tipsAndLocations, tipApplication, dryTransmits, centralPull);
        this.paintRadius = paintRadius;
    }

    override initializeStartupProgram(pi: KnifeTouchProgramInfo): void {
        let buffers = KnifePageChange.knifeBuffers!;
        super._initializeStartupProgram(pi, buffers);
    }
    override specializeStartupRoutine(pi: KnifeTouchProgramInfo): void {
        let buffers = KnifePageChange.knifeBuffers!;
        super._specializeStartupRoutine(pi, buffers);
    }

    override runStartupRoutine(pi: KnifeTouchProgramInfo): void {
        let buffers = KnifePageChange.knifeBuffers!;
        super._runStartupRoutine(pi, buffers);

    }
    override finalizeStartup(pi: KnifeTouchProgramInfo): void {
        let buffers = KnifePageChange.knifeBuffers!;
        super._finalizeStartup(pi, buffers);

    }
    override preparePageForProcess(): void {
        super.preparePageForProcess();
        GPURunner.initPageChangeDryLatentTextureFromPage(this.page, this);
    }
    override applyProcessToPage(): void {
        super.applyProcessToPage();
        GPURunner.applyPageChangeDryLatentTextureToPage(this.page, this);
    }

    override selectProcessProgram(): TouchProgramInfo {
        let gl = GPURunner.gl!;
        let pi = KnifePageChange.processScrape;

        gl.useProgram(pi.program);
        return pi;
    }

    override initializeProcessProgram(pi: KnifeTouchProgramInfo): void {
        let buffers = KnifePageChange.knifeBuffers!;
        let gl = GPURunner.gl!;
        super._initializeProcessProgram(pi, buffers);

        if (GPURunner.doInitializeProgram(pi, 'knife')) {
            gl.uniform1i(pi.uniformLocations.kmLookupTexNum, KnifePageChange.kmLookupTexNumVal);
        }

    }
    override specializeProcessRoutine(pi: KnifeTouchProgramInfo): void {
        let buffers = KnifePageChange.knifeBuffers!;
        let gl = GPURunner.gl!;
        super._specializeProcessRoutine(pi, buffers);
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.actionFromTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D_ARRAY, buffers!.moveFromTexture);
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.actionFracTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D_ARRAY, buffers!.moveFracTexture);
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.actionCommitTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D_ARRAY, buffers!.commitTexture);
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.maxDepthTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D_ARRAY, buffers!.maxDepthTexture);
    }
    

    override runProcessRoutine(pi: KnifeTouchProgramInfo): void {
        let buffers = KnifePageChange.knifeBuffers!;
        let gl = GPURunner.gl!;

        pi = KnifePageChange.processScrape;

        this.accumulateForEntries(
            (tipLoc: PageChangeEntry): boolean => {
                let tip = tipLoc.shape as FingerTip;
                let center = tipLoc.location;
                
                const specialApplication = {instrument: this.tipApplication.instrument, motion: this.tipApplication.motion, action: this.tipApplication.action, modifier: tipLoc.modifier};
                const textures = tip.getChangeTextureIndexes(specialApplication);

                let offsetX = center.x - (textures.width)/2;
                let offsetY = center.y - (textures.height)/2;

                let patternDo = tip.getKnifePattern(this.paintRadius);
                let orientCoef = Point2D.moveOctantPointDistanceCoeficients[tip.octMove];
                let ptMovePerp = Point2D.offsetsFromOctant(FingerTip.moveOctantRight[FingerTip.moveOctantRight[tip.octMove]]);
        

                gl.uniform2i(pi.uniformLocations.actionTextureOffset, offsetX, offsetY);
                gl.uniform1i(pi.uniformLocations.actionTextureIndex, textures.idxsAction[0]);
                gl.uniform1i(pi.uniformLocations.maxDepthTextureIndex, textures.idxMax);
                gl.uniform1i(pi.uniformLocations.radius, tip.radius);
                gl.uniform2f(pi.uniformLocations.orientCoef, orientCoef.dx, orientCoef.dy);
                gl.uniform2i(pi.uniformLocations.movePerpendicular, ptMovePerp.x, ptMovePerp.y);
                gl.uniform1iv(pi.uniformLocations.gapPattern, patternDo[0]);

                //this.debugSpewDepthTexture(this.outputDepthTexture!);

                this.stashOutput();
                this.bindStashAsWorking();
                this.useAvailableOutputFrame();
                gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);
                return true

            }
        );

        pi = KnifePageChange.processDryScrape;
        gl.useProgram(pi.program);
        this.initializeProcessProgram(pi);

        this.usePageReference(this.pageDryLatentCTexture!, this.pageDryLatentMTexture!);

        this.stashOutput();
        this.bindStashAsWorking();
        this.useAvailableOutputFrame();
        // actually, use a custom frame for dry scrape changes too but leave the output as just set for downstream processing
        let fbOut = GPURunner.initTextureFramebuffer(gl, 'dryScrapeFb', this.outputLatentCTexture!, this.outputLatentMTexture!, this.outputDepthTexture!, buffers!.accLatentCTexture!, buffers!.accLatentMTexture!);
        gl.bindFramebuffer(gl.FRAMEBUFFER, fbOut);

        gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);

        // copy the dry texture out of the accumulator
        const fbTemp1 = gl.createFramebuffer();
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp1);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, buffers!.accLatentCTexture!, 0);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, this.pageDryLatentCFramebufferW);
        gl.blitFramebuffer(0, 0, this.width, this.height, 0, 0, this.width, this.height, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp1);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, buffers!.accLatentMTexture!, 0);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, this.pageDryLatentMFramebufferW);
        gl.blitFramebuffer(0, 0, this.width, this.height, 0, 0, this.width, this.height, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.deleteFramebuffer(fbOut);

    }

    override finalizeProcess(pi: KnifeTouchProgramInfo): void {
        let buffers = KnifePageChange.knifeBuffers!;
        super._finalizeProcess(pi, buffers);

    }
    override initializeFinishProgram(pi: KnifeTouchProgramInfo): void {
        let buffers = KnifePageChange.knifeBuffers!;
        super._initializeFinishProgram(pi, buffers);

    }
    override specializeFinishRoutine(pi: KnifeTouchProgramInfo): void {
        let buffers = KnifePageChange.knifeBuffers!;
        super._specializeFinishRoutine(pi, buffers);
    }

    override runFinishRoutine(pi: KnifeTouchProgramInfo): void {
        let buffers = KnifePageChange.knifeBuffers!;
        super._runFinishRoutine(pi, buffers);

    }
    override finalizeFinish(pi: KnifeTouchProgramInfo): void {
        let buffers = KnifePageChange.knifeBuffers!;
        super._finalizeFinish(pi, buffers);

    }


}

        