import tinycolor from 'tinycolor2';
import {colorMixKM, getLookUpTable} from './kubelkaMonk';
import {Point2D, SeededRandom} from './utils';
import {ChangeTextureSet, FingerTip, FlowCell, FlowCellMove, TipAction, TipApplication, TipInstrument, TipMotion} from './fingertip';
import { CrcOff, Page } from './page';
import { initialize } from 'workbox-google-analytics';
import { GPURunner, ProgramInfo } from './GPURunner';
import { TouchPageChange, TouchProgramBuffers, TouchProgramInfo, TouchUniformLocations } from './TouchPageChange';
import { PageChange, PageChangeEntry } from './PageChange';

export interface SampleTouchUniformLocations extends TouchUniformLocations {
    center: WebGLUniformLocation,
    concentrateLine: WebGLUniformLocation,
};

export interface SampleTouchProgramInfo extends TouchProgramInfo {
    uniformLocations: SampleTouchUniformLocations
};

export interface SampleTouchProgramBuffers  extends TouchProgramBuffers {

};


export class SamplePageChange extends TouchPageChange {

    static processConcentrate: SampleTouchProgramInfo = {tag: 'processConcentrate', uniformLocations:{}} as SampleTouchProgramInfo;

    static sampleBuffers: SampleTouchProgramBuffers | undefined;

    static fsSourceProcessConcentrate = 
    TouchPageChange.fsSourceProcessGlobals  
    + TouchPageChange.fsColorMixLerpLatentSource 
    + 
    `
#line 6037
uniform highp ivec2 u_center;
uniform highp int u_concentrateLine;
void main() {
    highp uvec2 workingSize = uvec2(textureSize(u_workingDepthTexNum, 0));
    highp ivec2 workingPos = ivec2(v_texCoordRendered * vec2(workingSize));

    outputLatentC = emptyLatentPart;
    outputLatentM = smudgeLatentM;
    outputDepth = 0u;

    highp int take0 = 0;
    highp int take1 = 0;
    highp int take2 = 0;
    highp int take3 = 0;
    highp int take5 = 0;
    highp int take6 = 0;
    highp int take7 = 0;
    highp int take8 = 0;
    if (workingPos.x == 0 || workingPos.x == int(workingSize.x - 1u) || workingPos.y == 0 || workingPos.y == int(workingSize.y - 1u)) {
        return;
    }
    if (workingPos.x < u_center.x - u_concentrateLine || workingPos.x > u_center.x + u_concentrateLine || workingPos.y < u_center.y - u_concentrateLine || workingPos.y > u_center.y + u_concentrateLine) {
        return;
    }

    if (workingPos.y == u_center.y + u_concentrateLine) {
        take1 = 1;
    }
    if (workingPos.y == u_center.y - u_concentrateLine) {
        take7 = 1;
    }
    if (workingPos.x == u_center.x - u_concentrateLine) {
        take3 = 1;
    }
    if (workingPos.x == u_center.x + u_concentrateLine) {
        take5 = 1;
    }
    if (take1 == 1 && take3 == 1) {
        take0 = 1;
    }
    if (take1 == 1 && take5 == 1) {
        take2 = 1;
    }
    if (take7 == 1 && take3 == 1) {
        take6 = 1;
    }
    if (take7 == 1 && take5 == 1) {
        take8 = 1;
    }

    outputLatentC = texture(u_workingLatentCTexNum, v_texCoordRendered);
    outputLatentM = texture(u_workingLatentMTexNum, v_texCoordRendered);

    if (take0 + take1 + take2 + take3 + take5 + take6 + take7 + take8 > 0) {
        Latent outputLatent;
        outputDepth = texelFetch(u_workingDepthTexNum, workingPos, 0).r;

        if (outputLatentC == emptyLatentPart && outputLatentM == smudgeLatentM) {
            outputDepth = 0u;
        } else {
            if (outputDepth == 0u) {
                outputDepth = 1u;
            }
            outputLatent = latentFromLatentCM(outputLatentC, outputLatentM);
        }

        if (take0 == 1) {
            highp ivec2 posThere = ivec2(workingPos.x-1, workingPos.y+1);
            highp uint depthThere = texelFetch(u_workingDepthTexNum, posThere, 0).r;
            if (depthThere > 0u) {
                highp uvec4 latentCThere = texelFetch(u_workingLatentCTexNum, posThere, 0);
                highp uvec4 latentMThere = texelFetch(u_workingLatentMTexNum, posThere, 0);
                Latent latentThere = latentFromLatentCM(latentCThere, latentMThere);
                if (outputDepth == 0u) {
                    outputLatentC = latentCThere;
                    outputLatentM = latentMThere;
                    outputLatent = latentThere;
                    outputDepth = depthThere;
                } else {
                    outputDepth += depthThere;
                    highp float blendWeight = float(depthThere) / float(outputDepth);
                    outputLatent = lerpLatent(outputLatent, latentThere, blendWeight);
                    outputLatentC = latentToLatentC(outputLatent);
                    outputLatentM = latentToLatentM(outputLatent);
                }
            }
        }
        if (take1 == 1) {
            highp ivec2 posThere = ivec2(workingPos.x, workingPos.y+1);
            highp uint depthThere = texelFetch(u_workingDepthTexNum, posThere, 0).r;
            if (depthThere > 0u) {
                highp uvec4 latentCThere = texelFetch(u_workingLatentCTexNum, posThere, 0);
                highp uvec4 latentMThere = texelFetch(u_workingLatentMTexNum, posThere, 0);
                Latent latentThere = latentFromLatentCM(latentCThere, latentMThere);
                if (outputDepth == 0u) {
                    outputLatentC = latentCThere;
                    outputLatentM = latentMThere;
                    outputLatent = latentThere;
                    outputDepth = depthThere;
                } else {
                    outputDepth += depthThere;
                    highp float blendWeight = float(depthThere) / float(outputDepth);
                    outputLatent = lerpLatent(outputLatent, latentThere, blendWeight);
                    outputLatentC = latentToLatentC(outputLatent);
                    outputLatentM = latentToLatentM(outputLatent);
                }
            }
        }
        if (take2 == 1) {
            highp ivec2 posThere = ivec2(workingPos.x+1, workingPos.y+1);
            highp uint depthThere = texelFetch(u_workingDepthTexNum, posThere, 0).r;
            if (depthThere > 0u) {
                highp uvec4 latentCThere = texelFetch(u_workingLatentCTexNum, posThere, 0);
                highp uvec4 latentMThere = texelFetch(u_workingLatentMTexNum, posThere, 0);
                Latent latentThere = latentFromLatentCM(latentCThere, latentMThere);
                if (outputDepth == 0u) {
                    outputLatentC = latentCThere;
                    outputLatentM = latentMThere;
                    outputLatent = latentThere;
                    outputDepth = depthThere;
                } else {
                    outputDepth += depthThere;
                    highp float blendWeight = float(depthThere) / float(outputDepth);
                    outputLatent = lerpLatent(outputLatent, latentThere, blendWeight);
                    outputLatentC = latentToLatentC(outputLatent);
                    outputLatentM = latentToLatentM(outputLatent);
                }
            }
        }
        if (take3 == 1) {
            highp ivec2 posThere = ivec2(workingPos.x-1, workingPos.y);
            highp uint depthThere = texelFetch(u_workingDepthTexNum, posThere, 0).r;
            if (depthThere > 0u) {
                highp uvec4 latentCThere = texelFetch(u_workingLatentCTexNum, posThere, 0);
                highp uvec4 latentMThere = texelFetch(u_workingLatentMTexNum, posThere, 0);
                Latent latentThere = latentFromLatentCM(latentCThere, latentMThere);
                if (outputDepth == 0u) {
                    outputLatentC = latentCThere;
                    outputLatentM = latentMThere;
                    outputLatent = latentThere;
                    outputDepth = depthThere;
                } else {
                    outputDepth += depthThere;
                    highp float blendWeight = float(depthThere) / float(outputDepth);
                    outputLatent = lerpLatent(outputLatent, latentThere, blendWeight);
                    outputLatentC = latentToLatentC(outputLatent);
                    outputLatentM = latentToLatentM(outputLatent);
                }
            }
        }
        if (take5 == 1) {
            highp ivec2 posThere = ivec2(workingPos.x+1, workingPos.y);
            highp uint depthThere = texelFetch(u_workingDepthTexNum, posThere, 0).r;
            if (depthThere > 0u) {
                highp uvec4 latentCThere = texelFetch(u_workingLatentCTexNum, posThere, 0);
                highp uvec4 latentMThere = texelFetch(u_workingLatentMTexNum, posThere, 0);
                Latent latentThere = latentFromLatentCM(latentCThere, latentMThere);
                if (outputDepth == 0u) {
                    outputLatentC = latentCThere;
                    outputLatentM = latentMThere;
                    outputLatent = latentThere;
                    outputDepth = depthThere;
                } else {
                    outputDepth += depthThere;
                    highp float blendWeight = float(depthThere) / float(outputDepth);
                    outputLatent = lerpLatent(outputLatent, latentThere, blendWeight);
                    outputLatentC = latentToLatentC(outputLatent);
                    outputLatentM = latentToLatentM(outputLatent);
                }
            }
        }
        if (take6 == 1) {
            highp ivec2 posThere = ivec2(workingPos.x-1, workingPos.y-1);
            highp uint depthThere = texelFetch(u_workingDepthTexNum, posThere, 0).r;
            if (depthThere > 0u) {
                highp uvec4 latentCThere = texelFetch(u_workingLatentCTexNum, posThere, 0);
                highp uvec4 latentMThere = texelFetch(u_workingLatentMTexNum, posThere, 0);
                Latent latentThere = latentFromLatentCM(latentCThere, latentMThere);
                if (outputDepth == 0u) {
                    outputLatentC = latentCThere;
                    outputLatentM = latentMThere;
                    outputLatent = latentThere;
                    outputDepth = depthThere;
                } else {
                    outputDepth += depthThere;
                    highp float blendWeight = float(depthThere) / float(outputDepth);
                    outputLatent = lerpLatent(outputLatent, latentThere, blendWeight);
                    outputLatentC = latentToLatentC(outputLatent);
                    outputLatentM = latentToLatentM(outputLatent);
                }
            }
        }
        if (take7 == 1) {
            highp ivec2 posThere = ivec2(workingPos.x, workingPos.y-1);
            highp uint depthThere = texelFetch(u_workingDepthTexNum, posThere, 0).r;
            if (depthThere > 0u) {
                highp uvec4 latentCThere = texelFetch(u_workingLatentCTexNum, posThere, 0);
                highp uvec4 latentMThere = texelFetch(u_workingLatentMTexNum, posThere, 0);
                Latent latentThere = latentFromLatentCM(latentCThere, latentMThere);
                if (outputDepth == 0u) {
                    outputLatentC = latentCThere;
                    outputLatentM = latentMThere;
                    outputLatent = latentThere;
                    outputDepth = depthThere;
                } else {
                    outputDepth += depthThere;
                    highp float blendWeight = float(depthThere) / float(outputDepth);
                    outputLatent = lerpLatent(outputLatent, latentThere, blendWeight);
                    outputLatentC = latentToLatentC(outputLatent);
                    outputLatentM = latentToLatentM(outputLatent);
                }
            }
        }
        if (take8 == 1) {
            highp ivec2 posThere = ivec2(workingPos.x+1, workingPos.y-1);
            highp uint depthThere = texelFetch(u_workingDepthTexNum, posThere, 0).r;
            if (depthThere > 0u) {
                highp uvec4 latentCThere = texelFetch(u_workingLatentCTexNum, posThere, 0);
                highp uvec4 latentMThere = texelFetch(u_workingLatentMTexNum, posThere, 0);
                Latent latentThere = latentFromLatentCM(latentCThere, latentMThere);
                if (outputDepth == 0u) {
                    outputLatentC = latentCThere;
                    outputLatentM = latentMThere;
                    outputLatent = latentThere;
                    outputDepth = depthThere;
                } else {
                    outputDepth += depthThere;
                    highp float blendWeight = float(depthThere) / float(outputDepth);
                    outputLatent = lerpLatent(outputLatent, latentThere, blendWeight);
                    outputLatentC = latentToLatentC(outputLatent);
                    outputLatentM = latentToLatentM(outputLatent);
                }
            }
        }

    }
    return;
}
`;

    static getPrograms(): Array<[source: string, pi: ProgramInfo]> {
        return [[SamplePageChange.fsSourceProcessConcentrate, SamplePageChange.processConcentrate]
    ];
    }

    static initProgramLocations(gl: WebGL2RenderingContext) {
        SamplePageChange.processConcentrate.uniformLocations = {
            center: gl.getUniformLocation(SamplePageChange.processConcentrate.program, 'u_center')!,
            concentrateLine: gl.getUniformLocation(SamplePageChange.processConcentrate.program, 'u_concentrateLine')!
        } as SampleTouchUniformLocations;
        TouchPageChange.addProcessProgramLocations(gl, SamplePageChange.processConcentrate);
    }


    static initPermanentStorage(gl: WebGL2RenderingContext, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {
        SamplePageChange.sampleBuffers = {} as SampleTouchProgramBuffers;
        SamplePageChange.addPermanentStorage(gl, SamplePageChange.sampleBuffers, textureCallback);

        // get the static change textures
    }
    static addPermanentStorage(gl: WebGL2RenderingContext, buffers: SampleTouchProgramBuffers, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {

        TouchPageChange.addPermanentStorage(gl, SamplePageChange.sampleBuffers!, textureCallback);
    
    }

    color: tinycolor.ColorFormats.RGB;

    constructor(page: Page, pageX: number, pageY: number, width: number, height: number,
        tipsAndLocations: Array<PageChangeEntry>, tipApplication: TipApplication) {
        super(page, pageX, pageY, width, height, tipsAndLocations, tipApplication, false, undefined);
        this.color = {r: 0, g: 0, b: 0};
    }

    override preparePageForStartup(): void {
        GPURunner.initSamplePageChange(this.page, this);
    }
    override applyStartupToPage(): void {
    }
    override preparePageForProcess(): void {
    }
    override applyProcessToPage(): void {
    }
    override preparePageForFinish(): void {
    }
    override applyFinishToPage(): void {
    }


    override initializeStartupProgram(pi: SampleTouchProgramInfo): void {
        let buffers = SamplePageChange.sampleBuffers!;
        super._initializeStartupProgram(pi, buffers);

    }
    override specializeStartupRoutine(pi: SampleTouchProgramInfo): void {
        let buffers = SamplePageChange.sampleBuffers!;
        super._specializeStartupRoutine(pi, buffers);
    }

    override runStartupRoutine(pi: SampleTouchProgramInfo): void {
        let buffers = SamplePageChange.sampleBuffers!;
        let gl = GPURunner.gl!;
        // first input is the visible page colors
        const fbTemp1 = gl.createFramebuffer();
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp1);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.pageVisibleLatentCTexture, 0);
        const fbTemp2 = gl.createFramebuffer();
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, fbTemp2);
        gl.framebufferTexture2D(gl.DRAW_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, buffers.working1LatentCTexture, 0);
        gl.blitFramebuffer(0, 0, this.width, this.height, 0, 0, this.width, this.height, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp1);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.pageVisibleLatentMTexture, 0);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, fbTemp2);
        gl.framebufferTexture2D(gl.DRAW_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, buffers.working1LatentMTexture, 0);
        gl.blitFramebuffer(0, 0, this.width, this.height, 0, 0, this.width, this.height, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp1);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.pageWetDepthTexture, 0);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, fbTemp2);
        gl.framebufferTexture2D(gl.DRAW_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, buffers.working1DepthTexture, 0);
        gl.blitFramebuffer(0, 0, this.width, this.height, 0, 0, this.width, this.height, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        this.outputDepthTexture = buffers.working1DepthTexture;
        this.outputLatentCTexture = buffers.working1LatentCTexture;
        this.outputLatentMTexture = buffers.working1LatentMTexture;
        this.outputFramebuffer = buffers.working1Framebuffer;
       
        gl.invalidateFramebuffer(gl.READ_FRAMEBUFFER, []);
        gl.deleteFramebuffer(fbTemp1);


    }
    override finalizeStartup(pi: SampleTouchProgramInfo): void {
        let buffers = SamplePageChange.sampleBuffers!;
        super._finalizeStartup(pi, buffers);

    }
    override initializeProcessProgram(pi: SampleTouchProgramInfo): void {
        let buffers = SamplePageChange.sampleBuffers!;
        super._initializeProcessProgram(pi, buffers);
    }
    override specializeProcessRoutine(pi: SampleTouchProgramInfo): void {
        let buffers = SamplePageChange.sampleBuffers!;
        super._specializeProcessRoutine(pi, buffers);
    }


    override runProcessRoutine(pi: SampleTouchProgramInfo): void {
        let buffers = SamplePageChange.sampleBuffers!;
        let gl = GPURunner.gl!;
        let lastRadius = -1;

        this.accumulateForEntries(
            (tipLoc: PageChangeEntry): boolean => {
                let tip = tipLoc.shape as FingerTip;
                let center = tipLoc.location;
        
                let pi = SamplePageChange.processConcentrate;
                gl.useProgram(pi.program);
                this.initializeProcessProgram(pi);
                gl.uniform2i(pi.uniformLocations.center, center.x, center.y);
                for (let line = tip.radius; line >= 0; line--) {
                    gl.uniform1i(pi.uniformLocations.concentrateLine, line);

                    this.stashOutput();
                    this.bindStashAsWorking();
                    this.useAvailableOutputFrame();
                    gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);
                    //this.debugSpewDepthTexture(this.outputDepthTexture!);
                    //this.debugRenderLatentOutput(pi);
                }
                return true
            }
        );
    }
    override finalizeProcess(pi: SampleTouchProgramInfo): void {

    }
    override selectFinishProgram(): TouchProgramInfo {
        let gl = GPURunner.gl!;
        let pi = TouchPageChange.latentToExternalProgram;

        gl.useProgram(pi.program);
        return pi;
    
    }
    
    override initializeFinishProgram(pi: SampleTouchProgramInfo): void {
        let buffers = SamplePageChange.sampleBuffers!;
        this._initializeOutputProgram(pi, buffers);
    }
    override specializeFinishRoutine(pi: SampleTouchProgramInfo): void {
    }

    override runFinishRoutine(pi: SampleTouchProgramInfo): void {
        let buffers = SamplePageChange.sampleBuffers!;
        let gl = GPURunner.gl!;
        this.stashOutput();
        this.bindStashAsWorking();
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);
        let texColor = new Uint8Array(4);
        let ptCenter = this.entries[0].location;
        gl.readPixels(ptCenter.x, ptCenter.y, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, texColor);
        this.color = {r: texColor[0], g: texColor[1], b: texColor[2]};
    }
    override finalizeFinish(pi: SampleTouchProgramInfo): void {
        let buffers = SamplePageChange.sampleBuffers!;

    }

}

        