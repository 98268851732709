import { App } from "../../App";
import FingerTip, { TipApplication, ChangeTextureSet } from "./fingertip";
import { ProgramBuffers, ProgramInfo, GPURunner } from "./GPURunner";
import { Page } from "./page";
import { Point2D } from "./utils";

export interface PageChangeEntry {
    shape: FingerTip | {dx: number, dy: number};
    location: Point2D;
    modifier: number;
};

export abstract class PageChange {
    page: Page;
    crcDebug: CanvasRenderingContext2D | null = null;

    static vertexBuffers: ProgramBuffers | undefined;

    static vsSource = `#version 300 es
    in highp vec2 a_texCoord;
    in highp vec2 a_position;
    out highp vec2 v_texCoordRendered;
    out highp vec2 v_texCoordExternal;
    uniform highp vec2 u_resolution;
    
    void main() {
        gl_Position = vec4(a_position, 0, 1);
        v_texCoordExternal = vec2(a_texCoord.x, 1.0 - a_texCoord.y);
        v_texCoordRendered = a_texCoord;
    }
    `;

    static addProgramLocations(gl: WebGL2RenderingContext, programInfo: ProgramInfo) {
        programInfo.attribLocations = {
            vertexPosition: gl.getAttribLocation(programInfo.program, "a_position"),
            texCoordLocation: gl.getAttribLocation(programInfo.program, "a_texCoord"),
        };
        programInfo.uniformLocations.resolution = gl.getUniformLocation(programInfo.program, "u_resolution")!;
    }

    static initPermanentStorage(gl: WebGL2RenderingContext, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {
        PageChange.vertexBuffers = {} as ProgramBuffers;
        PageChange.addPermanentStorage(gl, PageChange.vertexBuffers!, textureCallback);
    }

    static verts = 6;
    //static verts = 24;
    static addPermanentStorage(gl: WebGL2RenderingContext, buffers: ProgramBuffers, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {
        if (PageChange.vertexBuffers!.vertexPositionBuffer === undefined) {
            PageChange.vertexBuffers = {
                vertexPositionBuffer: gl.createBuffer()!,
                texCoordBuffer: gl.createBuffer()!
            }
    
            //let positions = new Float32Array(12);
            let positions = new Float32Array(48);

            positions[0] = -1; positions[1] = 1;
            positions[2] = 1; positions[3] = 1;
            positions[4] = -1; positions[5] = -1;

            positions[6] = -1; positions[7] = -1;
            positions[8] = 1; positions[9] = 1;
            positions[10] = 1; positions[11] = -1;

            // positions[0] = -1; positions[1] = 1;
            // positions[2] = 0; positions[3] = 1;
            // positions[4] = -1; positions[5] = 0;

            // positions[6] = -1; positions[7] = 0;
            // positions[8] = 0; positions[9] = 1;
            // positions[10] = 0; positions[11] = 0;

            // positions[12] = 0; positions[13] = 1;
            // positions[14] = 1; positions[15] = 1;
            // positions[16] = 0; positions[17] = 0;

            // positions[18] = 0; positions[19] = 0;
            // positions[20] = 1; positions[21] = 1;
            // positions[22] = 1; positions[23] = 0;

            // positions[24] = -1; positions[25] = 0;
            // positions[26] = 0; positions[27] = 0;
            // positions[28] = -1; positions[29] = -1;

            // positions[30] = -1; positions[31] = -1;
            // positions[32] = 0; positions[33] = 0;
            // positions[34] = 0; positions[35] = -1;

            // positions[36] = 0; positions[37] = 0;
            // positions[38] = 1; positions[39] = 0;
            // positions[40] = 0; positions[41] = -1;

            // positions[42] = 0; positions[43] = -1;
            // positions[44] = 1; positions[45] = 0;
            // positions[46] = 1; positions[47] = -1;


            // Now pass the list of positions into WebGL to build the
            gl.bindBuffer(gl.ARRAY_BUFFER, PageChange.vertexBuffers.vertexPositionBuffer);
            gl.bufferData(gl.ARRAY_BUFFER, positions, gl.STATIC_DRAW);

            let coords = new Float32Array([
                0.0,  1.0,
                1.0,  1.0,
                0.0,  0.0,
                0.0,  0.0,
                1.0,  1.0,
                1.0,  0.0]);

            // let coords = new Float32Array([
            //     0.0,  1.0,
            //     0.5,  1.0,
            //     0.0,  0.5,

            //     0.0,  0.5,
            //     0.5,  1.0,
            //     0.5,  0.5,

            //     0.5,  1.0,
            //     1.0,  1.0,
            //     0.5,  0.5,

            //     0.5,  0.5,
            //     1.0,  1.0,
            //     1.0,  0.5,

            //     0.0,  0.5,
            //     0.5,  0.5,
            //     0.0,  0.0,

            //     0.0,  0.0,
            //     0.5,  0.5,
            //     0.5,  0.0,

            //     0.5,  0.5,
            //     1.0,  0.5,
            //     0.5,  0.0,

            //     0.5,  0.0,
            //     1.0,  0.5,
            //     1.0,  0.0

            //     ]);
    

            gl.bindBuffer(gl.ARRAY_BUFFER, PageChange.vertexBuffers.texCoordBuffer);
            gl.bufferData(gl.ARRAY_BUFFER, coords, gl.STATIC_DRAW);

        }
        buffers.vertexPositionBuffer = PageChange.vertexBuffers!.vertexPositionBuffer;
        buffers.texCoordBuffer = PageChange.vertexBuffers!.texCoordBuffer;
    }

    pageWetLatentCTexture: WebGLTexture | null = null;
    pageWetLatentMTexture: WebGLTexture | null = null;
    pageWetDepthTexture: WebGLTexture | null = null;
    pageWetAgeTexture: WebGLTexture | null = null;
    pageDryLatentCTexture: WebGLTexture | null = null;
    pageDryLatentMTexture: WebGLTexture | null = null;
    pageVisibleLatentCTexture: WebGLTexture | null = null;
    pageVisibleLatentMTexture: WebGLTexture | null = null;
    pageWetLatentCFramebufferW: WebGLFramebuffer | null = null;
    pageWetLatentMFramebufferW: WebGLFramebuffer | null = null;
    pageWetDepthFramebufferW: WebGLFramebuffer | null = null;
    pageWetAgeFramebufferW: WebGLFramebuffer | null = null;
    pageDryLatentCFramebufferW: WebGLFramebuffer | null = null;
    pageDryLatentMFramebufferW: WebGLFramebuffer | null = null;
    pageVisibleLatentCFramebufferW: WebGLFramebuffer | null = null;
    pageVisibleLatentMFramebufferW: WebGLFramebuffer | null = null;

    pageX: number;
    pageY: number;
    width: number;
    height: number;

    constructor(page: Page, pageX: number, pageY: number, width: number, height: number) {
        this.page = page;
        this.pageX = pageX;
        this.pageY = pageY;
        this.width = width;
        this.height = height;

    }


    static setTextureCoordBuffer(gl: WebGL2RenderingContext) {

    }
    

    public processChanges(crcDebug: CanvasRenderingContext2D) {
        if (!GPURunner.gl) {
            console.log('no working gl context, paint skipped');
            return;
        }
        this.crcDebug = crcDebug;

        this.setViewport();
        this.preparePageForStartup();
        let pi: ProgramInfo = this.selectStartupProgram();
        this.initializeStartupProgram(pi);
        this.specializeStartupRoutine(pi);
        this.runStartupRoutine(pi);
        this.finalizeStartup(pi);
        this.applyStartupToPage();

        this.preparePageForProcess();
        pi = this.selectProcessProgram();
        this.initializeProcessProgram(pi);
        this.specializeProcessRoutine(pi);
        this.runProcessRoutine(pi);
        this.finalizeProcess(pi);
        this.applyProcessToPage();

        this.preparePageForFinish();
        pi = this.selectFinishProgram();
        this.initializeFinishProgram(pi);
        this.specializeFinishRoutine(pi);
        this.runFinishRoutine(pi);
        this.finalizeFinish(pi);
        this.applyFinishToPage();
        GPURunner.gl!.flush();

        if (App.trustGPU === false) {
            if (GPURunner.gl.getError() === GPURunner.gl.NO_ERROR) {
                App.doTrustGPU();
            }
        }
    }
 
    abstract setViewport(): void;
    abstract preparePageForStartup(): void;
    abstract selectStartupProgram(): ProgramInfo;
    abstract initializeStartupProgram(pi: ProgramInfo): void;
    abstract specializeStartupRoutine(pi: ProgramInfo): void;
    abstract runStartupRoutine(pi: ProgramInfo): void;
    abstract finalizeStartup(pi: ProgramInfo): void;
    abstract applyStartupToPage(): void;
    abstract preparePageForProcess(): void;
    abstract selectProcessProgram(): ProgramInfo;
    abstract initializeProcessProgram(pi: ProgramInfo): void;
    abstract specializeProcessRoutine(pi: ProgramInfo): void;
    abstract runProcessRoutine(pi: ProgramInfo): void;
    abstract finalizeProcess(pi: ProgramInfo): void;
    abstract applyProcessToPage(): void;
    abstract preparePageForFinish(): void;
    abstract selectFinishProgram(): ProgramInfo;
    abstract initializeFinishProgram(pi: ProgramInfo): void;
    abstract specializeFinishRoutine(pi: ProgramInfo): void;
    abstract runFinishRoutine(pi: ProgramInfo): void;
    abstract finalizeFinish(pi: ProgramInfo): void;
    abstract applyFinishToPage(): void;
    
    finalize() {    
        //clean up resources
        if (this.pageWetDepthTexture) {
            GPURunner.gl!.deleteTexture(this.pageWetDepthTexture);
        }
        if (this.pageWetDepthFramebufferW) {
            GPURunner.gl!.deleteFramebuffer(this.pageWetDepthFramebufferW);
        }
        if (this.pageWetAgeTexture) {
            GPURunner.gl!.deleteTexture(this.pageWetAgeTexture);
        }
        if (this.pageWetAgeFramebufferW) {
            GPURunner.gl!.deleteFramebuffer(this.pageWetAgeFramebufferW);
        }
        if (this.pageWetLatentCTexture) {
            GPURunner.gl!.deleteTexture(this.pageWetLatentCTexture);
        }
        if (this.pageWetLatentMTexture) {
            GPURunner.gl!.deleteTexture(this.pageWetLatentMTexture);
        }
        if (this.pageWetLatentCFramebufferW) {
            GPURunner.gl!.deleteFramebuffer(this.pageWetLatentCFramebufferW);
        }
        if (this.pageWetLatentMFramebufferW) {
            GPURunner.gl!.deleteFramebuffer(this.pageWetLatentMFramebufferW);
        }
        if (this.pageDryLatentCTexture) {
            GPURunner.gl!.deleteTexture(this.pageDryLatentCTexture);
        }
        if (this.pageDryLatentMTexture) {
            GPURunner.gl!.deleteTexture(this.pageDryLatentMTexture);
        }
        if (this.pageDryLatentCFramebufferW) {
            GPURunner.gl!.deleteFramebuffer(this.pageDryLatentCFramebufferW);
        }
        if (this.pageDryLatentMFramebufferW) {
            GPURunner.gl!.deleteFramebuffer(this.pageDryLatentMFramebufferW);
        }
        if (this.pageVisibleLatentCTexture) {
            GPURunner.gl!.deleteTexture(this.pageVisibleLatentCTexture);
        }
        if (this.pageVisibleLatentMTexture) {
            GPURunner.gl!.deleteTexture(this.pageVisibleLatentMTexture);
        }
        if (this.pageVisibleLatentCFramebufferW) {
            GPURunner.gl!.deleteFramebuffer(this.pageVisibleLatentCFramebufferW);
        }
        if (this.pageVisibleLatentMFramebufferW) {
            GPURunner.gl!.deleteFramebuffer(this.pageVisibleLatentMFramebufferW);
        }
       

    }
}

