export class Point2D {
    x: number;
    y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    public newRelative(distance: number, angle: number | undefined): Point2D {
        if (angle === undefined) {
            return new Point2D(this.x, this.y);
        }
        return new Point2D(this.x + distance * Math.cos(angle), this.y + distance * Math.sin(angle));
    }

    public newRotated(ptCenter: Point2D, sinA: number, cosA: number) : Point2D {
        let x1 = this.x - ptCenter.x;  
        let y1 = this.y - ptCenter.y;
        let x2 = x1 * cosA - y1 * sinA;
        let y2 = x1 * sinA + y1 * cosA;
        return new Point2D(x2 + ptCenter.x, y2 + ptCenter.y);
    }
    
    public angleToward(destination: Point2D): number | undefined {
        if (this.x === destination.x && this.y === destination.y) {
            return undefined;
        }
        return (Math.atan2(destination.y - this.y, destination.x - this.x));
    }

    public taxiDistance(from: Point2D): number {
        return Math.max(Math.abs(from.x - this.x), Math.abs(from.y - this.y));
    }

    public midTo(to: Point2D) : Point2D {
        return new Point2D(this.x + (to.x - this.x) / 2, this.y + (to.y - this.y) / 2);
    }

    static stepLineLow(pt0: Point2D, pt1: Point2D, into: Array<Point2D>) {
        let dx = pt1.x - pt0.x;
        let dy = pt1.y - pt0.y;
        let yi = 1;
        if (dy < 0) {
            yi = -1;
            dy = -dy;
        }
        let D = (2 * dy) - dx;
        let y = pt0.y;

        for(let x = pt0.x; x <= pt1.x; x++) {
            into.push(new Point2D(x, y));
            if (D > 0) {
                y = y + yi;
                D = D + (2 * (dy - dx));
            } else {
                D = D + 2*dy;
            }
        }
    }
    static stepLineHigh(pt0: Point2D, pt1: Point2D, into: Array<Point2D>) {
        let dx = pt1.x - pt0.x;
        let dy = pt1.y - pt0.y;
        let xi = 1;
        if (dx < 0) {
            xi = -1;
            dx = -dx;
        }
        let D = (2 * dx) - dy;
        let x = pt0.x;

        for(let y = pt0.y; y <= pt1.y; y++) {
            into.push(new Point2D(x, y));
            if (D > 0) {
                x = x + xi;
                D = D + (2 * (dx - dy));
            } else {
                D = D + 2*dx;
            }
        }
    }

    public lineTo(to: Point2D): Array<Point2D> {
        let result = new Array<Point2D>();
        if (Math.abs(to.y - this.y) < Math.abs(to.x - this.x)) {
            if (this.x > to.x) {
                Point2D.stepLineLow(to, this, result);
                result.reverse();
            } else {
                Point2D.stepLineLow(this, to, result);
            }
        } else {
            if (this.y > to.y) {
                Point2D.stepLineHigh(to, this, result);
                result.reverse();
            } else {
                Point2D.stepLineHigh(this, to, result);
            }
        }
        return result;
    }

    pushTL(using: Point2D): void {
        if (using.x < this.x) {
            this.x = using.x;
        }
        if (using.y < this.y) {
            this.y = using.y;
        }
    }
    pushTR(using: Point2D): void {
        if (using.x > this.x) {
            this.x = using.x;
        }
        if (using.y < this.y) {
            this.y = using.y;
        }
    }
    pushBL(using: Point2D): void {
        if (using.x < this.x) {
            this.x = using.x;
        }
        if (using.y > this.y) {
            this.y = using.y;
        }
    }
    pushBR(using: Point2D): void {
        if (using.x > this.x) {
            this.x = using.x;
        }
        if (using.y > this.y) {
            this.y = using.y;
        }
    }
    
    distanceTo(to: Point2D): number {
        return Math.sqrt((this.x - to.x) * (this.x - to.x) + (this.y - to.y) * (this.y - to.y));
    }

    inRect(rect:DOMRect): boolean {
        return (this.x >= rect.left && this.x <= rect.right && this.y >= rect.top && this.y <= rect.bottom);
    }

    static normalizeAngle(angle: number) {
        // assumes we didn't add more than pi
        if (angle > Math.PI) {
            angle = -Math.PI + (angle - Math.PI);
        }
        if (angle < -Math.PI) {
            angle = Math.PI + (angle - -Math.PI);

        }
        return angle;
    };
    static angleDiff(angleA: number, angleB: number) : number {
        let d = Math.min(Math.abs(angleA - angleB), Math.PI * 2 - Math.abs(angleA - angleB));
        return d;
    };

    static windOrder(pts: Array<Point2D>): number {
        let areaTotal = 0;
        for (let i = 0; i < pts.length; i++) {
            const ptNext = pts[i === pts.length - 1 ? 0 : i+1];
            const pt = pts[i];
            areaTotal += ((pt.x * ptNext.y) - (ptNext.x * pt.y));
        }
        if (areaTotal > 0) {
            return 1;
        }
        return -1;
    }
    // normal octants are
    public octantFrom = (ptFrom: Point2D): number=> {
        let sx = Math.abs(this.x - ptFrom.x);
        let sy = Math.abs(this.y - ptFrom.y);
        if (sx === 0 && sy===0) {
            return 4;
        }
        if (this.y < ptFrom.y && this.x <= ptFrom.x) {
            if (sx < sy) {
                return 1;
            } else {
                return 0;
            }
        } else if (this.y <= ptFrom.y && this.x > ptFrom.x) {
            if (sx <= sy) {
                return 2;
            } else {
                return 5;
            }
        } else if (this.y > ptFrom.y && this.x >= ptFrom.x) {
            if (sx < sy) {
                return 7;
            } else {
                return 8;
            }
        } else {
            if (sx <= sy) {
                return 6;
            } else {
                return 3;
            }
        }
    }
    public moveOctantFrom = (ptFrom: Point2D): number=> {
        let dx = this.x - ptFrom.x;
        let dy = this.y - ptFrom.y;
        let sx = Math.abs(dx);
        let sy = Math.abs(dy);
        if (sx === 0 && sy===0) {
            return 4;
        }
        if (sy === sx) {
            if (dx < 0 && dy < 0) {
                return 0;
            }
            if (dx > 0 && dy < 0) {
                return 2;
            }
            if (dx < 0 && dy > 0) {
                return 6;
            }
            return 8;
        } else if (sx === 0) {
            return dy < 0 ? 1 : 7;
        } else if (sy === 0) {
            return dx < 0 ? 3 : 5;
        } else if (sy > sx) {
            if (dy < 0) {
                if (sy >= sx*2) {
                    return 1;
                }
                return dx < 0 ? 0 : 2;
            } else { // dy > 0
                if (sy > sx*2) {
                    return 7;
                }
                return dx < 0 ? 6 : 8;
            }

        } else { // sx > sy
            if (dx < 0) {
                if (sx > sy*2) {
                    return 3;
                }
                return dy < 0 ? 0 : 6;
            } else { // dx > 0
                if (sx > sy*2) {
                    return 5;
                }
                return dy < 0 ? 2 : 8;
            }

        }
    }
    
    static center = new Point2D(0,0);
    static octantFromOffsets = (dx:number, dy: number): number=> {
        return new Point2D(dx*8, dy*8).octantFrom(Point2D.center);
    }

    static offsetsFromOctant = (octant: number):Point2D=> {
        switch(octant) {
            case 0:
                return new Point2D(-1, -1);
            case 1:
                return new Point2D(0, -1);
            case 2:
                return new Point2D(1, -1);
            case 3:
                return new Point2D(-1, 0);
            default:
            case 4:
                return new Point2D(0, 0);
            case 5:
                return new Point2D(1, 0);
            case 6:
                return new Point2D(-1, 1);
            case 7:
                return new Point2D(0, 1);
            case 8:
                return new Point2D(1, 1);
        }
    }

    static moveOctantPointDistanceCoeficients = [
        { dx: -Math.SQRT2 / 2, dy:  Math.SQRT2 / 2 }, // 0 = left up
        { dx: 0, dy:  1 },                            // 1 = up
        { dx: Math.SQRT2 / 2, dy:  Math.SQRT2 / 2 },  // 2 = right up
        { dx: -1, dy: 0 },                            // 3 = left
        { dx: 0, dy: 0 },                             // 4 = none
        { dx: 1, dy: 0 },                             // 5 = right
        { dx: -Math.SQRT2 / 2, dy: -Math.SQRT2 / 2 }, // 6 = left down
        { dx: 0, dy: -1 },                            // 7 = down
        { dx: Math.SQRT2 / 2, dy: -Math.SQRT2 / 2 }   // 8 = right down
    ];

    componentDistanceWithMoveOctantTo(ptTo: Point2D, moveOct: number): { dx: number, dy: number } {
        const coef = Point2D.moveOctantPointDistanceCoeficients[moveOct];
        const dx = (ptTo.x - this.x) * coef.dx - (ptTo.y - this.y) * coef.dy;
        const dy = (ptTo.x - this.x) * coef.dy + (ptTo.y - this.y) * coef.dx;
        return { dx, dy };
    }

    static sutherlandHodgman(subjectPolygon: Point2D[], clipPolygon: Point2D[]): Point2D[] {
        let outputList = subjectPolygon;
        let cp1 = clipPolygon[clipPolygon.length - 1];

        for (let j = 0; j < clipPolygon.length; j++) {
            let cp2 = clipPolygon[j];
            let inputList = outputList;
            outputList = [];
            let s = inputList[inputList.length - 1];

            for (let i = 0; i < inputList.length; i++) {
                let e = inputList[i];
                if (Point2D.isInside(cp1, cp2, e)) {
                    if (!Point2D.isInside(cp1, cp2, s)) {
                        outputList.push(Point2D.intersection(cp1, cp2, s, e));
                    }
                    outputList.push(e);
                } else if (Point2D.isInside(cp1, cp2, s)) {
                    outputList.push(Point2D.intersection(cp1, cp2, s, e));
                }
                s = e;
            }
            cp1 = cp2;
        }
        return outputList;
    }

    static polygonArea (polygon: Point2D[]): number {
        let area = 0;
        for (let i = 0; i < polygon.length; i++) {
            let j = (i + 1) % polygon.length;
            area += polygon[i].x * polygon[j].y;
            area -= polygon[j].x * polygon[i].y;
        }
        return Math.abs(area / 2);
    }


    static isInside(cp1: Point2D, cp2: Point2D, p: Point2D): boolean {
        return (cp2.x - cp1.x) * (p.y - cp1.y) > (cp2.y - cp1.y) * (p.x - cp1.x);
    }

    static intersection(cp1: Point2D, cp2: Point2D, s: Point2D, e: Point2D): Point2D {
        let dc = new Point2D(cp1.x - cp2.x, cp1.y - cp2.y);
        let dp = new Point2D(s.x - e.x, s.y - e.y);
        let n1 = cp1.x * cp2.y - cp1.y * cp2.x;
        let n2 = s.x * e.y - s.y * e.x;
        let n3 = 1.0 / (dc.x * dp.y - dc.y * dp.x);
        return new Point2D((n1 * dp.x - n2 * dc.x) * n3, (n1 * dp.y - n2 * dc.y) * n3);
    }

}


// Node class representing individual elements in the list
export interface DoublyLinkedPointListNode {
    value: Point2D;
    next: DoublyLinkedPointListNode | undefined;
    prev: DoublyLinkedPointListNode | undefined;
}

// Doubly linked list class
export class DoublyLinkedPointList {
    public head: DoublyLinkedPointListNode | undefined;
    public tail: DoublyLinkedPointListNode | undefined;
    public size: number;

    constructor() {
        this.head = undefined;
        this.tail = undefined;
        this.size = 0;
    }

    // Check if the list is empty
    public isEmpty(): boolean {
        return this.size <= 0;
    }

    // Add an element to the end of the list
    public addLast(pt: Point2D) {
        const newNode: DoublyLinkedPointListNode = {
            value: pt,
            next: undefined,
            prev: undefined,
        };

        if (this.isEmpty()) {
            this.head = newNode;
            this.tail = newNode;
        } else {
            this.tail!.next = newNode;
            newNode.prev = this.tail;
            this.tail = newNode;
        }

        this.size++;
    }

    // Add an element to the beginning of the list
    public addFirst(pt: Point2D) {
        const newNode: DoublyLinkedPointListNode = {
            value: pt,
            next: undefined,
            prev: undefined,
        };

        if (this.isEmpty()) {
            this.head = newNode;
            this.tail = newNode;
        } else {
            this.head!.prev = newNode;
            newNode.next = this.head;
            this.head = newNode;
        }

        this.size++;
    }

}


export class SeededRandom
{
    public seed: number = 1000;
    
    nextFloat() : number{
      this.seed ++;
      let t = this.seed += 0x6D2B79F5;
      t = Math.imul(t ^ (t >>> 15), t | 1);
      t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
      return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    }
}

let measureTextCanvas: HTMLCanvasElement;

export function getTextSegments(text : string, font: string, maxWidth: number) : Array<string> {
    // re-use canvas object for better performance
    const canvas = measureTextCanvas || (measureTextCanvas = document.createElement("canvas"));
    const context = canvas.getContext("2d")!;
    context.font = font;

    let segments = new Array<string>();

    let iSegmentStart = 0;
    let iLastSpace = -1;
    let measureText = '';
    for(let i = 0; i < text.length; i++) {
        if (text[i] === ' ') {
            iLastSpace = i;
        }   
        measureText += text[i];
        let width = context.measureText(measureText).width;
        if (width > maxWidth) {
            if (iLastSpace === -1) {
                segments.push(text.substring(iSegmentStart, i));
                iSegmentStart = i;
            } else {
                segments.push(text.substring(iSegmentStart, iLastSpace));
                iSegmentStart = iLastSpace + 1;
            }
            measureText = '';
            iLastSpace = -1;
            i = iSegmentStart;
        }
    }
    segments.push(text.substring(iSegmentStart, text.length));
    return segments
}
  
export class BitVector {
    private data: Uint32Array;
    private size: number;
    constructor(size: number) {
        this.size = size;
        this.data = new Uint32Array((size + 31) / 32);
    }

    setBit(index: number) {
        this.data[index >> 5] |= 1 << (index & 31);
    }
    clearBit(index: number) {
        this.data[index >> 5] &= ~(1 << (index & 31));
    }

    getBit(index: number): boolean {
        let set = this.data[index >> 5];
        return (set !==0 && (set & (1 << (index & 31))) !== 0);
    }

    clear() {
        this.data.fill(0);
    }

    isAllFalse() {
        for (let i = 0; i < this.data.length; i++) {
            if (this.data[i] !== 0) {
                return false;
            }
        }
        return true;
    }
}