import tinycolor from 'tinycolor2';
import {colorMixKM, getLookUpTable} from './kubelkaMonk';
import {Point2D, SeededRandom} from './utils';
import {ChangeTextureSet, FingerTip, FlowCell, FlowCellMove, TipAction, TipApplication, TipInstrument, TipMotion} from './fingertip';
import { CrcOff, Page } from './page';
import { initialize } from 'workbox-google-analytics';
import { GPURunner, ProgramInfo } from './GPURunner';
import { TouchPageChange, TouchProgramBuffers, TouchProgramInfo, TouchUniformLocations } from './TouchPageChange';
import { PageChange, PageChangeEntry } from './PageChange';

export interface PageChangeDrop {
    color: tinycolor.ColorFormats.RGB | undefined;
    offset: Point2D;
    width: number;
    height: number;
    depth: Uint16Array;
};

export interface CircleTouchUniformLocations extends TouchUniformLocations {
    externalDropDepthTexNum: WebGLUniformLocation,
    externalDropDepthTextureOffset: WebGLUniformLocation,
    dropColor: WebGLUniformLocation,
};

export interface CircleTouchProgramInfo extends TouchProgramInfo {
    uniformLocations: CircleTouchUniformLocations
};

export interface CircleTouchProgramBuffers extends TouchProgramBuffers {
    dropDepthTexture: WebGLTexture | null,
    maxDepthTexture: WebGLTexture | null,
    commitTexture: WebGLTexture | null,
    moveFromTexture: WebGLTexture | null,
    moveFracTexture: WebGLTexture | null,

};


export class CirclePageChange extends TouchPageChange {

    static dropProgram: CircleTouchProgramInfo = {tag: 'dropProgram', uniformLocations:{}} as CircleTouchProgramInfo;
    static circleBuffers: CircleTouchProgramBuffers | undefined;

static fsSourceDrop = 
TouchPageChange.fsSourceProcessGlobals  
+ `  
#line 1048
uniform highp usampler2D u_kmLookupTexNum;
uniform highp usampler2D u_externalDropDepthTexNum;
uniform highp vec4 u_dropColor;
uniform highp ivec2 u_externalDropDepthTextureOffset;
` + TouchPageChange.fsColorMixRGBToLatentSource + `
#line 1054
void main() {
    outputDepth = texture(u_workingDepthTexNum, v_texCoordRendered).r;
    outputLatentC = texture(u_workingLatentCTexNum, v_texCoordRendered);
    outputLatentM = texture(u_workingLatentMTexNum, v_texCoordRendered);
    Latent outputLatent = latentFromLatentCM(outputLatentC, outputLatentM);

    highp uvec2 sourceSize = uvec2(textureSize(u_workingLatentCTexNum, 0));
    highp ivec2 sourcePosition = ivec2(v_texCoordExternal * vec2(sourceSize));

    highp uvec2 dropSize = uvec2(textureSize(u_externalDropDepthTexNum, 0));

    highp ivec2 dropPosition = ivec2(sourcePosition - u_externalDropDepthTextureOffset);
    if (!(dropPosition.x < 0 || uint(dropPosition.x) >= dropSize.x || dropPosition.y < 0 || uint(dropPosition.y) >= dropSize.y)) {
        highp uint dropDepth = texelFetch(u_externalDropDepthTexNum, dropPosition, 0).r;
        if (dropDepth > 0u) {
            highp float blendWeight = float(dropDepth) / (float(dropDepth) + float(outputDepth));
            Latent dropLatent = rgbaToLatent(u_dropColor);
            outputLatent = lerpLatent(outputLatent, dropLatent, blendWeight);
            outputDepth += dropDepth;
        }
    }
    outputLatentC = latentToLatentC(outputLatent);
    outputLatentM = latentToLatentM(outputLatent);
}`;

    static getPrograms(): Array<[string, ProgramInfo]> {
        return [[CirclePageChange.fsSourceDrop, CirclePageChange.dropProgram]];
    }

    static initProgramLocations(gl: WebGL2RenderingContext) {
        CirclePageChange.dropProgram.uniformLocations = {
            kmLookupTexNum: gl.getUniformLocation(CirclePageChange.dropProgram.program, 'u_kmLookupTexNum')!,
            externalDropDepthTexNum: gl.getUniformLocation(CirclePageChange.dropProgram.program, 'u_externalDropDepthTexNum')!,
            externalDropDepthTextureOffset: gl.getUniformLocation(CirclePageChange.dropProgram.program, 'u_externalDropDepthTextureOffset')!,
            dropColor: gl.getUniformLocation(CirclePageChange.dropProgram.program, 'u_dropColor')!,
        } as CircleTouchUniformLocations;
        TouchPageChange.addProcessProgramLocations(gl, CirclePageChange.dropProgram);
    }

    static initPermanentStorage(gl: WebGL2RenderingContext, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {
        CirclePageChange.circleBuffers = {} as CircleTouchProgramBuffers;
        CirclePageChange.addPermanentStorage(gl, CirclePageChange.circleBuffers, textureCallback);
    }
    static addPermanentStorage(gl: WebGL2RenderingContext, buffers: CircleTouchProgramBuffers, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {

        if (CirclePageChange.circleBuffers!.maxDepthTexture === undefined)
        {
            TouchPageChange.addPermanentStorage(gl, buffers, textureCallback);
            CirclePageChange.circleBuffers!.maxDepthTexture = GPURunner.initTexture(gl, 'maxDepthTexture', true);
            CirclePageChange.circleBuffers!.moveFromTexture = GPURunner.initTexture(gl, 'moveFromTexture', true);
            CirclePageChange.circleBuffers!.moveFracTexture = GPURunner.initTexture(gl, 'moveFracTexture', true);
            CirclePageChange.circleBuffers!.commitTexture = GPURunner.initTexture(gl, 'commitTexture', true);
            CirclePageChange.circleBuffers!.dropDepthTexture = GPURunner.initTexture(gl, 'dropDepthTexture');
            
            // get the static change textures
            const circleChangeTextureSet = textureCallback(0, {instrument: TipInstrument.finger, motion: TipMotion.press, action: TipAction.unknown, modifier: 0});

            gl.activeTexture(gl.TEXTURE0 + TouchPageChange.actionFromTexNumVal);
            gl.bindTexture(gl.TEXTURE_2D_ARRAY, CirclePageChange.circleBuffers!.moveFromTexture);
            gl.texImage3D(gl.TEXTURE_2D_ARRAY, 0, gl.RGBA16UI, 
            circleChangeTextureSet.width, circleChangeTextureSet.height, circleChangeTextureSet.moveEntries, 0, 
            gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, circleChangeTextureSet.texFrom!);

            gl.activeTexture(gl.TEXTURE0 + TouchPageChange.actionFracTexNumVal);
            gl.bindTexture(gl.TEXTURE_2D_ARRAY, CirclePageChange.circleBuffers!.moveFracTexture);
            gl.texImage3D(gl.TEXTURE_2D_ARRAY, 0, gl.RGBA32F, 
            circleChangeTextureSet.width, circleChangeTextureSet.height, circleChangeTextureSet.moveEntries, 0, 
            gl.RGBA, gl.FLOAT, circleChangeTextureSet.texFrac!);

            gl.activeTexture(gl.TEXTURE0 + TouchPageChange.actionCommitTexNumVal);
            gl.bindTexture(gl.TEXTURE_2D_ARRAY, CirclePageChange.circleBuffers!.commitTexture);
            gl.texImage3D(gl.TEXTURE_2D_ARRAY, 0, gl.R32F, 
            circleChangeTextureSet.width, circleChangeTextureSet.height, circleChangeTextureSet.moveEntries, 0, 
            gl.RED, gl.FLOAT, circleChangeTextureSet.texCommit!);

            gl.activeTexture(gl.TEXTURE0 + TouchPageChange.maxDepthTexNumVal);
            gl.bindTexture(gl.TEXTURE_2D_ARRAY, CirclePageChange.circleBuffers!.maxDepthTexture);
            gl.texImage3D(gl.TEXTURE_2D_ARRAY, 0, gl.R16UI, 
            circleChangeTextureSet.width, circleChangeTextureSet.height, circleChangeTextureSet.maxEntries, 0, 
            gl.RED_INTEGER, gl.UNSIGNED_SHORT, circleChangeTextureSet.texMax!);

        }
        buffers.maxDepthTexture = CirclePageChange.circleBuffers!.maxDepthTexture;
        buffers.moveFromTexture = CirclePageChange.circleBuffers!.moveFromTexture;
        buffers.moveFracTexture = CirclePageChange.circleBuffers!.moveFracTexture;
        buffers.commitTexture = CirclePageChange.circleBuffers!.commitTexture;
        buffers.dropDepthTexture = CirclePageChange.circleBuffers!.dropDepthTexture;
    }

    static externalDropDepthTexNumVal = 15;

    initialDrop: PageChangeDrop | undefined;

    constructor(page: Page, pageX: number, pageY: number, width: number, height: number,
        tipsAndLocations: Array<PageChangeEntry>, tipApplication: TipApplication,
        dryTransmits: boolean, centralPull: ((d:number)=>number) | undefined) {
        super(page, pageX, pageY, width, height, tipsAndLocations, tipApplication, dryTransmits, centralPull);
    }

    override initializeStartupProgram(pi: CircleTouchProgramInfo): void {
        let gl = GPURunner.gl!;
        let buffers = CirclePageChange.circleBuffers!;
        super._initializeStartupProgram(pi, buffers);
    }

    override specializeStartupRoutine(pi: CircleTouchProgramInfo): void {
        let gl = GPURunner.gl!;
        let buffers = CirclePageChange.circleBuffers!;
        super._specializeStartupRoutine(pi, buffers);
    }

    override runStartupRoutine(pi: CircleTouchProgramInfo): void {
        let buffers = CirclePageChange.circleBuffers!;
        super._runStartupRoutine(pi, buffers);
    }
    override finalizeStartup(pi: CircleTouchProgramInfo): void {
        let buffers = CirclePageChange.circleBuffers!;
        super._finalizeStartup(pi, buffers);
    }
    override initializeProcessProgram(pi: CircleTouchProgramInfo): void {
        let buffers = CirclePageChange.circleBuffers!;
        let gl = GPURunner.gl!;
        super._initializeProcessProgram(pi, buffers);

        if (GPURunner.doInitializeProgram(CirclePageChange.dropProgram, 'circle')) {
            let piSave = pi;
            pi = CirclePageChange.dropProgram;
            gl.useProgram(pi.program);
            super._initializeProcessProgram(pi, buffers);

            gl.uniform1i(pi.uniformLocations.kmLookupTexNum, CirclePageChange.kmLookupTexNumVal);
            gl.uniform1i(pi.uniformLocations.externalDropDepthTexNum, CirclePageChange.externalDropDepthTexNumVal);
            gl.uniform4f(pi.uniformLocations.dropColor, 0, 0, 0, 0);
            pi = piSave;
            gl.useProgram(pi.program);
        }

    }
    override specializeProcessRoutine(pi: CircleTouchProgramInfo): void {
        let buffers = CirclePageChange.circleBuffers!;
        super._specializeProcessRoutine(pi, buffers);
        let gl = GPURunner.gl!;
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.actionFromTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D_ARRAY, CirclePageChange.circleBuffers!.moveFromTexture);
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.actionFracTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D_ARRAY, CirclePageChange.circleBuffers!.moveFracTexture);
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.actionCommitTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D_ARRAY, CirclePageChange.circleBuffers!.commitTexture);
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.maxDepthTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D_ARRAY, CirclePageChange.circleBuffers!.maxDepthTexture);

    }

    override runProcessRoutine(pi: CircleTouchProgramInfo): void {
        let buffers = CirclePageChange.circleBuffers!;
        let gl = GPURunner.gl!;
        //console.log('run process gets outputDepthTexture');
        //this.debugSpewDepthTexture(this.outputDepthTexture!);


        if (this.initialDrop !== undefined) {
            let piSave = pi;
            pi = CirclePageChange.dropProgram;
            gl.useProgram(pi.program);
    
            let r = this.initialDrop.color!.r / 255.0;
            let g = this.initialDrop.color!.g / 255.0;
            let b = this.initialDrop.color!.b / 255.0;
            gl.uniform4f(pi.uniformLocations.dropColor, r, g, b, 1.0);
            let dropOffX = this.initialDrop.offset.x;
            let dropOffY = this.initialDrop.offset.y;
            gl.uniform2i(pi.uniformLocations.externalDropDepthTextureOffset, dropOffX, dropOffY);

            // load the initial drop texture 
            gl.activeTexture(gl.TEXTURE0 + CirclePageChange.externalDropDepthTexNumVal);
            gl.bindTexture(gl.TEXTURE_2D, CirclePageChange.circleBuffers!.dropDepthTexture);
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.R16UI, this.initialDrop.width, this.initialDrop.height, 0, gl.RED_INTEGER, gl.UNSIGNED_SHORT, this.initialDrop.depth);

            this.stashOutput();
            this.bindStashAsWorking();
            this.useAvailableOutputFrame();
            gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);
            pi = piSave;
        } 
        this.accumulateForEntries(
            (tipLoc: PageChangeEntry): boolean => {
                let tip = tipLoc.shape as FingerTip;
                let center = tipLoc.location;
               
                // the tip has a sequence of changes to make
                const specialApplication = {instrument: this.tipApplication.instrument, motion: this.tipApplication.motion, action: this.tipApplication.action, modifier: tipLoc.modifier};
                const changeTextures = tip.getChangeTextureIndexes(specialApplication);
                this.accumulateTextures(center, changeTextures, 0, changeTextures.idxsAction.length,
                     (idx:number)=>{
                        let pi = TouchPageChange.processAccumulateTakesProgram;
                        gl.useProgram(pi.program);
                        return pi;
                     }, (idx:number, stopEarly: boolean)=>{
                        let pi = TouchPageChange.processCommitAccumulatorProgram;
                        gl.useProgram(pi.program);
                        gl.uniform1i(pi.uniformLocations.writeAccum, stopEarly ? 0 : 1);
                        return pi;

                     });
                return true;
            }
        );

    }
    override finalizeProcess(pi: CircleTouchProgramInfo): void {
        let buffers = CirclePageChange.circleBuffers!;
        super._finalizeProcess(pi, buffers);

    }
    override initializeFinishProgram(pi: CircleTouchProgramInfo): void {
        let buffers = CirclePageChange.circleBuffers!;
        super._initializeFinishProgram(pi, buffers);

    }
    override specializeFinishRoutine(pi: CircleTouchProgramInfo): void {
        let buffers = CirclePageChange.circleBuffers!;
        super._specializeFinishRoutine(pi, buffers);
    }

    override runFinishRoutine(pi: CircleTouchProgramInfo): void {
        let buffers = CirclePageChange.circleBuffers!;
        super._runFinishRoutine(pi, buffers);

    }
    override finalizeFinish(pi: CircleTouchProgramInfo): void {
        let buffers = CirclePageChange.circleBuffers!;
        super._finalizeFinish(pi, buffers);

    }

}

        