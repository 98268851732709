import tinycolor from 'tinycolor2';
import {colorMixKM, Latent, latentFromLatentCM, latentToLatentC, latentToLatentM, latentToRgb, lerpLatent, rgbToLatent} from './kubelkaMonk';
import {BitVector, Point2D, SeededRandom} from './utils';
import {ChangeTextureSet, FingerTip, FlowCell, FlowCellMove, TipMotion, TipApplication, TipInstrument, TipAction} from './fingertip';
import { TouchPageChange } from './TouchPageChange';
import { GPURunner } from './GPURunner';
import { CirclePageChange } from './circlePageChange';
import { SmearPageChange } from './smearPageChange';
import { DryPageChange } from './dryPageChange';
import { BlowPageChange } from './blowPageChange';
import { RefreshPageChange } from './refreshPageChange';
import { PageChangeEntry } from './PageChange';
import {App} from '../../App';
import { KnifePageChange } from './knifePageChange';
import { SamplePageChange } from './samplePageChange';
import { StampPageChange } from './stampPageChange';


export enum CellKind {
    background = 0,
    corona = 1,
    meniscus = 2,
    smash = 3,
    dry = 5,
    knife = 6,
    scrape = 16,
    hidden = 32
}

export interface DragStep {
    radius: number;
    smashRadius: number;
    location: Point2D;
    octantMove: number;
}

// the overall effect on page cells resulting from placing or dragging a finger tip on it
export class TipEffect {
    rectTL: Point2D = new Point2D(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
    rectBR: Point2D = new Point2D(-Number.MAX_SAFE_INTEGER, -Number.MAX_SAFE_INTEGER);
    cells: Array<number>;
    steps: Array<DragStep>;
    changeEntries: Array<PageChangeEntry>;
    constructor() {
        this.steps = new Array<DragStep>();
        this.cells = new Array<number>();
        this.changeEntries = new Array<PageChangeEntry>();
    }

    public isEmpty() : boolean {
        return this.cells.length === 0;
    }
}

class PageTipPlacement {
    page: Page;
    tip: FingerTip;
    pageCenter: Point2D;
    pageTipCenter: Point2D;
    //tipApplication: TipApplication;
    pageBuffer: Uint8Array;
    setWetDry: boolean;
    constructor(page: Page, tip: FingerTip, tipPageCenter: Point2D, pageBuffer: Uint8Array, setWetDry: boolean) {
        this.page = page;
        this.tip = tip;
        this.pageCenter = new Point2D(page.cellsWidth / 2, page.cellsHeight / 2);
        this.pageTipCenter = tipPageCenter;
        this.pageBuffer = pageBuffer;
        this.setWetDry = setWetDry;
    }
}

export type CrcOff = (CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D);

// ######                       
// #     #   ##    ####  ###### 
// #     #  #  #  #    # #      
// ######  #    # #      #####  
// #       ###### #  ### #      
// #       #    # #    # #      
// #       #    #  ####  ###### 
export class Page {

    static renderScale = 1;

    static paintDepthScale = 4;
    static maxCoronaDepth = Math.round(256 * Page.paintDepthScale);
    static backgroundImplicitDepth = 1.5;
    static pixToScaleUnits(cPix: number):number {
        return Math.round(cPix );
    }
    static scaleUnitsToPix(u: number):number {
        return u * this.renderScale;
    }

    public static offDryRed = 0;
    public static offDryGreen = 1;
    public static offDryBlue = 2;
    public static offWetRed = 3;
    public static offWetGreen = 4;
    public static offWetBlue = 5;
    public static offDepthHigh = 6;
    public static offDepthLow = 7;
    public static offVisRed = 8;
    public static offVisGreen = 9;
    public static offVisBlue = 10;

    cellsWidth: number;
    cellsHeight: number;
    cellsTotal: number;

    cellData: Uint8Array // dried, wet, depth, visible
    cellDataBlade: Uint8Array
    cellDryApplied: Uint16Array | undefined;

    wetCanvasOffsets: Map<number, number>;
    wetCanvasStage: number;
    wetCanvasStageMax: number;
    wetCanvasStageOffsets: Array<Set<number>>;

    rnd: SeededRandom
    dryTransmits: boolean = true;

    centralPull: ((d:number)=>number) | undefined;
    lastTipPlacement: PageTipPlacement | undefined;
    lastTipApplication: TipApplication | undefined;

    crcVisible: CanvasRenderingContext2D;

    buffVisibleLatentC: WebGLRenderbuffer | undefined;
    buffVisibleLatentM: WebGLRenderbuffer | undefined;
    visibleLatentCFramebufferR: WebGLFramebuffer | null = null;
    visibleLatentMFramebufferR: WebGLFramebuffer | null = null;
    visibleLatentCFramebufferW: WebGLFramebuffer | null = null;
    visibleLatentMFramebufferW: WebGLFramebuffer | null = null;
    buffWetDepth: WebGLRenderbuffer | undefined;
    wetDepthFramebufferR: WebGLFramebuffer | null = null;
    wetDepthFramebufferW: WebGLFramebuffer | null = null;
    buffWetAge: WebGLRenderbuffer | undefined;
    wetAgeFramebufferR: WebGLFramebuffer | null = null;
    wetAgeFramebufferW: WebGLFramebuffer | null = null;
    buffWetLatentC: WebGLRenderbuffer | undefined;
    buffWetLatentM: WebGLRenderbuffer | undefined;
    wetLatentCFramebufferR: WebGLFramebuffer | null = null;
    wetLatentMFramebufferR: WebGLFramebuffer | null = null;
    wetLatentCFramebufferW: WebGLFramebuffer | null = null;
    wetLatentMFramebufferW: WebGLFramebuffer | null = null;
    buffDryLatentC: WebGLRenderbuffer | undefined;
    buffDryLatentM: WebGLRenderbuffer | undefined;
    dryLatentCFramebufferR: WebGLFramebuffer | null = null;
    dryLatentMFramebufferR: WebGLFramebuffer | null = null;
    dryLatentCFramebufferW: WebGLFramebuffer | null = null;
    dryLatentMFramebufferW: WebGLFramebuffer | null = null;

    clientRec: DOMRect;
    paintDries: boolean;

    public static getOffscreenCrc(width: number, height: number, options: any) : CrcOff {
        if ((typeof OffscreenCanvas) !== 'undefined' && OffscreenCanvas !== undefined && OffscreenCanvas !== null) {
            let cvs = new OffscreenCanvas(width, height);
            return cvs.getContext("2d", options)!;
        } else {
            let cvs = document.createElement('canvas');
            cvs.width = width;
            cvs.height = height;
            return cvs.getContext("2d", options)! as CanvasRenderingContext2D;
        }
    }

    public static initializeGPU() {

        // let whiteRgb = new Uint8Array([255, 255, 255]);
        // let yelRgb = new Uint8Array([253, 199, 12]);

        // let whiteLatent = rgbToLatent(whiteRgb,0);
        // let yelLatent = rgbToLatent(yelRgb,0);
        // let blendLatent = rgbToLatent(yelRgb,0);

        // let whiteC = latentToLatentC(whiteLatent);
        // let whiteM = latentToLatentM(whiteLatent);
        // let whiteLatentInt = latentFromLatentCM(whiteC, whiteM);
        // let yelC = latentToLatentC(yelLatent);
        // let yelM = latentToLatentM(yelLatent);
        // let blendC = latentToLatentC(blendLatent);
        // let blendM = latentToLatentM(blendLatent);

        // let blendRgb = new Uint8Array([0, 0, 0]);
        // let blendRgbInt = new Uint8Array([0, 0, 0]);
        // let lastDriftR = 0;
        // let lastDriftG = 0;
        // let lastDriftB = 0;

        // let yelVol = 10;
        // let whiteVol = 20;
        // let totalVol = yelVol;

        // for (let rep = 0; rep<4000;rep++) {
        //     totalVol += whiteVol;
        //     if (totalVol > 30000) {
        //         totalVol = 30000;
        //     }
        //     if (rep === 443) {
        //         console.log('rep', rep);

        //     }

        //     let blendWeight = whiteVol / totalVol;

        //     blendLatent = lerpLatent(blendLatent, whiteLatent, blendWeight);

        //     let blendLatentInt = latentFromLatentCM(blendC, blendM);
        //     blendLatentInt = lerpLatent(blendLatentInt, whiteLatentInt, blendWeight);

        //     latentToRgb(blendLatent, blendRgb, 0);
        //     latentToRgb(blendLatentInt, blendRgbInt, 0);

        //     let driftR = blendRgb[0] - blendRgbInt[0];
        //     let driftG = blendRgb[1] - blendRgbInt[1];
        //     let driftB = blendRgb[2] - blendRgbInt[2];

        //     if (driftR !== lastDriftR || driftG !== lastDriftG || driftB !== lastDriftB) {
        //         console.log('rep', rep);
        //         if (driftR === 0 && driftG === 0 && driftB === 0) {
        //             console.log('fixed');
        //         } else {
        //             console.log('blendRgb', blendRgb);
        //             console.log('blendRgbInt', blendRgbInt);
        //         }
        //         lastDriftR = driftR;
        //         lastDriftG = driftG;
        //         lastDriftB = driftB;
        //     }


        //     blendC = latentToLatentC(blendLatentInt);
        //     blendM = latentToLatentM(blendLatentInt);


        //     //blendLatent = rgbToLatent(blendRgb, 0 );



        // }
        // latentToRgb(blendLatent, blendRgb, 0);


        // let whiteRgb = new Uint8Array([255, 255, 255]);
        // let whiteLatent = rgbToLatent(whiteRgb,0);

        // let rgb = new Uint8Array([0, 0, 0]);
        // let blendLatent = {concentration0 : 0, concentration1 : 0, concentration2 : 0, concentration3 : 0, missingRed: 0, missingGreen: 0, missingBlue: 0} as Latent;
        // let alpha5 = ()=> {
        //     let lat = rgbToLatent(rgb, 0);
        //     console.log(`${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${lat.concentration0}, ${lat.concentration1}, ${lat.concentration2}, ${lat.concentration3}, ${lat.missingRed}, ${lat.missingGreen}, ${lat.missingBlue}, ${blendLatent.concentration0}, ${blendLatent.concentration1}, ${blendLatent.concentration2}, ${blendLatent.concentration3}, ${blendLatent.missingRed}, ${blendLatent.missingGreen}, ${blendLatent.missingBlue}`);
            
        //     let blendWeight = 3 / 100;
        //     blendLatent = lerpLatent(lat, whiteLatent, blendWeight);

        //     let rd = rgb[0];
        //     let gd = rgb[1];
        //     let bd = rgb[2];
        //     let ad = .95;
        //     rd = Math.floor(rd * ad + (1-ad) * 255);
        //     gd = Math.floor(gd * ad + (1-ad) * 255);
        //     bd = Math.floor(bd * ad + (1-ad) * 255);
        //     if (rd*gd*bd === 1) {
        //         rd=gd=bd=0;
        //     }
        //     rgb[0] = rd;
        //     rgb[1] = gd;
        //     rgb[2] = bd;
        // }

        // for (let i = 0; i < 10; i++ ) {
        //     alpha5();
        // }

        // rgb = new Uint8Array([255, 0, 0]);
        // blendLatent = {concentration0 : 0, concentration1 : 0, concentration2 : 0, concentration3 : 0, missingRed: 0, missingGreen: 0, missingBlue: 0} as Latent;
        // for (let i = 0; i < 10; i++ ) {
        //     alpha5();
        // }

        // rgb = new Uint8Array([0, 255, 0]);
        // blendLatent = {concentration0 : 0, concentration1 : 0, concentration2 : 0, concentration3 : 0, missingRed: 0, missingGreen: 0, missingBlue: 0} as Latent;
        // for (let i = 0; i < 10; i++ ) {
        //     alpha5();
        // }
        // rgb = new Uint8Array([0, 0, 255]);
        // blendLatent = {concentration0 : 0, concentration1 : 0, concentration2 : 0, concentration3 : 0, missingRed: 0, missingGreen: 0, missingBlue: 0} as Latent;
        // for (let i = 0; i < 10; i++ ) {
        //     alpha5();
        // }
        // rgb = new Uint8Array([128, 128, 128]);
        // blendLatent = {concentration0 : 0, concentration1 : 0, concentration2 : 0, concentration3 : 0, missingRed: 0, missingGreen: 0, missingBlue: 0} as Latent;
        // for (let i = 0; i < 10; i++ ) {
        //     alpha5();
        // }
        // rgb = new Uint8Array([200, 100, 0]);
        // blendLatent = {concentration0 : 0, concentration1 : 0, concentration2 : 0, concentration3 : 0, missingRed: 0, missingGreen: 0, missingBlue: 0} as Latent;
        // for (let i = 0; i < 10; i++ ) {
        //     alpha5();
        // }
        // rgb = new Uint8Array([20, 200, 100]);
        // blendLatent = {concentration0 : 0, concentration1 : 0, concentration2 : 0, concentration3 : 0, missingRed: 0, missingGreen: 0, missingBlue: 0} as Latent;
        // for (let i = 0; i < 10; i++ ) {
        //     alpha5();
        // }
        // rgb = new Uint8Array([100, 20, 200]);
        // blendLatent = {concentration0 : 0, concentration1 : 0, concentration2 : 0, concentration3 : 0, missingRed: 0, missingGreen: 0, missingBlue: 0} as Latent;
        // for (let i = 0; i < 10; i++ ) {
        //     alpha5();
        // }




        GPURunner.initializeGPU((radius: number,application: TipApplication) : ChangeTextureSet=>{
            if (application.instrument === TipInstrument.finger && (application.motion === TipMotion.press || application.motion === TipMotion.lift)) {
                return FingerTip.makeCircleChangeTextureSet(radius);
            } else if (application.instrument === TipInstrument.finger && application.motion === TipMotion.drag) {
                return FingerTip.makeSmearChangeTextureSet(radius);
            } else if (application.instrument === TipInstrument.blow) {
                
            } else if (application.instrument === TipInstrument.knife) {
                return FingerTip.makeKnifeChangeTextureSet();
            }

            return {} as ChangeTextureSet;

        });
    }

    constructor(clientRec:DOMRect, cx: number, cy: number, crcVisible: CanvasRenderingContext2D, centralPull: ((d:number)=>number) | undefined, rnd: SeededRandom, dryTransmits: boolean, paintDries: boolean) {
        this.clientRec = clientRec;
        this.cellsWidth = cx;
        this.cellsHeight = cy;
        this.cellsTotal = this.cellsWidth * this.cellsHeight;
        this.cellData = new Uint8Array(this.cellsTotal * (3 + 3 + 2 + 3));
        this.cellDataBlade = new Uint8Array(3 * FingerTip.cellsWidth * (3 + 3 + 2 + 3));
        this.rnd = rnd;
        this.centralPull = centralPull;
        this.wetCanvasOffsets = new Map<number, number>();
        this.wetCanvasStage = 0;
        this.wetCanvasStageMax = 7;
        this.wetCanvasStageOffsets = new Array<Set<number>>(this.wetCanvasStageMax);
        for (let i = 0; i < this.wetCanvasStageMax; i++) {
            this.wetCanvasStageOffsets[i] = new Set<number>();
        }
        this.dryTransmits = dryTransmits;

        this.crcVisible = crcVisible;
        this.paintDries = paintDries;
        if (App.useGPU) {
            GPURunner.allocatePage(this);
        }

    }

    public clear(onClear: undefined | ((crc: CanvasRenderingContext2D)=>void), centralPull: ((d:number)=>number) | undefined, rnd: SeededRandom, blendDried: boolean) {
        let width = this.crcVisible.canvas.width;
        let height = this.crcVisible.canvas.height;

        this.crcVisible.fillStyle = 'white';
        this.crcVisible.fillRect(0, 0, width, height);
        if (App.useGPU) {
            GPURunner.clearPage(this);
        }
        
        // dried, wet, depth, visible
        let clearData = [255,255,255,1,1,1,0,0,255,255,255];
        for (let c = 0; c < this.cellsTotal; c++) {
            let off = c * 11;
            this.cellData.set(clearData, off);
        }
        
        if (onClear !== undefined) {
            onClear(this.crcVisible);
        }
    }
    
    public offsetOfCoordinates(pt: Point2D): number {
        if (pt.x < 0 || pt.y < 0 || pt.x > this.cellsWidth-1 || pt.y > this.cellsHeight-1) {
            return -1;
        }
        return this.cellsWidth * pt.y + pt.x;
    }
    public coordinatesOfOffset(offset: number): Point2D {
        let x = offset % this.cellsWidth;
        let y = Math.floor(offset / this.cellsWidth);
        return new Point2D(x, y);
    }

    public wetColorAtOffset(offset: number) : tinycolor.ColorFormats.RGB {
        let rOff = offset * 11 + 3;
        // dried, wet, depth, visible
        let r = this.cellData[rOff];
        let g = this.cellData[rOff + 1];
        let b = this.cellData[rOff + 2];

        return {r: r, g: g, b: b};
    }
    public visibleColorAtOffset(offset: number) : tinycolor.ColorFormats.RGB {
        let rOff = offset * 11 + 8;
        // dried, wet, depth, visible
        let r = this.cellData[rOff];
        let g = this.cellData[rOff + 1];
        let b = this.cellData[rOff + 2];
        return {r: r, g: g, b: b};
    }

    public getWetDepthAtOffset(c: number) : number {
        let off = c * 11;
        let d = (this.cellData[off + 6] << 8) | this.cellData[off + 7];
        return d;
    }

    public setWetDepthAtOffset(c: number, d: number) {
        let off = c * 11;
        this.cellData[off + 6] = d >> 8;
        this.cellData[off + 7] = d & 0xff;
    }


    public tipCoordinatesToPageCoordinates(ptTip: Point2D, ptCenter: Point2D) : Point2D{
        return new Point2D(ptTip.x + ptCenter.x - FingerTip.cellsWidth/2, ptTip.y + ptCenter.y - FingerTip.cellsWidth/2);
    }

    private getChangeDimensions(minX: number, maxX: number, minY: number, maxY: number): {minX: number, minY:number, width:number, height: number} | undefined {
        let pageWidth = this.cellsWidth;
        let pageHeight = this.cellsHeight;
        let lastChangeDims = GPURunner.getCurrentViewport();
        let lastWidth = lastChangeDims[0];
        let lastHeight = lastChangeDims[1];
        //minX -= 6;
        //minY -= 16;
        let width = maxX - minX + 1;
        let height = maxY - minY + 1;
        let addWidth = 0;
        let addHeight = 0;
        if (width > pageWidth) {
            width = pageWidth;
            minX = 0;
            maxX = pageWidth - 1;
        }
        if (height > pageHeight) {
            height = pageHeight;
            minY = 0;
            maxY = pageHeight - 1;
        }

        // use the last change area if it isn't too small or too large
        if (lastWidth <= pageWidth && lastHeight <= pageHeight) {
            if (width <= lastWidth && height <= lastHeight) {
                if (width >= lastWidth/2 && height >= lastHeight/2) {
                    addWidth = lastWidth - width;
                    addHeight = lastHeight - height;
                }
            }
        }
        if (addWidth === 0 && addHeight === 0) {
            let align = 16;
            addWidth = width % align;
            addHeight = height % align;
            addHeight = addHeight > 0 ? align-addHeight : addHeight;
            addWidth = addWidth > 0 ? align-addWidth : addWidth;
        }
        //addWidth += 8;
        if (width + addWidth <= pageWidth) {
            width += addWidth;
            minX -= Math.floor(addWidth / 2);
            maxX += Math.ceil(addWidth / 2);
        }
        if (height + addHeight <= pageHeight) {
            height += addHeight;
            minY -= Math.floor(addHeight / 2);
            maxY += Math.ceil(addHeight / 2);
        }
        if (minX < 0) {
            maxX += -minX;
            minX = 0;
        }
        if (minY < 0) {
            maxY += -minY;
            minY = 0;
        }
        if (maxX > pageWidth - 1) {
            minX -= maxX - (pageWidth - 1);
            maxX = pageWidth - 1;
        }
        if (maxY > pageHeight - 1) {
            minY -= maxY - (pageHeight - 1);
            maxY = pageHeight - 1;
        }
        if (minX < 0 || minY < 0) {
            throw new Error('PageChange area out of bounds');
        }

        if (width <= 0 || height <= 0) {
            return undefined;
        }
        return {minX, minY, width, height};

    }


    public createTouchPageChange(tipsAndLocations: Array<PageChangeEntry>, tipApplication: TipApplication, setWetDry: boolean, paintRadius: number) : TouchPageChange | undefined {
        //console.time('createPageChange');
        // get bounds of all tips
        let minX = Number.MAX_SAFE_INTEGER;
        let minY = Number.MAX_SAFE_INTEGER;
        let maxX = -Number.MAX_SAFE_INTEGER;
        let maxY = -Number.MAX_SAFE_INTEGER;
       
        for (const entry of tipsAndLocations) {
            let entryMinX: number;
            let entryMaxX: number;
            let entryMinY: number;
            let entryMaxY: number;
            if ((entry.shape as any)['effectRadius'] !== undefined) {
                let shape = entry.shape as FingerTip;
                entryMinX = entry.location.x - shape.effectRadius;
                entryMaxX = entry.location.x + shape.effectRadius;
                entryMinY = entry.location.y - shape.effectRadius;
                entryMaxY = entry.location.y + shape.effectRadius;
            } else {
                let shape = entry.shape as {dx: number, dy: number};
                entryMinX = entry.location.x;
                entryMaxX = entry.location.x + shape.dx;;
                entryMinY = entry.location.y;
                entryMaxY = entry.location.y + shape.dy;
            }
            minX = Math.max(0, Math.min(minX, entryMinX));
            minY = Math.max(0, Math.min(minY, entryMinY));
            maxX = Math.min(this.cellsWidth - 1, Math.max(maxX, entryMaxX));
            maxY = Math.min(this.cellsHeight - 1, Math.max(maxY, entryMaxY));
        }
        let changeDims = this.getChangeDimensions(minX, maxX, minY, maxY);
        if (changeDims === undefined) {
            return undefined;
        }
        minX = changeDims.minX;
        minY = changeDims.minY;
        let width = changeDims.width;
        let height = changeDims.height;

        // make the tip changes relative to the change area
        let tipChanges = new Array<PageChangeEntry>();
        for (const entry of tipsAndLocations) {
            let relCenter = new Point2D(entry.location.x - minX, entry.location.y - minY);
            tipChanges.push({shape:entry.shape, location: relCenter, modifier: entry.modifier});
        }

        let change : TouchPageChange;

        if (tipApplication.instrument === TipInstrument.knife) {
            change = new KnifePageChange(this, minX, minY, width, height, tipChanges, tipApplication, this.dryTransmits, paintRadius, this.centralPull);
        } else if (tipApplication.instrument === TipInstrument.blow) {
            change = new BlowPageChange(this, minX, minY, width, height, tipChanges, tipApplication, this.dryTransmits, this.centralPull);
        } else if (tipApplication.instrument === TipInstrument.finger) {
            if (tipApplication.motion === TipMotion.drag) {
                change = new SmearPageChange(this, minX, minY, width, height, tipChanges, tipApplication, this.dryTransmits, this.centralPull);
            } else if (tipApplication.motion === TipMotion.press || tipApplication.motion === TipMotion.lift) {
                change = new CirclePageChange(this, minX, minY, width, height, tipChanges, tipApplication, this.dryTransmits, this.centralPull);
            }
        } else if (tipApplication.instrument === TipInstrument.eyeDropper) {
            change = new SamplePageChange(this, minX, minY, width, height, tipChanges, tipApplication);
        } else if (tipApplication.instrument === TipInstrument.stamp) {
            change = new StampPageChange(this, minX, minY, width, height, tipChanges, this.dryTransmits);
        }

        //console.timeEnd('createPageChange');
        return change!;
    }

    public applyTouchPageChange(pageChange: TouchPageChange) {
        let width = pageChange.width;
        let height = pageChange.height;
        let pageX = pageChange.pageX;
        let pageY = pageChange.pageY;

        pageChange.finalize();

    }

    public debugGPUGetDepth(readX0: number, readY0: number, readX1: number, readY1: number) : Uint16Array {

        return GPURunner.debugGPUGetDepth(this, readX0, readY0, readX1, readY1);
    }
   
    collectTipCellData(tipCoordinatesToBufferOffset: (ptTip: Point2D, adj: number)=>number) {
        let pageLoc = this.lastTipPlacement!.pageTipCenter;
        let tip = this.lastTipPlacement!.tip;
        let pageMinX = pageLoc.x + (tip.minX - FingerTip.cellsWidth/2);
        let pageMaxX = pageLoc.x + (tip.maxX - FingerTip.cellsWidth/2);
        let pageMinY = pageLoc.y + (tip.minY - FingerTip.cellsWidth/2);
        let pageMaxY = pageLoc.y + (tip.maxY - FingerTip.cellsWidth/2);

        pageMinX = Math.max(0, pageMinX);
        pageMinY = Math.max(0, pageMinY);
        pageMaxX = Math.min(this.cellsWidth-1, pageMaxX);
        pageMaxY = Math.min(this.cellsHeight-1, pageMaxY);

        let tipMinX = FingerTip.cellsWidth/2 - (pageLoc.x - pageMinX);
        let tipMinY = FingerTip.cellsWidth/2 - (pageLoc.y - pageMinY);
        let tipMaxX = FingerTip.cellsWidth/2 + (pageMaxX - pageLoc.x);
        let tipMaxY = FingerTip.cellsWidth/2 + (pageMaxY - pageLoc.y);
        for (let y = 0; y < tip.cellDataRows!.length; y++) {
            let row = tip.cellDataRows![y];

            row.lengthModified = 0;
            row.printRelativeModifiedStart = -1;
            if (row.row >= tipMinY && row.row <= tipMaxY) {
                let ptTip = new Point2D(row.firstCell, row.row);
                let adj = 0;
                if (ptTip.x < tipMinX) {
                    adj = (tipMinX - ptTip.x);
                    ptTip.x = tipMinX;
                }
                row.pageRelativeStart = tipCoordinatesToBufferOffset(ptTip, adj);

                let rowMinX = Math.max(tipMinX, row.firstCell);
                let rowMaxX = Math.min(tipMaxX, row.firstCell + (row.length / 11) - 1);
                let copyLength = (rowMaxX - rowMinX + 1) * 11;
                let copyTo = row.printRelativeStart + (rowMinX - row.firstCell) * 11;
                let copyFrom = row.pageRelativeStart + (rowMinX - row.firstCell) * 11;

                if (copyLength > 0) {
                    let v = new Uint8Array(this.lastTipPlacement!.pageBuffer.buffer, copyFrom, copyLength);
                    tip.allCellData!.set(v, copyTo);
                }
            }
        }

        // get the state of the page before the drag put into every source or dest cell in the print
        for (const fCell of tip.allFlowCells) {
            fCell.inBounds = fCell.ptOnPrint.x >= tipMinX && fCell.ptOnPrint.x <= tipMaxX && fCell.ptOnPrint.y >= tipMinY && fCell.ptOnPrint.y <= tipMaxY;
                // and the offset into storage arrays
            if (fCell.inBounds) {
                fCell.stagedDepth = fCell.finalDepth = (fCell.dvTipCells[Page.offDepthHigh]<<8) | fCell.dvTipCells[Page.offDepthLow];
                if (fCell.finalDepth <= 0) {
                    fCell.dvTipCells[Page.offWetRed] = fCell.dvTipCells[Page.offWetGreen] = fCell.dvTipCells[Page.offWetBlue] = 1;
                }  
            } else {
                fCell.dvTipCells[Page.offDryRed] = fCell.dvTipCells[Page.offDryGreen] = fCell.dvTipCells[Page.offDryBlue] = 1;
                fCell.dvTipCells[Page.offWetRed] = fCell.dvTipCells[Page.offWetGreen] = fCell.dvTipCells[Page.offWetBlue] = 1;
                fCell.dvTipCells[Page.offDepthHigh] = fCell.dvTipCells[Page.offDepthLow] = 0;
            }
    
            if (fCell.move1 !== undefined) {
                fCell.move1.removedDepth = 0;
            }
            if (fCell.move2 !== undefined) {
                fCell.move2.removedDepth = 0;
            }
            if (fCell.move3 !== undefined) {
                fCell.move3.removedDepth = 0;
            }
            if (fCell.move4 !== undefined) {
                fCell.move4.removedDepth = 0;
            }
            if (fCell.move5 !== undefined) {
                fCell.move5.removedDepth = 0;
            }
    
            fCell.modified = false;

        }
    }

    writeTipCellData(kindCells: Set<number>) {
        let maxDepth = 0xffff;
        let tip = this.lastTipPlacement!.tip;
        // send the final depth and color to the page and cell kind map
        let setWetDry = this.lastTipPlacement!.setWetDry
        for (const fCell of tip.allFlowCells) {
            if (fCell.modified && fCell.inBounds && fCell.finalKind !== CellKind.background) {
                if (fCell.finalDepth > maxDepth) {
                    fCell.finalDepth = maxDepth;
                }
                fCell.dvTipCells[Page.offDepthHigh] = fCell.finalDepth >> 8
                fCell.dvTipCells[Page.offDepthLow] = fCell.finalDepth & 0xff;
                let row = tip.cellDataRows![fCell.ptOnPrint.y - tip.minY];
                let copyTo = row.printRelativeStart + (fCell.ptOnPrint.x - row.firstCell) * 11;
                if (row.printRelativeModifiedStart === -1) {
                    row.printRelativeModifiedStart = copyTo;
                    row.lengthModified = 11;
                } else {
                    if (copyTo < row.printRelativeModifiedStart) {
                        row.lengthModified += row.printRelativeModifiedStart - copyTo;
                        row.printRelativeModifiedStart = copyTo;
                    } else {
                        row.lengthModified = Math.max(row.lengthModified, copyTo + 11 - row.printRelativeModifiedStart);
                    }
                }
                let pageOffset = (row.pageRelativeStart + (copyTo - row.printRelativeStart))/11;
                if (setWetDry) {
                    if (fCell.finalDepth === 0) {
                        this.makeCanvasOffsetDry(pageOffset);
                    } else {
                        this.makeCanvasOffsetWet(pageOffset);
                    }
                }
                this.setCellVisibleColor(tip, fCell);

                // this is the set of cells modified (by kind) in this 'session'
                kindCells.add(pageOffset);
            }
        }
        for(const row of tip.cellDataRows!) {
            if (row.lengthModified > 0) {
                let v = new Uint8Array(tip.allCellData!.buffer, row.printRelativeModifiedStart, row.lengthModified);
                this.lastTipPlacement!.pageBuffer.set(v, row.pageRelativeStart + (row.printRelativeModifiedStart - row.printRelativeStart));
            }
        }
    }

    static colorIsSmudge(buf: Uint8Array, off: number) {
        return buf[off] === 1 && buf[off + 1] === 1 && buf[off + 2] === 1;
    }
    static colorIsBlow(color: tinycolor.ColorFormats.RGB) {
        return color.r === 254 && color.g === 254 && color.b === 254;
    }
    static colorIsKnife(color: tinycolor.ColorFormats.RGB) {
        return color.r === 1 && color.g === 2 && color.b === 3;
    }

    private setCellVisibleColor(tip: FingerTip, fCell: FlowCell) {
        if (fCell.finalDepth > 0 && !Page.colorIsSmudge(fCell.dvTipCells, Page.offWetRed)) {
            if (this.dryTransmits && fCell.finalDepth <= FingerTip.preferredMeniscusScrapeDepth) {
                // scale between depth and 4* depth so thicker paint blocks out effect of blending much more
                let scaledDepth = fCell.finalDepth;
                if (scaledDepth > FingerTip.preferredSmashScrapeDepth) {
                    scaledDepth = 4 * FingerTip.preferredMeniscusScrapeDepth * ((scaledDepth-FingerTip.preferredSmashScrapeDepth)/(FingerTip.preferredMeniscusScrapeDepth - FingerTip.preferredSmashScrapeDepth));
                }
                let depthRat = Page.backgroundImplicitDepth / (scaledDepth + Page.backgroundImplicitDepth);
                // visible = mix of wet and dried
                colorMixKM(fCell.dvTipCells, Page.offWetRed, fCell.dvTipCells, Page.offDryRed, depthRat, fCell.dvTipCells, Page.offVisRed);
            } else {
                // visible = wet
                fCell.dvTipCells[Page.offVisRed] = fCell.dvTipCells[Page.offWetRed];
                fCell.dvTipCells[Page.offVisGreen] = fCell.dvTipCells[Page.offWetGreen];
                fCell.dvTipCells[Page.offVisBlue] = fCell.dvTipCells[Page.offWetBlue];
            }
        } else {
            // visible = dried
            fCell.dvTipCells[Page.offVisRed] = fCell.dvTipCells[Page.offDryRed];
            fCell.dvTipCells[Page.offVisGreen] = fCell.dvTipCells[Page.offDryGreen];
            fCell.dvTipCells[Page.offVisBlue] = fCell.dvTipCells[Page.offDryBlue];
        }

    }


    private stageFlow(from: FlowCell, to: FlowCellMove, totalMove: number, prevError: number) : number {
        let newError = prevError;
        if (from.inBounds && to.fCellDest.inBounds) {
            let fracMove = (totalMove * to.fraction) + prevError;
            let intMove = prevError === 0 ? Math.floor(fracMove) : Math.round(fracMove);
            newError = (fracMove - intMove);
            if (intMove > 0) {
                if (this.centralPull !== undefined) {
                    // moving away from the center?
                    // turn tip space into page space
                    
                    let ptOnPageSrc = this.tipCoordinatesToPageCoordinates(from.ptOnPrint, this.lastTipPlacement!.pageTipCenter);
                    let ptOnPageDst = this.tipCoordinatesToPageCoordinates(to.fCellDest.ptOnPrint, this.lastTipPlacement!.pageTipCenter);

                    const dSrc = ptOnPageSrc.distanceTo(this.lastTipPlacement!.pageCenter);
                    const dDst = ptOnPageDst.distanceTo(this.lastTipPlacement!.pageCenter);
                    if (dDst > dSrc) {
                        const contrib = Math.min(1.0, (dDst - dSrc)); // approx the vector in a line to the center
                        const scale = Math.min(1, Math.max(0, this.centralPull(dDst)));
                        intMove -= (Math.floor(intMove * contrib * scale));
                    }
                }

                from.stagedDepth -= intMove;
                to.fCellDest.stagedDepth += intMove;
                to.removedDepth = intMove;
            }
        }
        return newError;
    }

    private commitFlow(from: FlowCell, moveTo: FlowCellMove) {

        let move = moveTo.removedDepth;
        let to = moveTo.fCellDest;
        if (move > 0) {
            let toIsSmudge = Page.colorIsSmudge(to.dvTipCells, Page.offWetRed);
            let fromIsSmudge = Page.colorIsSmudge(from.dvTipCells, Page.offWetRed);
            let fromIsBlow = this.lastTipApplication!.instrument === TipInstrument.blow;

            if (fromIsBlow) {
                // dry = wet unless wet is background
                if (!toIsSmudge) {
                    // dry moves from page to air, so alter the from dry
                    from.dvTipCells[Page.offDryRed] = from.dvTipCells[Page.offWetRed];
                    from.dvTipCells[Page.offDryGreen] = from.dvTipCells[Page.offWetGreen];
                    from.dvTipCells[Page.offDryBlue] = from.dvTipCells[Page.offWetBlue];
                }
            } else if (fromIsSmudge) {
                // smearing, so leave wet in dest alone 
                // solvent would wake up dry but ...

            } else if (toIsSmudge || to.finalDepth === 0) {
                // transfer the wet
                to.dvTipCells[Page.offWetRed] = from.dvTipCells[Page.offWetRed];
                to.dvTipCells[Page.offWetGreen] = from.dvTipCells[Page.offWetGreen];
                to.dvTipCells[Page.offWetBlue] = from.dvTipCells[Page.offWetBlue];
            } else if (to.dvTipCells[Page.offWetRed] === from.dvTipCells[Page.offWetRed] &&
                    to.dvTipCells[Page.offWetGreen] === from.dvTipCells[Page.offWetGreen] &&
                    to.dvTipCells[Page.offWetBlue] === from.dvTipCells[Page.offWetBlue]) {
                    // no mix, same color
            }
            else {
                // the weight is about the paint volume. tip mixing onto page
                let wTip = moveTo.removedDepth / (moveTo.removedDepth + to.finalDepth);
                // to wet = mix of from wet and to wet
                colorMixKM(to.dvTipCells, Page.offWetRed, from.dvTipCells, Page.offWetRed, wTip, to.dvTipCells, Page.offWetRed);
            }

            to.modified = true;
            from.modified = true;

            to.finalDepth += move;
            from.finalDepth -= move;

            if (to.finalDepth === 0) {
                console.log('zero depth');
            }
        }

        moveTo.removedDepth = 0;
    }

    static ColorToTipApplication(color: tinycolor.ColorFormats.RGB) : TipApplication {
        if (Page.colorIsBlow(color)) {
            return {instrument: TipInstrument.blow, motion: TipMotion.unknown, action: TipAction.dry, modifier: 0};
        }
        if (Page.colorIsKnife(color)) {
            return {instrument: TipInstrument.knife, motion: TipMotion.unknown, action: TipAction.remove, modifier: 0};
        }
        if (color.r * color.g * color.b === 1) {
            return {instrument: TipInstrument.finger, motion: TipMotion.unknown, action: TipAction.smudge, modifier: 0};
        }
        return {instrument: TipInstrument.finger, motion: TipMotion.unknown, action: TipAction.paint, modifier: 0};
    }

    public dropPaint(tip: FingerTip, center: Point2D, paintColor: string, paintRadius: number, pChange: CirclePageChange | undefined) : TipEffect | undefined {

        const colorRGB = new tinycolor(paintColor).toRgb();
        let tipApplication = Page.ColorToTipApplication(colorRGB);

        // get the new start volume and apply it to all cells in the minimum circle
        const fillRad = Math.floor((FingerTip.minFingerRadius / 5.0) * 3.0);
        let paintVol = FingerTip.newPaintVolume(paintRadius, colorRGB.r * colorRGB.g * colorRGB.b === 1);
        //let paintVol = FingerTip.newPaintVolume(paintRadius, false);
        let minCells = Math.PI * fillRad * fillRad;

        this.lastTipPlacement = new PageTipPlacement(this, tip, center, this.cellData, true);
        this.lastTipApplication = tipApplication;

        if (pChange !== undefined) {
            let dropDim = fillRad * 2;
            pChange.initialDrop = {color: colorRGB, 
                offset: new Point2D((center.x - fillRad) - pChange.pageX, (center.y - fillRad) - pChange.pageY),
                width: dropDim, height: dropDim, 
                depth: new Uint16Array(dropDim * dropDim)};
            let ptBuff = new Point2D(FingerTip.maxPrintRadius - fillRad, FingerTip.maxPrintRadius - fillRad);
            FingerTip.doFilledCircle(fillRad, 0, (i: number, pt: Point2D)=>{
                
                const glop = Math.min(Math.floor((this.rnd.nextFloat() + .5)  * paintVol / minCells), 0xffff);
                // info on that spot on the finger
                let ptOnBuff = new Point2D(pt.x - ptBuff.x, pt.y - ptBuff.y);
                let depthOffset = ptOnBuff.y * dropDim + ptOnBuff.x;
                pChange.initialDrop!.depth[depthOffset] = glop;

            });
            return undefined;

        } else {
           
            // effects from this one movement step
            const result :TipEffect = new TipEffect();
            result.steps.push({radius: tip.radius, smashRadius: tip.smashRadius, location: this.lastTipPlacement.pageTipCenter, octantMove: 4});

            const kindCells = new Set<number>();
            this.collectTipCellData((ptTip: Point2D, adj: number)=> {
                    let ptPage = this.tipCoordinatesToPageCoordinates(ptTip, this.lastTipPlacement!.pageTipCenter);
                    return (this.offsetOfCoordinates(ptPage) - adj) * 11;
                });
            FingerTip.doFilledCircle(fillRad, 0, (i: number, pt: Point2D)=>{
                // to make things jagged a bit
                const glop = (this.rnd.nextFloat() + .5)  * paintVol / minCells;
                //const glop = paintVol / minCells;

                // info on that spot on the finger
                let fCellTip = tip.getSingleChangeFlowCell(pt, glop, colorRGB);
                // now they are one
                this.stageFlow(fCellTip, fCellTip.move1, glop, 0);
                this.commitFlow(fCellTip, fCellTip.move1);
            })
            this.writeTipCellData(kindCells);

            // convert the modified page cells into drawing effects
            this.cellsToEffects(kindCells, result);

            return result;
        }
    }

    public applyTip(tip: FingerTip, center: Point2D, paintColor: string, paintRadius: number) : TipEffect {

        const colorRGB = new tinycolor(paintColor).toRgb();
        let tipKind = Page.ColorToTipApplication(colorRGB);
        tipKind.motion = TipMotion.press;
        this.lastTipPlacement = new PageTipPlacement(this, tip, center, this.cellData, true);
        this.lastTipApplication = tipKind;
        
        // effects from this one movement step
        const result :TipEffect = new TipEffect();
        result.steps.push({radius: tip.radius, smashRadius: tip.smashRadius, location: this.lastTipPlacement.pageTipCenter, octantMove: 4});

        const kindCells = new Set<number>();
        this.collectTipCellData((ptTip: Point2D, adj: number)=> {
                let ptPage = this.tipCoordinatesToPageCoordinates(ptTip, this.lastTipPlacement!.pageTipCenter);
                return (this.offsetOfCoordinates(ptPage) - adj) * 11;
            });

        // run the flow
        this.runCentralFlowPattern(tip, this.lastTipPlacement.pageTipCenter, kindCells, tipKind);

        // convert the modified page cells into drawing effects
        this.cellsToEffects(kindCells, result);

        return result;
    }

    public blowTip(tip: FingerTip, center: Point2D, dragDryAmount: number) {
        let tipKind : TipApplication = {instrument: TipInstrument.blow, motion: TipMotion.press, action: TipAction.dry, modifier: 0};
        this.lastTipPlacement = new PageTipPlacement(this, tip, center, this.cellData, true);
        this.lastTipApplication = tipKind;

        const pageLoc = this.lastTipPlacement.pageTipCenter;

        this.cellDryApplied = new Uint16Array(this.cellsTotal);
        
        // effects from this dry step is a list of the x,y, amount of paint to dry
        //const effectPoints = new Array<Point2D>();

        // drying is moving paint from the page to the 'air' aka nowhere
        let colorRGB = {r: 254, g: 254, b: 254};
        this.collectTipCellData((ptTip: Point2D, adj: number)=> {
            let ptPage = this.tipCoordinatesToPageCoordinates(ptTip, pageLoc);
            return (this.offsetOfCoordinates(ptPage) - adj) * 11;
        });
        let dryCells = new Set<number>();
        FingerTip.doFilledCircle(tip.radius, 0, (i: number, pt: Point2D)=>{

            let fCellAir = tip.getSingleChangeFlowCell(pt, 0, colorRGB);
            let fCellPage = fCellAir.move1.fCellDest;
            if (fCellPage.inBounds) {
                let ptPage = this.tipCoordinatesToPageCoordinates(pt, pageLoc);
                let pageOffset = this.offsetOfCoordinates(ptPage);
                dryCells.add(pageOffset);

                let pageDepth = fCellPage.finalDepth;
                // now they are one
                let localDryAmount = (dragDryAmount > pageDepth) ? pageDepth : dragDryAmount;
                if (localDryAmount > 0) {

                    let saveMove = fCellPage.move1;
                    fCellPage.move1 = {fCellDest: fCellAir, fraction: 1} as FlowCellMove;
                    this.stageFlow(fCellPage, fCellPage.move1, localDryAmount, 0);
                    this.commitFlow(fCellPage, fCellPage.move1);
                    fCellPage.move1 = saveMove;
                }

            }
        })
        this.writeTipCellData(dryCells);
        dryCells.forEach(c=> 
            this.cellDryApplied![c] += dragDryAmount
        );
    }
    public clearAppliedDry() {
        if (this.cellDryApplied !== undefined) {

        }
        this.cellDryApplied = undefined;
    }

    public clearKnifeBladeCells() {
        // no background color mixing
        let clearData = [1,1,1,1,1,1,0,0,1,1,1];
        for (let c = 0; c < FingerTip.cellsWidth * 3; c++) {
            let off = c * 11;
            this.cellDataBlade.set(clearData, off);
        }
    }

    public dragTip(ptStart: Point2D, ptEnd: Point2D, radiusStart: number, radiusEnd: number, octantLast: number, paintRadius:number, tipApplication: TipApplication, useGPU: boolean) : TipEffect {
        const result :TipEffect = new TipEffect();

        const kindCells = new Set<number>();

        let stepLine = ptStart.lineTo(ptEnd);
        // for this batch, pretend like every step is oriented the same way to prevent those mid-batch jagged circles
        let primaryMoveOctant = ptEnd.moveOctantFrom(ptStart);

        result.steps.push({radius: radiusStart, smashRadius: FingerTip.smashRadiusOf(radiusStart), location: ptStart, octantMove: octantLast});

        // move the radius from start to finish size in integer jumps in the same steps as the line
        let radiusStep = (radiusEnd - radiusStart) / (stepLine.length-1);
        let radiusTrack = radiusStart;
        let radiusLast = radiusTrack;
        let ptLast = ptStart;
        for (const s of stepLine.slice(1)) {
            let octantMove = s.octantFrom(ptLast);

            // add a bit to the radius, if it changes by an integer amount, use the new value
            radiusTrack += radiusStep;
            if (Math.floor(radiusTrack) !== radiusLast) {
                radiusLast = Math.floor(radiusTrack);
            }

            let ftScrape : FingerTip
            if (tipApplication.instrument === TipInstrument.knife) {
                octantMove = primaryMoveOctant;
                // radius communicates degree of edge too
                ftScrape = FingerTip.getKnifeShape(radiusLast, octantMove, octantLast);
                ftScrape.customizeKnifePattern(paintRadius, octantMove);
            } else {
                ftScrape = FingerTip.getScrapeShape(radiusLast, octantMove, octantLast);
            }

            result.steps.push({radius: ftScrape.radius, smashRadius: ftScrape.smashRadius, location: s, octantMove: primaryMoveOctant});

            result.changeEntries.push({shape: ftScrape, location: s, modifier: octantMove});

            // run the flow
            this.lastTipApplication = tipApplication;
            if (!useGPU) {
                if (tipApplication.instrument === TipInstrument.knife) {
                    this.runKnifeFlowPattern(ftScrape, ptLast, kindCells);
                } else {
                    this.runDragFlowPattern(ftScrape, ptLast, kindCells);
                }
            }
            

            octantLast = octantMove;
            ptLast = s;
        }

        // convert the modified page cells into drawing effects
        this.cellsToEffects(kindCells, result);

        return result;
    }

    public dragBlowTip(ptStart: Point2D, ptEnd: Point2D, radiusStart: number, radiusEnd: number, dragDryAmount: number, octantLast: number, previousBlows: Array<PageChangeEntry>, useGPU: boolean) : [steps: number, lastOctant: number] {

        let stepLine = ptStart.lineTo(ptEnd);
        // for this batch, pretend like every step is oriented the same way to prevent those mid-batch jagged circles
        let primaryMoveOctant = ptEnd.moveOctantFrom(ptStart);

        let steps = 1;

        // move the radius from start to finish size in integer jumps in the same steps as the line
        let radiusStep = (radiusEnd - radiusStart) / (stepLine.length-1);
        let radiusTrack = radiusStart;
        let radiusLast = radiusTrack;
        let ptLast = ptStart;
        let changes = new Array<PageChangeEntry>();
        for (const s of stepLine.slice(1)) {
            // add a bit to the radius, if it changes by an integer amount, use the new value
            radiusTrack += radiusStep;
            if (Math.floor(radiusTrack) !== radiusLast) {
                radiusLast = Math.floor(radiusTrack);
            }

            steps ++;
            let ft = FingerTip.getCircleShape(radiusLast);
            if (useGPU) {
                changes.push({shape:ft, location: s, modifier:dragDryAmount});
            } else {
                this.blowTip(ft, ptLast, dragDryAmount);
            }

            ptLast = s;
        }
        if (useGPU) {
            let tipApplication = {instrument: TipInstrument.blow, motion: TipMotion.drag, action: TipAction.dry, modifier: 0};
            this.lastTipApplication = tipApplication;
            let pChange = this.createTouchPageChange(changes, tipApplication, true, 0) as BlowPageChange;
            if (pChange !== undefined) {
                pChange.processChanges(this.crcVisible);
                // previous changes need to be made relative to the whole page instead of relative to this change
                // because they are used to paint the already dry regions in a long dry drag.
                for (const change  of changes) {
                    change.location = new Point2D(change.location.x + pChange.pageX, change.location.y + pChange.pageY);
                }
                pChange.finalize();
                previousBlows.push(...changes);
                this.refreshVisible(previousBlows);

            }
        }

        return [steps, primaryMoveOctant]
    }

    public removeTip(tip: FingerTip, center: Point2D, tipApplication: TipApplication) : TipEffect {
        
        const pageLoc = new Point2D(center.x, center.y);
        
        // effects from this one movement step
        const result :TipEffect = new TipEffect();
        result.steps.push({radius: tip.radius, smashRadius: tip.smashRadius, location: pageLoc, octantMove: 4});

        const kindCells = new Set<number>();

        // run the flow
        this.runCentralFlowPattern(tip, pageLoc, kindCells, tipApplication, true);

        // convert the modified page cells into drawing effects
        this.cellsToEffects(kindCells, result);

        return result;
    }


    private runCentralFlowPattern(tip: FingerTip, pageLoc: Point2D, kindCells: Set<number>, tipApplication: TipApplication, smashOnly: boolean = false) {
        this.lastTipPlacement = new PageTipPlacement(this, tip, pageLoc, this.cellData, true);
        this.lastTipApplication = tipApplication;

        // get the state of the page before the drag put into every source or dest cell in the print
        this.collectTipCellData((ptTip: Point2D, adj: number)=> {
            let ptPage = this.tipCoordinatesToPageCoordinates(ptTip, pageLoc);
            return (this.offsetOfCoordinates(ptPage) - adj) * 11;
        });

        let debugShowVol = false;

        const stageRadiate = (fCell: FlowCell, max: number, priority: number) => {
            let move = fCell.finalDepth - max;
            if (move > 0) {
                if (smashOnly && fCell.finalKind !== CellKind.smash) {
                    return;
                }
                let error = 0;
                if (fCell.move1 !== undefined && fCell.move1.priority === priority) {
                    error = this.stageFlow(fCell, fCell.move1, move, error);
                }
                if (fCell.move2 !== undefined && fCell.move2.priority === priority) {
                    error = this.stageFlow(fCell, fCell.move2, move, error);
                }
                if (fCell.move3 !== undefined && fCell.move3.priority === priority) {
                    error = this.stageFlow(fCell, fCell.move3, move, error);
                }
                if (fCell.move4 !== undefined && fCell.move4.priority === priority) {
                    error = this.stageFlow(fCell, fCell.move4, move, error);
                }
                if (fCell.move5 !== undefined && fCell.move5.priority === priority) {
                    this.stageFlow(fCell, fCell.move5, move, error);
                }
            }
        }
        const commitRadiate = (fCell: FlowCell, priority: number) => {
            if (smashOnly && fCell.finalKind !== CellKind.smash) {
                fCell.finalDepth = 0;
                fCell.modified = false;
                return;
            }

            if (fCell.move1 !== undefined && fCell.move1.priority === priority) {
                this.commitFlow(fCell, fCell.move1);
            }
            if (fCell.move2 !== undefined && fCell.move2.priority === priority) {
                this.commitFlow(fCell, fCell.move2);
            }
            if (fCell.move3 !== undefined && fCell.move3.priority === priority) {
                this.commitFlow(fCell, fCell.move3);
            }
            if (fCell.move4 !== undefined && fCell.move4.priority === priority) {
                this.commitFlow(fCell, fCell.move4);
            }
            if (fCell.move5 !== undefined && fCell.move5.priority === priority) {
                this.commitFlow(fCell, fCell.move5);
            }
        }

        const spewRingVol = (ring: number, pri: number, tag:string, fCellsRing: Array<FlowCell>) => {
            if (debugShowVol) {
                let total = 0;
                for (const fCell of fCellsRing) {
                    total += fCell.finalDepth;
                }
                console.log(`Ring ${ring} pri ${pri} ${tag} vol ${total}`);
            }
        }

        //let start = console.time('runCentralFlowPattern');
        //for (let i = 0; i < 100; i++) {

        let ring = 0;
        for (const fCellsRing of tip.fCellsCentralRings) {

            //spewRingVol(ring, 1, 'pre', fCellsRing);

            for (const fCell of fCellsRing) {
                let maxDepth = fCell.maxTouchDepth;
                if (fCell.finalKind === CellKind.smash) {
                    maxDepth = fCell.preferredScrapeDepth;
                }
                stageRadiate(fCell, maxDepth, 1);
            }
            for (const fCell of fCellsRing) {
                commitRadiate(fCell, 1);
            }
            //spewRingVol(ring, 1, 'post', fCellsRing);

            for (const fCell of fCellsRing) {
                let maxDepth = fCell.maxTouchDepth;
                if (fCell.finalKind === CellKind.smash) {
                    maxDepth = fCell.preferredScrapeDepth;
                }
                stageRadiate(fCell, maxDepth, 2);
            }
            for (const fCell of fCellsRing) {
                commitRadiate(fCell, 2);
            }
            //spewRingVol(ring, 2, 'post', fCellsRing);
            ring++;
        }
        //}
        //console.timeEnd('runCentralFlowPattern');


        // send the final depth and color to the page and cell kind map
        this.writeTipCellData(kindCells);
    }


    stageArcScrape(fCells: Array<FlowCell>) {
        for (const fCell of fCells) {
            let curDepth = fCell.finalDepth; 
            if (curDepth <= 0) {
                continue;
            }
            let curMax = fCell.preferredScrapeDepth;
            let moveFwd = 0;
            if (curDepth > fCell.maxKindPushDepth && fCell.finalKind === fCell.move1.fCellDest.finalKind) {
                // when build-up happens, let it ride but not too far
                // some gets left behind because of this little lie
                curDepth = fCell.maxKindPushDepth;
            }
            if (curDepth > curMax) {
                moveFwd = (curDepth - curMax);
            } else if (curDepth > fCell.minScrapeDepth) {
                moveFwd = Math.floor((curDepth - fCell.minScrapeDepth) * .25);
            } 
            if (moveFwd >= 1) {
                this.stageFlow(fCell, fCell.move1, moveFwd, 0);
            }
        }
    }
    commitArcScrape(fCells: Array<FlowCell>) : Array<FlowCell> {
        const overflow = new Array<FlowCell>();
        for (const fCell of fCells) {
            if (fCell.move1.removedDepth > 0) {
                this.commitFlow(fCell, fCell.move1);
                if (fCell.move1.fCellDest.finalKind === CellKind.corona && fCell.move1.fCellDest.finalDepth > Page.maxCoronaDepth) {
                    overflow.push(fCell.move1.fCellDest);
                }
            }
        }
        return overflow;
    }

    
    stageOverflows(fCells: Array<FlowCell>) {
        for (const fCell of fCells) {
            let curDepth = fCell.finalDepth; 
            if (curDepth <= Page.maxCoronaDepth || fCell.move1 === undefined) {
                continue;
            }
            let extra = curDepth - Page.maxCoronaDepth;
            let error = 0;
            error = this.stageFlow(fCell, fCell.move4, extra, error);
            error = this.stageFlow(fCell, fCell.move5, extra, error);
            error = this.stageFlow(fCell, fCell.move1, extra, error);
        }
    }
    commitOverflows (fCells: Array<FlowCell>) : Array<FlowCell> {
        const overflow = new Array<FlowCell>();
        for (const fCell of fCells) {
            if (fCell.move1 === undefined) {
                continue;
            }
            if (fCell.move1.removedDepth > 0) {
                this.commitFlow(fCell, fCell.move1);
                if (fCell.move1.fCellDest.finalDepth > Page.maxCoronaDepth) {
                    overflow.push(fCell.move1.fCellDest);
                }
            }
            if (fCell.move4.removedDepth > 0) {
                this.commitFlow(fCell, fCell.move4);
                if (fCell.move4.fCellDest.finalDepth > Page.maxCoronaDepth) {
                    overflow.push(fCell.move4.fCellDest);
                }
            }
            if (fCell.move5.removedDepth > 0) {
                this.commitFlow(fCell, fCell.move5);
                if (fCell.move5.fCellDest.finalDepth > Page.maxCoronaDepth) {
                    overflow.push(fCell.move5.fCellDest);
                }
            }

        }
        return overflow;
    }

    stageFill (fCells: Array<FlowCell>): Array<FlowCell> {
        const moves = new Array<FlowCell>();
        const considered = new Set<FlowCell>();
        for (const fCell of fCells) {
            if (fCell.move3 === undefined) {
                continue;
            }
            let curDepth = fCell.finalDepth; 
            if (curDepth === 0) {
                let fCellTrace = fCell.move3.fCellDest;
                while (fCellTrace !== undefined) {
                    if (considered.has(fCellTrace)) {
                        break;
                    }
                    if (fCellTrace.finalDepth > fCellTrace.minScrapeDepth) {
                        let move = Math.min(Math.floor(Math.max(1, Math.round(fCellTrace.finalDepth * .75))), FingerTip.maxMeniscusTouchDepth);
                        //move = Math.min(FingerTip.maxSmashScrapeDepth, move);
                        if (fCellTrace.finalDepth - move < 2*FingerTip.minSmashScrapeDepth) {
                            move = Math.max(0, fCellTrace.finalDepth - 2*FingerTip.minSmashScrapeDepth);
                        }
                        if (fCellTrace.move2 !== undefined && fCellTrace.move2.removedDepth === 0) {
                            if (move >= 1) {
                                this.stageFlow(fCellTrace, fCellTrace.move2, move, 0);
                                moves.push(fCellTrace);
                            }
                        } else {
                            break;
                        }
                    }
                    considered.add(fCellTrace);
                    fCellTrace = fCellTrace.move3?.fCellDest;
                }
            }
        }
        return moves;
    }
    commitFill(fCells: Array<FlowCell>) {
        for (const fCell of fCells) {
            if (fCell.move2 !== undefined && fCell.move2.removedDepth > 0) {
                this.commitFlow(fCell, fCell.move2);
            }
        }
    }

    stageArcBlend(fCells: Array<FlowCell>) {
        for (const fCell of fCells) {
            let curDepth = fCell.finalDepth; 
            if (curDepth <= 1) {
                continue;
            }
            if (fCell.move4.fCellDest.finalDepth > 1 && fCell.move4.fCellDest.move5 !== undefined) {
                let swap = Math.max(1, Math.floor(Math.min(curDepth*.05, fCell.move4.fCellDest.finalDepth * .05)));
                this.stageFlow(fCell, fCell.move4, swap, 0)
            }
            if (fCell.move5.fCellDest.finalDepth > 1 && fCell.move5.fCellDest.move4 !== undefined) {
                let swap = Math.max(1, Math.floor(Math.min(curDepth*.05, fCell.move5.fCellDest.finalDepth * .05)));
                this.stageFlow(fCell, fCell.move5, swap, 0)
            }
        }
    }
    commitArcBlend(fCells: Array<FlowCell>) {
        for (const fCell of fCells) {
            if (fCell.move4.removedDepth > 0) {
                this.commitFlow(fCell, fCell.move4);
            }
            if (fCell.move5.removedDepth > 0) {
                this.commitFlow(fCell, fCell.move5);
            }
        }
    }
    performDrag() {
        let tip = this.lastTipPlacement!.tip;
        let fillMoves = this.stageFill(tip.fCellsMeniscusScrape);
        this.commitFill(fillMoves);

        fillMoves = this.stageFill(tip.fCellsSmashScrape);
        this.commitFill(fillMoves);
        
        this.stageArcScrape(tip.fCellsMeniscusScrape);
        let overflow = this.commitArcScrape(tip.fCellsMeniscusScrape);
        while (overflow.length > 0) {
            this.stageOverflows(overflow);
            overflow = this.commitOverflows(overflow);
        }

        this.stageArcScrape(tip.fCellsSmashScrape);
        overflow = this.commitArcScrape(tip.fCellsSmashScrape);
        while (overflow.length > 0) {
            this.stageOverflows(overflow);
            overflow = this.commitOverflows(overflow);
        }

        this.stageArcBlend(tip.fCellsMeniscusBlend);
        this.commitArcBlend(tip.fCellsMeniscusBlend);
    }

    private runDragFlowPattern(tip: FingerTip, pageLoc: Point2D, kindCells: Set<number>) {
        this.lastTipPlacement = new PageTipPlacement(this, tip, pageLoc, this.cellData, true);

        // get the state of the page before the drag put into every source or dest cell in the print
        const tipToPageOff = (ptTip: Point2D, adj: number)=> {
            let pageX = ptTip.x + pageLoc.x - FingerTip.cellsWidth/2;
            let pageY = ptTip.y + pageLoc.y - FingerTip.cellsWidth/2;
            let offPage = this.cellsWidth * pageY + pageX;
            return (offPage - adj) * 11;
        }
        this.collectTipCellData(tipToPageOff);
        this.performDrag();
        // put back the changes
        this.writeTipCellData(kindCells);
    }

    private runKnifeFlowPattern(tip: FingerTip, pageLoc: Point2D, kindCells: Set<number>) {
        let mainPlacement = this.lastTipPlacement = new PageTipPlacement(this, tip, pageLoc, this.cellData, true);
        let bladePlacement = new PageTipPlacement(this, tip.tipKnifeBlade!, pageLoc, this.cellDataBlade, false );
        
        // get the state of the page before the drag put into every source or dest cell in the print
        this.collectTipCellData((ptTip: Point2D, adj: number)=> {
            let ptPage = this.tipCoordinatesToPageCoordinates(ptTip, pageLoc);
            return (this.offsetOfCoordinates(ptPage) - adj) * 11;
        });

        this.lastTipPlacement = bladePlacement;
        // get the state of the blade from the cache
        this.collectTipCellData((ptTip: Point2D, adj: number)=> {
            let fCell = tip.tipKnifeBlade!.getFlowCell(ptTip.x - adj, ptTip.y, CellKind.background, CellKind.background);
            let tipStart = Math.floor((this.cellDataBlade.length - tip.tipKnifeBlade!.allCellData!.length)/2);
            return fCell.dvTipCells.byteOffset + tipStart;
        });
        this.lastTipPlacement = mainPlacement;

        // any cells from the 'left behind in a turn'  section just vanish
        for (const fCell of tip.fCellsKnifeBack!) {
            this.stageFlow(fCell, fCell.move1, fCell.finalDepth, 0);
            this.commitFlow(fCell, fCell.move1);
        }

        // the knife edge pulls up to the blade
        for (const fCell of tip.fCellsKnifeEdge!) {
            if (fCell.move1.fCellDest !== undefined && fCell.finalDepth > 0) {
                this.stageFlow(fCell, fCell.move1, fCell.finalDepth, 0);
                this.commitFlow(fCell, fCell.move1);
            }
        }

        // the blade smooths
        for (const fCell of tip.tipKnifeBlade!.fCellsKnifeEdge!) {

            let curDepth = fCell.finalDepth; 
            if (curDepth === 0 || fCell.move2 === undefined || fCell.move3 === undefined) {
                continue;
            }
            let prevDepth = fCell.move2.fCellDest.finalDepth;
            let nextDepth = fCell.move3.fCellDest.finalDepth;
            if (prevDepth > nextDepth) {
                if (nextDepth < curDepth) {
                    let swap = Math.max(1, Math.floor(curDepth*.05));
                    this.stageFlow(fCell, fCell.move3, swap, 0)
                }

            } else {
                if (prevDepth < curDepth) {
                    let swap = Math.max(1, Math.floor(curDepth*.05));
                    this.stageFlow(fCell, fCell.move2, swap, 0)
                }

            }
        }
        for (const fCell of tip.tipKnifeBlade!.fCellsKnifeEdge!) {
            if (fCell.move2 !== undefined && fCell.move2.removedDepth > 0) {
                this.commitFlow(fCell, fCell.move2);
            }
            if (fCell.move3 !== undefined && fCell.move3.removedDepth > 0) {
                this.commitFlow(fCell, fCell.move3);
            }
        }

        // now the blade drop paint into the gaps
        for (const fCell of tip.tipKnifeBlade!.fCellsKnifeEdge!) {
            let curDepth = fCell.finalDepth; 
            if (curDepth <= 0 || fCell.move1 === undefined || fCell.move1.fCellDest === undefined) {
                continue;
            }
            this.stageFlow(fCell, fCell.move1, curDepth, 0)
            this.commitFlow(fCell, fCell.move1);
        }

        // send the final depth and color to the page and cell kind map
        for (const fCell of tip.allFlowCells) {
            if (fCell.finalKind !== CellKind.background && fCell.inBounds) {
                if (fCell.finalDepth === 0) {
                    // scrape over only dried background will fade it
                    if (fCell.modified === false && !(fCell.dvTipCells[Page.offDryRed] === 255 && fCell.dvTipCells[Page.offDryGreen] === 255 && fCell.dvTipCells[Page.offDryBlue] === 255)) {
                        let rd = fCell.dvTipCells[Page.offDryRed];
                        let gd = fCell.dvTipCells[Page.offDryGreen];
                        let bd = fCell.dvTipCells[Page.offDryBlue];
                        let ad = .95;
                        rd = Math.floor(rd * ad + (1-ad) * 255);
                        gd = Math.floor(gd * ad + (1-ad) * 255);
                        bd = Math.floor(bd * ad + (1-ad) * 255);
                        if (rd*gd*bd === 1) {
                            rd=gd=bd=0;
                        }
                        
                        fCell.dvTipCells[Page.offDryRed] = rd;
                        fCell.dvTipCells[Page.offDryGreen] = gd;
                        fCell.dvTipCells[Page.offDryBlue] = bd;
                        fCell.modified = true;
                    }
                }
            }
        }
        

        this.writeTipCellData(kindCells);

        this.lastTipPlacement = bladePlacement;
        let unKindCells = new Set<number>();
        this.writeTipCellData(unKindCells);
        this.lastTipPlacement = mainPlacement;

    }

    private cellsToEffects(kindCells: Set<number>, effect: TipEffect) : void {
        // for every cell
        kindCells.forEach(c => {
            let pos = this.coordinatesOfOffset(c);
            effect.rectTL.pushTL(pos);
            effect.rectBR.pushBR(pos);

            effect.cells.push(c);
        });
    }

    public makeCanvasOffsetWet(offset: number) {
        let existingWetStage = this.wetCanvasOffsets.get(offset);
        if (existingWetStage !== undefined && existingWetStage !== this.wetCanvasStage) {
            // remove from earlier stage
            this.wetCanvasStageOffsets[existingWetStage].delete(offset);
        }
        // set into current stage
        this.wetCanvasOffsets.set(offset, this.wetCanvasStage);
        this.wetCanvasStageOffsets[this.wetCanvasStage].add(offset);

    }
    public makeCanvasOffsetDry(offset: number) {
        let existingWetStage = this.wetCanvasOffsets.get(offset);
        if (existingWetStage !== undefined && existingWetStage !== this.wetCanvasStage) {
            // remove from earlier stage
            this.wetCanvasStageOffsets[existingWetStage].delete(offset);
            this.wetCanvasOffsets.delete(offset);
        }
    }

    public refreshVisible(previousBlows: Array<PageChangeEntry> | undefined = undefined) {
        let change = new RefreshPageChange(this);
        if (previousBlows !== undefined) {  
            change.previousBlows.push(...previousBlows)
        }
        change.processChanges(this!.crcVisible);
        change.finalize();
    }

    public dry(factor: number, repeat: number, useGpu: boolean) {
        if (factor !== 0) {

            let finalFactor = factor;
            let reps = 1;
            if (repeat > 1) {
                if (repeat > this.wetCanvasStageMax) {
                    // only do the first reps and then one push at the end
                    reps = this.wetCanvasStageMax + 1;
                    finalFactor = factor * (repeat - this.wetCanvasStageMax);
                } else {
                    // just do these reps with no final
                    reps = repeat;
                }
            }

            //console.time('dry');
            //let max=0;
            for (let r = 0; r < reps; r++) {
                if (r === reps-1) {
                    factor = finalFactor;
                }

                if (useGpu) {
                    let dryChange = new DryPageChange(this, factor);
                    dryChange.processChanges(this!.crcVisible);
                    dryChange.finalize();
                } else {
                    let iWetCanvasStage = this.wetCanvasStage + 1;
                    if (iWetCanvasStage >= this.wetCanvasStageMax) {
                        iWetCanvasStage = 0;
                    }
        
                    let cellsTotal = this.cellsTotal;
                    let wetCellsStage = this.wetCanvasStageOffsets[iWetCanvasStage];
                    let wetCellsAll = this.wetCanvasOffsets;
        
                    for(let c = 0; c < cellsTotal; c++) {
                        let off = c * 11;
                        let d = (this.cellData[off + Page.offDepthHigh] << 8) | this.cellData[off + Page.offDepthLow];
                        //max = Math.max(max, d);
                        if (d > 0) { 
                            if (wetCellsAll.has(c)) {
                                if(wetCellsStage.has(c)) {
                                    this.wetCanvasOffsets.delete(c);
                                    this.cellData[off + Page.offDryRed] = this.cellData[off + Page.offVisRed];
                                    this.cellData[off + Page.offDryGreen] = this.cellData[off + Page.offVisGreen];
                                    this.cellData[off + Page.offDryBlue] = this.cellData[off + Page.offVisBlue];
                                } else {
                                    continue;
                                }
                            }
                            if (d > 10*Page.maxCoronaDepth) {
                                d = 10*Page.maxCoronaDepth;
                            }
                            let reduce = Math.max(1, Math.floor(d * factor));
                            d-=reduce;
                            if (d<=0) {
                                d=0;
                            }
                            this.cellData[off + Page.offDepthHigh] = d >> 8;
                            this.cellData[off + Page.offDepthLow ] = d & 0xff;
                        }
                    }
                    this.wetCanvasStage = iWetCanvasStage;
                    this.wetCanvasStageOffsets[iWetCanvasStage] = new Set<number>();   
                }

            }
            //console.timeEnd('dry');
        }
    }

}

